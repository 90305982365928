import React, { ReactElement, SyntheticEvent, useEffect } from 'react';
import { FormTypeContext } from 'context/formTypeContext';
import {
    SCNav,
    SCNavList,
    SCNavIcon,
    SCNavElement,
    SCMenuWrapper,
    SCLinkWrapper,
    SCTSNavElement,
    SCLinkMenuWrapper,
    SCNavElementWrapper
} from './styles';
import { INavigationProps, NavigationSelected } from './types';
import { Routes } from 'routes';
import { IFormTypeState } from 'types/claim-types';
import { IconButton, Menu } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from 'components/atoms/MenuIcon/MenuItem';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum, TSButtonWeightEnum } from 'enums/TSButtonVariants';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';

const TSTopNavigation = ({ isAuthenticated, children }: INavigationProps): ReactElement => {
    const { saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const location = useLocation();
    useEffect(() => {
        chooseSelected(location.pathname);
    }, [location.pathname]);
    const [selected, setSelected] = React.useState({
        home: true,
        claim: false,
        claimLookup: false,
        faqs: false,
        contact: false,
        admin: false
    });
    const chooseSelected = (value): void => {
        switch (value) {
            case Routes.HOME:
                select(NavigationSelected.HOME);
                updateContextStateClaimFormAdvancement({
                    reviewState: ClaimStateEnum.INACTIVE
                });
                break;
            case Routes.CLAIM:
                select(NavigationSelected.CLAIM);
                updateContextStateClaimFormAdvancement({
                    reviewState: ClaimStateEnum.INACTIVE
                });
                break;
            case Routes.CLAIM_LOOKUP:
                select(NavigationSelected.CLAIM_LOOKUP);
                break;
            case Routes.FAQS:
                select(NavigationSelected.FAQS);
                break;
            case Routes.CONTACT:
                select(NavigationSelected.CONTACT);
                break;
            case Routes.ADMIN_LANDING:
            case Routes.CLAIMS_MANAGEMENT:
            case Routes.ADMIN_GUIDE:
            case Routes.MARKETING_GUIDELINES:
                select(NavigationSelected.ADMIN);
                break;
            default: {
                select(NavigationSelected.HOME);
                break;
            }
        }
    };
    const select = (value): void => {
        setSelected({
            home: false,
            claim: false,
            claimLookup: false,
            faqs: false,
            contact: false,
            admin: false
        });
        setSelected((prevState) => ({
            ...prevState,
            [value]: true
        }));
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <SCNav>
            <SCNavList>
                <SCTSNavElement>
                    <SCLinkWrapper underline={selected.home}>
                        <Link to={Routes.HOME}>
                            <TSButton
                                ariaLabel="Home button"
                                tsVariant={TSButtonVariantsEnum.SLIDE}
                                size={TSButtonSizeEnum.LARGE}
                                weight={TSButtonWeightEnum.BOLD}
                                onClick={(event: SyntheticEvent<Element, Event>): void => {
                                    select(NavigationSelected.HOME);
                                    handleClose();
                                }}
                            >
                                Home
                            </TSButton>
                        </Link>
                    </SCLinkWrapper>
                </SCTSNavElement>
                <SCTSNavElement>
                    <SCLinkWrapper underline={selected.claim}>
                        <Link to={Routes.CLAIM}>
                            <TSButton
                                ariaLabel="Claim button"
                                tsVariant={TSButtonVariantsEnum.SLIDE}
                                size={TSButtonSizeEnum.LARGE}
                                weight={TSButtonWeightEnum.BOLD}
                                onClick={(event: SyntheticEvent<Element, Event>): void => {
                                    select(NavigationSelected.CLAIM);
                                    handleClose();
                                }}
                            >
                                File a Claim
                            </TSButton>
                        </Link>
                    </SCLinkWrapper>
                </SCTSNavElement>
                <SCTSNavElement>
                    <SCLinkWrapper underline={selected.claimLookup}>
                        <Link to={Routes.CLAIM_LOOKUP}>
                            <TSButton
                                ariaLabel="Check Claim Status button"
                                tsVariant={TSButtonVariantsEnum.SLIDE}
                                size={TSButtonSizeEnum.LARGE}
                                weight={TSButtonWeightEnum.BOLD}
                                onClick={(event: SyntheticEvent<Element, Event>): void => {
                                    select(NavigationSelected.CLAIM_LOOKUP);
                                    handleClose();
                                }}
                            >
                                Check Claim Status
                            </TSButton>
                        </Link>
                    </SCLinkWrapper>
                </SCTSNavElement>
                <SCTSNavElement>
                    <SCLinkWrapper underline={selected.faqs}>
                        <Link to={Routes.FAQS}>
                            <TSButton
                                ariaLabel="FAQs button"
                                tsVariant={TSButtonVariantsEnum.SLIDE}
                                size={TSButtonSizeEnum.LARGE}
                                weight={TSButtonWeightEnum.BOLD}
                                onClick={(event: SyntheticEvent<Element, Event>): void => {
                                    select(NavigationSelected.FAQS);
                                    handleClose();
                                }}
                            >
                                FAQs
                            </TSButton>
                        </Link>
                    </SCLinkWrapper>
                </SCTSNavElement>
                <SCTSNavElement>
                    <SCLinkWrapper underline={selected.contact}>
                        <Link to={Routes.CONTACT}>
                            <TSButton
                                ariaLabel="Contact button"
                                tsVariant={TSButtonVariantsEnum.SLIDE}
                                size={TSButtonSizeEnum.LARGE}
                                weight={TSButtonWeightEnum.BOLD}
                                onClick={(event: SyntheticEvent<Element, Event>): void => {
                                    select(NavigationSelected.CONTACT);
                                    handleClose();
                                }}
                            >
                                Contact
                            </TSButton>
                        </Link>
                    </SCLinkWrapper>
                </SCTSNavElement>
                {isAuthenticated && (
                    <SCTSNavElement>
                        <SCLinkWrapper underline={selected.admin}>
                            <Link to={authenticationData.authenticatedRedirectPath}>
                                <TSButton
                                    ariaLabel="Admin Landing Buttin"
                                    tsVariant={TSButtonVariantsEnum.SLIDE}
                                    size={TSButtonSizeEnum.LARGE}
                                    weight={TSButtonWeightEnum.BOLD}
                                    onClick={(event: SyntheticEvent<Element, Event>): void => {
                                        select(NavigationSelected.ADMIN);
                                        handleClose();
                                    }}
                                >
                                    Admin
                                </TSButton>
                            </Link>
                        </SCLinkWrapper>
                    </SCTSNavElement>
                )}
            </SCNavList>
            <SCMenuWrapper>
                <SCNavIcon>
                    <IconButton
                        size={'large'}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleClick}
                    >
                        <MenuIcon open={open} />
                    </IconButton>
                </SCNavIcon>
                <SCNavElementWrapper>
                    <Menu
                        disableScrollLock={false}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: '94vw',
                                padding: '0'
                            }
                        }}
                    >
                        <SCNavElement backgroundColor={selected.home}>
                            <SCLinkMenuWrapper>
                                <Link to={Routes.HOME}>
                                    <TSButton
                                        ariaLabel="Home button"
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.BOLD}
                                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                                            select(NavigationSelected.HOME);
                                            handleClose();
                                            saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
                                        }}
                                    >
                                        Home
                                    </TSButton>
                                </Link>
                            </SCLinkMenuWrapper>
                        </SCNavElement>
                        <SCNavElement backgroundColor={selected.claim}>
                            <SCLinkMenuWrapper>
                                <Link to={Routes.CLAIM}>
                                    <TSButton
                                        ariaLabel="File a Claim button"
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.BOLD}
                                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                                            select(NavigationSelected.CLAIM);
                                            handleClose();
                                        }}
                                    >
                                        File a Claim
                                    </TSButton>
                                </Link>
                            </SCLinkMenuWrapper>
                        </SCNavElement>
                        <SCNavElement backgroundColor={selected.claimLookup}>
                            <SCLinkMenuWrapper>
                                <Link to={Routes.CLAIM_LOOKUP}>
                                    <TSButton
                                        ariaLabel="Check Claim Status button"
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.BOLD}
                                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                                            select(NavigationSelected.CLAIM_LOOKUP);
                                            handleClose();
                                        }}
                                    >
                                        Check Claim Status
                                    </TSButton>
                                </Link>
                            </SCLinkMenuWrapper>
                        </SCNavElement>
                        <SCNavElement backgroundColor={selected.faqs}>
                            <SCLinkMenuWrapper>
                                <Link to={Routes.FAQS}>
                                    <TSButton
                                        ariaLabel="FAQs button"
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.BOLD}
                                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                                            select(NavigationSelected.FAQS);
                                            handleClose();
                                        }}
                                    >
                                        FAQs
                                    </TSButton>
                                </Link>
                            </SCLinkMenuWrapper>
                        </SCNavElement>
                        <SCNavElement backgroundColor={selected.contact}>
                            <SCLinkMenuWrapper>
                                <Link to={Routes.CONTACT}>
                                    <TSButton
                                        ariaLabel="Contact button"
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.BOLD}
                                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                                            select(NavigationSelected.CONTACT);
                                            handleClose();
                                        }}
                                    >
                                        Contact
                                    </TSButton>
                                </Link>
                            </SCLinkMenuWrapper>
                        </SCNavElement>
                        {isAuthenticated && (
                            <SCNavElement backgroundColor={selected.admin}>
                                <SCLinkMenuWrapper>
                                    <Link to={authenticationData.authenticatedRedirectPath}>
                                        <TSButton
                                            ariaLabel="Admin button"
                                            tsVariant={TSButtonVariantsEnum.SLIDE}
                                            size={TSButtonSizeEnum.LARGE}
                                            weight={TSButtonWeightEnum.BOLD}
                                            onClick={(event: SyntheticEvent<Element, Event>): void => {
                                                select(NavigationSelected.ADMIN);
                                                handleClose();
                                            }}
                                        >
                                            Admin
                                        </TSButton>
                                    </Link>
                                </SCLinkMenuWrapper>
                            </SCNavElement>
                        )}
                    </Menu>
                </SCNavElementWrapper>
            </SCMenuWrapper>
        </SCNav>
    );
};

export { TSTopNavigation };
