import styled from 'styled-components';

export const SCSvgWrapper = styled.div`
    width: 100%;
    max-width: 160px;
    min-width: 115px;
    height: auto;
    max-height: 35px;
`;
export const SCSvgRoot = styled.svg`
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
`;
export const SCSvgFillYellow = styled.g`
    fill: #fae07e;
    fill-rule: nonzero;
`;
export const SCSvgFillBlue = styled.g`
    fill: #193062;
    fill-rule: nonzero;
`;
export const SCCircle = styled.g`
    fill: #ffe98f;
    fill-rule: nonzero;
`;
