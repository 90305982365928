import styled, { Keyframes, keyframes } from 'styled-components';
import { Page } from 'react-pdf';

export const onPageChange: Keyframes = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`;

interface IPDFControlsWrapper {
    width: number | string;
}
interface IDownloadLink {
    shouldHide: boolean;
}
export const SCPDFViewerComponentWrapper = styled.div`
    align-content: center;
    width: 100%;
`;
export const SCPDFControlsWrapper = styled.div<IPDFControlsWrapper>`
    display: flex;
    justify-content: space-between;
    width: ${(props): string => `${props.width}`};
`;
export const SCRenderedPDFWrapper = styled.div`
    max-width: 100vw;
    width: 100%;
`;
export const SCPage = styled(Page)`
    animation: ${onPageChange} 0.65s ease-out;
`;
export const SCDownloadLink = styled.a<IDownloadLink>`
    display: ${(props): string => (props.shouldHide ? 'none' : 'inline-block')};
`;
export const SCPaginationWrapperDesktop = styled.span`
    float: right;
    visibility: visible;
    @media only screen and (max-device-width: 380px) {
        display: none;
    }
    @media only screen and (max-device-width: 480px) {
        display: none;
    }
`;
export const SCPaginationWrapperMobile = styled.div`
    display: flex;
    visibility: hidden;
    @media only screen and (max-device-width: 380px) {
        visibility: visible;
    }
    @media only screen and (max-device-width: 480px) {
        visibility: visible;
    }
`;
