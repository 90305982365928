import TagManager from 'react-gtm-module';
import { TrackerEnum } from 'enums/TrackerType';
export class AnalyticsService {
    private static instance: AnalyticsService;
    constructor() {
        this.initializeGTM();
        document.addEventListener(TrackerEnum.PAGE_VIEW, this.sendPageChangeAnalytics);
        document.addEventListener(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, (ev: Event) => {
            if (ev instanceof CustomEvent) {
                const { claimType, distributorName, id } = ev.detail;
                if (id) {
                    switch (id) {
                        case TrackerEnum.CLAIMSTEP_START:
                        case TrackerEnum.CLAIMSTEP_PERSONAL_INFO:
                        case TrackerEnum.CLAIMSTEP_DISABILITY_DETAILS:
                        case TrackerEnum.CLAIMSTEP_JOBLOSS_DETAILS:
                        case TrackerEnum.CLAIMSTEP_EMPLOYER_INFO:
                        case TrackerEnum.CLAIMSTEP_HEALTHCARE_INFO:
                        case TrackerEnum.CLAIMSTEP_COMMENTS:
                        case TrackerEnum.CLAIMSTEP_REVIEW:
                        case TrackerEnum.CLAIMSTEP_SUBMITTED:
                        case TrackerEnum.CLAIMSTEP_SUBMIT_ATTEMPT_FAILED:
                            this.claimProcessAnalytics.sendToDataLayer(claimType, distributorName, id);
                            break;
                    }
                }
            }
        });
        document.addEventListener(TrackerEnum.BORROWER_NAME_CHANGED, (ev: Event) => {
            if (ev instanceof CustomEvent) {
                const { claimType, distributorName, isBorrowerFirstLastNamePopulated } = ev.detail;
                this.borrowerNameFieldAnalytics.sendToDataLayer(
                    claimType,
                    distributorName,
                    isBorrowerFirstLastNamePopulated
                );
            }
        });
        document.addEventListener(TrackerEnum.LAST_CLAIM_FIELD_CHANGED, (ev: Event) => {
            if (ev instanceof CustomEvent) {
                const { claimType, distributorName, lastClaimFieldChanged } = ev.detail;
                this.lastClaimFieldChangedAnalytics.sendToDataLayer(claimType, distributorName, lastClaimFieldChanged);
            }
        });
        document.addEventListener(TrackerEnum.DISTRIBUTOR_SELECT, this.sendDIDSelectionPageAnalytics);
    }
    private sendPageChangeAnalytics = (): void => {
        const currentPath = window.location.pathname;
        const event = TrackerEnum.PAGE_VIEW;
        const page = currentPath.split('/');
        TagManager.dataLayer({
            dataLayer: {
                event,
                page_location: currentPath,
                page_title: page
            }
        });
    };
    private sendDIDSelectionPageAnalytics = (): void => {
        const currentPath = window.location.pathname;
        const event = TrackerEnum.PAGE_VIEW;
        const page = 'DID Selection';
        TagManager.dataLayer({
            dataLayer: {
                event,
                page_location: currentPath,
                page_title: page
            }
        });
    };
    private claimProcessAnalytics = {
        sendToDataLayer: (claimType: string, distributorName: string, processState: string): void => {
            TagManager.dataLayer({
                dataLayer: {
                    event: TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS,
                    claimType: claimType,
                    distributorName: distributorName,
                    claimStep: processState
                }
            });
        }
    };
    private borrowerNameFieldAnalytics = {
        sendToDataLayer: (
            claimType: string,
            distributorName: string,
            isBorrowerFirstLastNamePopulated: boolean
        ): void => {
            TagManager.dataLayer({
                dataLayer: {
                    event: TrackerEnum.BORROWER_NAME_CHANGED,
                    claimType: claimType,
                    distributorName: distributorName,
                    isBorrowerFirstLastNamePopulated: isBorrowerFirstLastNamePopulated
                }
            });
        }
    };
    private lastClaimFieldChangedAnalytics = {
        sendToDataLayer: (claimType: string, distributorName: string, lastClaimFieldChanged: string): void => {
            TagManager.dataLayer({
                dataLayer: {
                    event: TrackerEnum.LAST_CLAIM_FIELD_CHANGED,
                    claimType: claimType,
                    distributorName: distributorName,
                    lastClaimFieldChanged: lastClaimFieldChanged
                }
            });
        }
    };
    private initializeGTM = (): void => {
        const tagManagerArgs = {
            gtmId: `${process.env.REACT_APP_PAYGUARD_APP_GTMID}`
        };
        TagManager.initialize(tagManagerArgs);
    };
    public static init(): AnalyticsService {
        if (!AnalyticsService.instance) {
            AnalyticsService.instance = new AnalyticsService();
        }
        return AnalyticsService.instance;
    }
}
