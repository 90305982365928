import React from 'react';
import { ITableHeadEnhancedExternalProps } from './types';
import { DataExternal, HeadCellExternal } from 'components/pages/ClaimsManagement';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { SCTableHeadCellWrapper } from './styles';
import ArrowSortIcon from 'components/atoms/ArrowSortIcon/ArrowSortIcon';
import { editFormLabels } from 'support/formLabels';

export const headCells: readonly HeadCellExternal[] = [
    {
        id: 'claimNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimNumber
    },
    {
        id: 'borrowerFullName',
        numeric: false,
        disablePadding: true,
        label: editFormLabels.borrowerName
    },
    {
        id: 'loanNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.loanNumber
    },
    {
        id: 'peril',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.lossType
    },
    {
        id: 'claimDecision',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimDecision
    },
    {
        id: 'amountPayable',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.amountPayable
    },
    {
        id: 'decisionReason',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.decisionReason
    },
    {
        id: 'decisionDateTime',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimDecisionDate
    },
    {
        id: 'claimStatusUpdatedDateTime',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimStateUpdatedDate
    },
    {
        id: 'claimSubmitDate',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimSubmitDate
    },
    {
        id: 'reviewDetails',
        numeric: false,
        disablePadding: false,
        label: ''
    }
];

export const csvHeadCells: readonly HeadCellExternal[] = [
    {
        id: 'claimNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimNumber
    },
    {
        id: 'borrowerFullName',
        numeric: false,
        disablePadding: true,
        label: editFormLabels.borrowerName
    },
    {
        id: 'loanNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.loanNumber
    },
    {
        id: 'peril',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.lossType
    },
    {
        id: 'claimDecision',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimDecision
    },
    {
        id: 'amountPayable',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.amountPayable
    },
    {
        id: 'decisionReason',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.decisionReason
    },
    {
        id: 'decisionDateTime',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimDecisionDate
    },
    {
        id: 'claimStatusUpdatedDateTime',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimStateUpdatedDate
    },
    {
        id: 'claimSubmitDate',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimSubmitDate
    }
];

const TableHeadEnhancedExternal = ({ order, orderBy, onRequestSort }: ITableHeadEnhancedExternalProps): JSX.Element => {
    const createSortHandler = (property: keyof DataExternal) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::AdminLanding:::::::::::::::::::::::::::::::::',
    //     '\n::onRequestSort::',
    //     onRequestSort,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <SCTableHeadCellWrapper key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowSortIcon}
                            aria-label={headCell.label === '' ? 'Button' : headCell.label}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </SCTableHeadCellWrapper>
                ))}
            </TableRow>
        </TableHead>
    );
};

export { TableHeadEnhancedExternal };
