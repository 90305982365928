import React from 'react';
import { IEConsentDisclaimerProps } from './types';
import { SCDisclaimer, SCHRElement, SCSubHead } from 'styles/global-styles';

const EConsentDisclaimer = ({ children }: IEConsentDisclaimerProps): JSX.Element => {
    return (
        <>
            <SCSubHead>Electronic Communications Consent and Agreement</SCSubHead>
            <SCHRElement />
            <SCDisclaimer>
                <p>
                    Through your electronic acceptance of this Electronic Communications Consent and Agreement
                    (“Agreement”) you consent to conducting transactions in electronic form with TruStage Financial
                    Group, Inc., and its subsidiaries and affiliates (“TruStage”). This can include the use of
                    electronic communications, electronic records, electronic signatures for the communications, and
                    electronic notices and disclosures described below. You also acknowledge that you have read and
                    agree to this Agreement.
                </p>
                <p>
                    It is your responsibility to provide us with an accurate email address as well as other contact
                    information. It is also your responsibility to inform us of any changes in this information.
                </p>
                <p>
                    You are not obligated to enter into this Agreement and you may conduct transactions in paper format
                    instead. There are no conditions, consequences or fees associated with withdrawing your consent.
                </p>
                <p>
                    If you prefer to conduct transactions in paper form, or you choose to withdraw your consent to
                    electronic transactions, or you wish to update your contact information, please contact the
                    TruStage™ Payment Guard insurance team. You can call us toll-free at
                    {'  '}
                    <a href="tel:855-997-7272">855-997-7272</a> or write to TruStage™ Payment Guard insurance, P.O. Box
                    61 Waverly, IA 50677-0061. There is no fee charged for such requests.
                </p>
                <p>
                    Through your electronic acceptance of this Agreement, You consent to the use of electronic
                    communications, electronic records and electronic signatures rather than their paper counterparts
                    for delivery to you of any and all:
                </p>
                <ul>
                    <li>
                        Notices and/or disclosures that various federal and/or state laws or regulations require that we
                        provide to you
                    </li>
                    <li>
                        Notices, documents, statements, data, records or communications regarding your coverage,
                        including changes in terms of coverage
                    </li>
                    <li>Privacy policies and notices</li>
                    <li>
                        Any other notices, correspondence, forms, documents, or other written materials of any kind
                        related to TruStage’s provision of products or services to you.
                    </li>
                </ul>
                <p>
                    You understand and agree that your acceptance of this Agreement provides consent to TruStage to
                    deliver legally required written notices electronically. Delivery of documents or communications
                    electronically will be considered “in writing” and you agree that the electronic documents or
                    communications have the same legal effect as written and signed paper documents or communications.
                    To ensure uninterrupted communication, please be sure to update the email address you provided to
                    TruStage in the event your email address changes.
                </p>
                <p>
                    You understand and agree that in order to access and retain the documents we send to you
                    electronically, a minimum standard of technology is required. The hardware, software and services
                    necessary include, but may not be limited to, a fully functional: computer, smartphone, or other
                    device which supports web browser software; internet access capability; email address and email
                    software; and web-browsing software compatible with this site such as Internet Explorer. You will be
                    notified of any changes to the hardware and/or software requirements that may create a material risk
                    that you will not be able to receive, view, print or save a document or communication.
                </p>
            </SCDisclaimer>
        </>
    );
};

export { EConsentDisclaimer };
