import styled from 'styled-components';
import { onLoad } from 'styles/global-styles';

interface IDecisionContent {
    widthProps: string;
}
export const SCFormWrapper = styled.div`
    width: 100%;
    max-width: 75%;
    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;
export const SCDecisionContent = styled.div<IDecisionContent>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    width: ${(props): string => {
        return props.widthProps ? props.widthProps : '100%';
    }};
    min-height: 100px;
    animation: ${onLoad} 0.55s ease-out;
    @media (max-width: 1440px) {
        width: 100%;
    }
    @media (max-width: 1024px) {
        width: ${(props): string => {
            return props.widthProps ? props.widthProps : '100%';
        }};
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const SCDecisionItem = styled.div`
    padding: 40px;
    margin-right: 2px;
    margin-left: 2px;
    border-top: solid black 5px;
    box-shadow: 0 11px 14px 4px rgba(0, 0, 0, 0.05);
`;
export const SCContactInfoTop = styled.div`
    display: initial;
    word-break: break-word;
    min-width: 220px;
    @media (max-width: 1024px) {
        display: none;
    }
`;
export const SCContactInfoBottom = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: initial;
    }
`;
