import styled from 'styled-components';

export const SCDiv = styled.div`
    size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    padding: 4px 10px 4px 0px;
`;

export const SCLabel = styled.span`
    font-weight: 700;
    padding-right: 8px;
    display: inline-block;
`;

export const SCValue = styled.span`
    overflow-wrap: break-word;
`;
