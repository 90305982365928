import styled from 'styled-components';

export const SCDiv = styled.div``;

export const SCChangeFormType = styled.div`
    font-size: 21px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: underline #608cff;
    &:hover {
        cursor: pointer;
    }
`;
