import styled from 'styled-components';

export const SCElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
export const SCButtonWrapper = styled.div`
    padding: 0 16px 0 16px;
    @media (max-width: 768px) {
        padding: 0 8px 0 8px;
    }
`;
export const SCRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    width: 100%;
    padding: 8px 0 8px 0;
`;
