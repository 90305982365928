import styled from 'styled-components';
import {
    onLoad,
    SCContentDirectionWrapper,
    SCContentWrapper,
    SCHeroHeader,
    SCHeroImage,
    SCImageContent,
    SCImageWrapper,
    SCPageWrapper,
    SCSubHead
} from 'styles/global-styles';

interface IFlexContentJustify {
    HeaderStyledProps: string;
}

export const SCDistributorSelectorSCPageWrapper = styled(SCPageWrapper)`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    height: 100vh;
    width: 100vw;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    animation: ${onLoad} 0.55s ease-out;
`;

//Styles for header
export const SCDistributorSelectorHeaderWrapper = styled.header`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    @media (max-width: 1024px) {
        height: 70px;
    }
`;

export const SCDistributorSelectorHeaderContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10% 0 10%;
    gap: 20px;
    align-content: flex-end;
    width: 100vw;
    height: 100px;
    max-width: 100%;
    @media (max-width: 1024px) {
        height: 60px;
        gap: 29px;
        flex-wrap: wrap;
        align-content: space-around;
        padding: 0;
    }
`;

export const SCDistributorSelectorHeaderContent = styled.header`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    max-width: 1476px;
    height: auto;
    padding: 16px;
    @media (max-width: 1024px) {
        max-width: 100%;
    }
    a {
        margin: 0;
    }
`;

//Styles for content
export const SCDistributorSelectorContentWrapper = styled(SCContentWrapper)`
    margin-top: 100px;
    padding-bottom: 100px;
    height: 100%;
    @media (max-width: 1024px) {
        padding-bottom: 0;
    }
`;

//Styles for image and top wording
export const SCDistributorSelectorImageWrapper = styled(SCImageWrapper)`
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 50px 0 0 0;
    @media (max-width: 1024px) {
        display: initial;
        margin: 24px 0 0 0;
    }
`;
export const SCDistributorImageContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: 100%;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    max-width: 50vw;
    min-width: 40vw;
    min-height: 150px;
    padding: 5px 5px 5px 0;
    background-color: rgba(255, 255, 255, 1);
    @media (max-width: 1024px) {
        max-width: 100%;
        padding: 20px 20px 20px 20px;
    }
    a {
        color: rgb(24, 142, 163);
        cursor: pointer;
    }
`;
export const SCDistributorSelectorImage = styled(SCHeroImage)`
    border: 0;
    height: 396px;
    min-width: 30vw;
`;

export const SCDistributorSelectorImageContent = styled(SCImageContent)`
    max-width: 100%;
    padding: 0 24px 0 0;
    margin-bottom: 180px;
    @media (max-width: 1024px) {
        padding: 40px 0 16px 0;
        margin-bottom: 80px;
    }
    @media (max-width: 768px) {
        padding: 40px 32px 16px 32px;
    }
`;

export const SCDistributorSelectorSelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1440px;
    @media (max-width: 1024px) {
        margin-left: 0;
        margin-top: 0;
        padding: 0 0 16px 0;
    }
    @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 0;
        padding: 0 32px 16px 32px;
    }
`;

export const SCDistributorSelector = styled(SCContentDirectionWrapper)`
    column-count: 2;
    justify-content: left;
    align-items: end;
    max-height: 150px;
    padding-left: 0;
    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 16px 16px 16px 16px;
        align-items: start;
    }
`;

export const SCAutocompleteWrapper = styled.div`
    display: inline-block;
    background: #ffffff;
    left: 0;
    width: 639px;
    height: auto;
    padding: 0 15px 0 0;
    @media (max-width: 1024px) {
        margin-bottom: 20px;
        width: 100%;
    }
`;

export const SCHeroHeaderTrustage = styled(SCHeroHeader)`
    font-size: 72px;
    color: #193062;
    letter-spacing: 0;
    line-height: 80px;
    padding: 0;
    @media (max-width: 1440px) {
        font-size: 44px;
        line-height: 60px;
        margin: 10px;
    }
`;
// SubHead for the new Trustage branding
export const SCSubHeadTrustage = styled(SCSubHead)`
    font-weight: 700;
    font-style: normal;
    padding: 16px 0 16px 0;
    color: #231f20;
    font-size: 20px;
    line-height: 26px;
    @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 18.35px;
        padding: 16px 0 16px 0;
    }
`;

export const SCDistributorSelectorSelectorTitle = styled.h2`
    font-weight: 700;
    font-style: normal;
    color: #193062;
    font-size: 48px;
    line-height: 60px;
    padding: 16px 0 16px 0;
    @media (max-width: 1440px) {
        font-size: 32px;
        line-height: 40px;
        padding: 16px 0 16px 0;
    }
`;

export const SCDistributorSelectorSelectorContent = styled.div`
    font-weight: 400;
    font-style: normal;
    color: #231f20;
    font-size: 16px;
    line-height: 26px;
    @media (max-width: 1440px) {
        padding: 16px 0 16px 0;
    }
`;
