import styled from 'styled-components';
import { TimelineState } from 'enums';
interface ISvgRectangle {
    status?: string;
    animate: boolean;
}
interface ITextWrapperStepper {
    status?: string;
    animate: boolean;
}

export const SCSvgWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    width: 63px;
    height: 64px;
    shape-rendering: crispEdges;
    transition: background-color 0.25s ease-out;
`;
export const SCSvgRoot = styled.svg`
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    max-height: 4px;
`;
export const SCSvgRectangle = styled.rect<ISvgRectangle>`
    fill: ${(props): string =>
        props.status !== TimelineState.INCOMPLETE ? `rgba(250, 224, 126, 1)` : `rgba(250, 224, 126, 0)`};
    height: ${(props): string => (props.status !== TimelineState.INCOMPLETE ? `4px` : `0px`)};
    transition: fill 0.25s ease-out;
`;

export const SCTextWrapperStepper = styled.div<ITextWrapperStepper>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    font-weight: ${(props): string =>
        props.status === TimelineState.ACTIVE ? `700` : props.animate === true ? `500` : `300`};
    font-size: ${(props): string => {
        return props.status === TimelineState.ACTIVE ? `21px` : `16px`;
    }};
    color: ${(props): string => {
        return props.status === TimelineState.ACTIVE ? `rgba(25, 48, 98, 1)` : `rgba(100, 100, 104, 1)`;
    }};
    transition: font-weight, font-size 0.35s ease-out;
`;
