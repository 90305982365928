import React from 'react';
import {
    TSButtonLetterSpacingEnum,
    TSButtonSizeEnum,
    TSButtonVariantsEnum,
    TSButtonWeightEnum
} from 'enums/TSButtonVariants';
import {
    SCLinksButton,
    SCLinksText,
    SCLinksWrapper,
    SCQuickLinksContentWrapper,
    SCQuickLinksTitle,
    SCQuickLinksTopBanner,
    SCQuickLinksWrapper
} from './styles';
import { ITSQuickLinksProps } from './types';
import { useHistory } from 'react-router-dom';

const TSQuickLinks = ({ id, ariaLabel, links, title }: ITSQuickLinksProps): JSX.Element => {
    const history = useHistory();

    const handleLinkClick = (destination: string, arrivalOption: string): void => {
        switch (arrivalOption) {
            case 'newWindow': {
                window.open(destination);
                break;
            }
            case 'route': {
                history.push(destination);
                break;
            }
            case 'scroll': {
                const element = document.getElementById(destination);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            }
            default:
                break;
        }
    };

    return (
        <SCQuickLinksWrapper id={id} aria-label={ariaLabel}>
            <SCQuickLinksTopBanner />
            <SCQuickLinksContentWrapper>
                <SCQuickLinksTitle>{title}</SCQuickLinksTitle>
                <SCLinksWrapper>
                    {links.map((link, index) => (
                        <SCLinksText key={`Key${index}`} data-testid={link.arrivalOption === 'scroll' && 'scroll'}>
                            <SCLinksButton
                                ariaLabel={link.title}
                                size={TSButtonSizeEnum.MEDIUM}
                                letterSpacing={TSButtonLetterSpacingEnum.NARROW}
                                weight={TSButtonWeightEnum.BOLD}
                                tsVariant={TSButtonVariantsEnum.UNDERLINE}
                                onClick={
                                    link.onClick
                                        ? link.onClick
                                        : (event): void => {
                                              event.preventDefault();
                                              handleLinkClick(link.destination, link.arrivalOption);
                                          }
                                }
                            >
                                {link.title}
                            </SCLinksButton>
                        </SCLinksText>
                    ))}
                </SCLinksWrapper>
            </SCQuickLinksContentWrapper>
        </SCQuickLinksWrapper>
    );
};

export { TSQuickLinks };
