import React, { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AdminTypes, IAdminLandingProps } from './types';
import {
    $deepBlue,
    SCContentDirectionWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCPageWrapper,
    SCSubHead,
    SCThreeColumnContent,
    SCThreeColumnWrapper
} from 'styles/global-styles';
import { SCResets } from 'components/pages/ClaimsManagement/styles';
import { TSButton } from 'components/atoms/TSButton';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { Routes } from 'routes';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import {
    SCAdminBoxHeading,
    SCAdminContentEyebrow,
    SCAdminContentWrapper,
    SCThreeColumnAdminContentWrapper
} from 'components/pages/AdminLanding/styles';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';
import { IClientContextState, IClientContextApiResponse } from 'types/client-context';
import { ClientContextsService } from 'api/ClientContextsService';

const AdminLanding = ({ children }: IAdminLandingProps): ReactElement => {
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const { saveClientContextDataState, clientContextDataState } = React.useContext(
        DistributorConfigurationStateContext
    ) as IClientContextState;
    const getClientConfiguration = async (distributorKey: string, loanServicerKey: string): Promise<void> => {
        const data = (await ClientContextsService(distributorKey, loanServicerKey)) as IClientContextApiResponse;
        if (data?.payload) {
            localStorage.removeItem(LocalStorageKeys.DISTRIBUTOR_KEY);
            localStorage.removeItem(LocalStorageKeys.LOAN_SERVICER_KEY);
            localStorage.setItem(LocalStorageKeys.DISTRIBUTOR_KEY, distributorKey as string);
            saveClientContextDataState(data.payload);
        }
        void (data?.payload && saveClientContextDataState(data.payload));
    };
    useEffect(() => {
        const localDistributorKey = localStorage.getItem(LocalStorageKeys.DISTRIBUTOR_KEY);
        const authDistributorKey: string = authenticationData.distributorKey as string;
        const isExternalAdmin = authenticationData.groups?.includes(AdminTypes.EXTERNAL_DISTRIBUTOR_ADMIN);
        const contextSwitchNeeded =
            localDistributorKey !== authDistributorKey && authDistributorKey != null && authDistributorKey;
        if (contextSwitchNeeded && isExternalAdmin) {
            getClientConfiguration(authDistributorKey as string, '');
        }
    }, [localStorage.getItem(LocalStorageKeys.DISTRIBUTOR_KEY)]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::AdminLanding:::::::::::::::::::::::::::::::::',
    //     '\n::authenticationData::',
    //     authenticationData,
    //     '\n::authenticationData.groups::',
    //     authenticationData.groups,
    //     '\n::getDistributorListContext()::',
    //     getDistributorListContext(),
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCPageWrapper>
            <SCResets>
                <SCContentWrapper>
                    <SCContentDirectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    >
                        <SCAdminContentWrapper>
                            <SCAdminContentEyebrow>{`Payment Guard + ${
                                authenticationData.distributorKey || authenticationData.loanServicerKey
                            }`}</SCAdminContentEyebrow>
                            <SCSubHead textColor={$deepBlue}>Admin Tools</SCSubHead>
                            <SCThreeColumnWrapper>
                                <SCThreeColumnAdminContentWrapper flexDirection={FlexDirectionEnum.ROW}>
                                    <SCThreeColumnContent>
                                        <SCAdminBoxHeading textAlign="left" textColor="#646468">
                                            <p>Claims Reporting</p>
                                        </SCAdminBoxHeading>
                                        <p>
                                            Search and filter Payment Guard claims data, export CSVs, and track metrics
                                            for your organization.
                                        </p>
                                        <Link to={Routes.CLAIMS_MANAGEMENT}>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                ariaLabel={'button to Access Claims Reports'}
                                            >
                                                Access Claims Reports
                                            </TSButton>
                                        </Link>
                                    </SCThreeColumnContent>
                                    <SCThreeColumnContent>
                                        <SCAdminBoxHeading textAlign="left" textColor="#646468">
                                            <p>Check a Specific Claim Status</p>
                                        </SCAdminBoxHeading>
                                        <p>
                                            Search and view the details of individual claims that have been submitted
                                            for processing already, including their processing status.
                                        </p>
                                        <Link to={Routes.CLAIM_LOOKUP}>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                size={TSButtonSizeEnum.LARGE}
                                                ariaLabel={'button to Check Claim Status'}
                                            >
                                                Check Claim Status
                                            </TSButton>
                                        </Link>
                                    </SCThreeColumnContent>
                                    <SCThreeColumnContent>
                                        <SCAdminBoxHeading textAlign="left" textColor="#646468">
                                            <p>File a Claim</p>
                                        </SCAdminBoxHeading>
                                        <p>
                                            You can start and submit a policy claim on behalf of a policy holder here.
                                        </p>
                                        <Link to={Routes.CLAIM}>
                                            <TSButton
                                                ariaLabel={'button to File a New Claim'}
                                                size={TSButtonSizeEnum.LARGE}
                                            >
                                                File a New Claim
                                            </TSButton>
                                        </Link>
                                    </SCThreeColumnContent>
                                </SCThreeColumnAdminContentWrapper>
                            </SCThreeColumnWrapper>
                            <SCThreeColumnWrapper>
                                <SCThreeColumnAdminContentWrapper flexDirection={FlexDirectionEnum.ROW}>
                                    <SCThreeColumnContent>
                                        <SCAdminBoxHeading textAlign="left" textColor="#646468">
                                            <p>Administrative Guide</p>
                                        </SCAdminBoxHeading>
                                        <p>Explore the guide to Payment Guard.</p>
                                        <Link to={Routes.ADMIN_GUIDE}>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                ariaLabel={'button to Administrative Guide'}
                                            >
                                                Access Admin Guide
                                            </TSButton>
                                        </Link>
                                    </SCThreeColumnContent>
                                    <SCThreeColumnContent>
                                        <SCAdminBoxHeading textAlign="left" textColor="#646468">
                                            <p>Marketing Guidelines</p>
                                        </SCAdminBoxHeading>
                                        <p>
                                            Find pre-compliant, ready to use assets, and details on how to make custom
                                            copy.
                                        </p>
                                        <Link to={Routes.MARKETING_GUIDELINES}>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                size={TSButtonSizeEnum.LARGE}
                                                ariaLabel={'button to Marketing Guidelines'}
                                            >
                                                Access Marketing Guidelines
                                            </TSButton>
                                        </Link>
                                    </SCThreeColumnContent>
                                    <SCThreeColumnContent>{/** Empty */}</SCThreeColumnContent>
                                </SCThreeColumnAdminContentWrapper>
                            </SCThreeColumnWrapper>
                        </SCAdminContentWrapper>
                    </SCContentDirectionWrapper>
                </SCContentWrapper>
            </SCResets>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCPageWrapper>
    );
};

export { AdminLanding };
