import React, { useEffect, useState } from 'react';
import { SCContentWrapper, SCFooterWrapper, SCPageWrapper, SCSectionInfo } from 'styles/global-styles';
import { IPdfViewerProps } from './types';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { SCPdfViewerWrapper, SCHeroHeaderCustom } from 'components/pages/PdfViewer/styles';
import { TSPdfViewerComponent } from '../../molecules/TSPdfViewerComponent';
import { ClientContextsService } from 'api/ClientContextsService';
import { IClientContentData, IClientContextApiResponse } from 'types/client-context';

const PdfViewer = ({ children }: IPdfViewerProps): JSX.Element => {
    const [distributorKeyRequested, setDistributorKeyRequested] = useState<string>();
    const [pdfUri, setPdfUri] = useState<string>();
    const [pdfRequest, setPdfRequest] = useState<string>();
    const [pageHeaderText, setPageHeaderText] = useState<string>();
    const [pageInfoText, setPageInfoText] = useState<string>();
    const [downloadLabel, setDownloadLabel] = useState<string>();
    const [didLoadFail, setDidLoadFail] = useState<boolean>(false);
    const setDistributorConfigurationData = (clientContent: IClientContentData): void => {
        if (clientContent) {
            if (!clientContent?.certificate_of_insurance_uri) {
                return setErrorInformation('We could not load the document you requested at this time.');
            }

            if (pdfRequest == 'certificateofinsurance') {
                setPdfUri(clientContent.certificate_of_insurance_uri);
                setPageHeaderText('Certificate of Insurance');
                setPageInfoText('You can review and download a PDF of your official Certificate of Insurance here.');
                setDownloadLabel('Download Certificate of Insurance PDF');
            } else {
                return setErrorInformation(
                    'We could not load the document you requested at this time. The link you have received may be invalid.'
                );
            }
        }
    };

    const setErrorInformation = (errorDisplayText: any): void => {
        setDidLoadFail(true);
        setPageInfoText(errorDisplayText);
    };
    useEffect(() => {
        //Process URL Params (distributor + document type requested)
        const queryParameters = new URLSearchParams(window.location.search);
        const pdfRequestLocal = queryParameters.get('pdfview');
        const requestedDistributorLocal = queryParameters.get('did');
        if (!pdfRequestLocal || !requestedDistributorLocal) {
            return setErrorInformation('We could not load the document you requested at this time.');
        }
        setPdfRequest(pdfRequestLocal);
        setDistributorKeyRequested(requestedDistributorLocal as string);
    }, []);

    useEffect(() => {
        let isLoaded = true;
        if (distributorKeyRequested) {
            ClientContextsService(distributorKeyRequested as string).then(
                (data: IClientContextApiResponse | unknown) => {
                    const client_content = (data as IClientContextApiResponse)?.payload?.client_content;
                    if (client_content && isLoaded) {
                        setDistributorConfigurationData(client_content);
                    }
                    if (!data) {
                        return setErrorInformation('We could not load the document you requested at this time.');
                    }
                }
            );
            return () => {
                isLoaded = false;
            };
        }
    }, [distributorKeyRequested]);

    return (
        <SCPageWrapper>
            <SCContentWrapper>
                {pdfUri && (
                    <SCPdfViewerWrapper>
                        <SCHeroHeaderCustom>{pageHeaderText}</SCHeroHeaderCustom>
                        <SCSectionInfo>{pageInfoText}</SCSectionInfo>
                        <TSPdfViewerComponent pdfFileUri={pdfUri} downloadLabel={downloadLabel} />
                    </SCPdfViewerWrapper>
                )}
                {!pdfUri && didLoadFail && (
                    <SCPdfViewerWrapper>
                        <SCHeroHeaderCustom>Oops!</SCHeroHeaderCustom>
                        <SCSectionInfo>{pageInfoText}</SCSectionInfo>
                    </SCPdfViewerWrapper>
                )}
                <SCFooterWrapper>
                    <GlobalFooter />
                </SCFooterWrapper>
            </SCContentWrapper>
        </SCPageWrapper>
    );
};

export { PdfViewer };
