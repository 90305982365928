import styled from 'styled-components';

interface ISvgBar {
    animate: boolean;
    disabled: boolean;
}

export const SCSvgWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    overflow: hidden;
`;
export const SCSvgRoot = styled.svg`
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
`;
export const SCSVGBar = styled.rect<ISvgBar>`
    fill: ${(props): string =>
        props.animate === true
            ? props.disabled
                ? `rgba(255, 255, 255, 0.5)`
                : `rgba(25, 48, 98, 1)`
            : props.disabled
            ? `rgba(25, 48, 98, 0.5)`
            : `rgba(25, 48, 98, 1)`};
    transition: fill 0.25s ease-out;
`;
