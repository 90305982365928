import styled from 'styled-components';
import { $digitalBlack, $yellow } from 'styles/global-styles';

interface ILinkWrapper {
    underline: boolean;
}
interface INavElement {
    backgroundColor: boolean;
}
export const SCNav = styled.nav`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 850px;
    height: auto;
    @media (max-width: 768px) {
        ul {
            visibility: hidden;
        }
        nav button {
            display: flex;
        }
        max-height: 32px;
        max-width: 100%;
    }
`;
export const SCNavList = styled.ul`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 24px;
    @media (max-width: 1024px) {
        display: none;
    }
`;
export const SCMenuWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`;
export const SCNavIcon = styled.i`
    display: none;
    @media (max-width: 1024px) {
        display: initial;
        button {
            padding: 0;
        }
    }
`;
export const SCNavElementWrapper = styled.div``;
export const SCNavElement = styled.div<INavElement>`
    display: flex;
    flex-flow: column nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
`;
export const SCTSNavElement = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-right: 0px;
    div {
        min-height: auto;
        min-width: auto;
        padding: 0 8px 0 8px;
        button {
            padding: 0;
            margin: 0;
            div {
                color: ${$digitalBlack};
            }
        }
    }
`;
export const SCLinkWrapper = styled.div<ILinkWrapper>`
    width: 100%;
    text-decoration: ${(prop): string => (prop.underline ? 'underline' : 'none')};
    text-underline-offset: 4px;
    button {
        min-width: 100%;
    }
`;
export const SCLinkMenuWrapper = styled.div`
    width: 100%;
    padding: 0 20px 0 20px;
    background-image: none !important;
    div {
        background-image: none !important;
    }
    button {
        min-width: 100%;
        background: none !important;
    }
`;
