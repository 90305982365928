import React, { ReactElement } from 'react';
import {
    SCContentSectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCPageWrapper
} from 'styles/global-styles';
import { IClaimLookupProps } from './types';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { ClaimLookupDataStateContext } from 'context/claimLookupDataContext';
import { IClaimLookupResponseDataState } from 'types/claim-lookup';
import { ClaimLookupResult } from 'components/organisms/ClaimLookupResult';
import { FormClaimLookup } from 'components/organisms/FormClaimLookup';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';

const ClaimLookup = ({ children }: IClaimLookupProps): ReactElement => {
    const { claimLookupResponseDataState, saveClaimLookupResponseDataState } = React.useContext(
        ClaimLookupDataStateContext
    ) as IClaimLookupResponseDataState;
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ClaimLookup:::::::::::::::::::::::::::::::::',
    //     '\n::claimLookupResponseDataState::',
    //     claimLookupResponseDataState,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCPageWrapper>
            <SCContentWrapper>
                <SCContentSectionResponsiveWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                >
                    <SCContentSectionWrapper>
                        {!claimLookupResponseDataState.shouldDisplayResult &&
                            !claimLookupResponseDataState.shouldDisplayNoResult && <FormClaimLookup />}
                        {(claimLookupResponseDataState.shouldDisplayResult ||
                            claimLookupResponseDataState.shouldDisplayNoResult) && (
                            <ClaimLookupResult data={claimLookupResponseDataState}></ClaimLookupResult>
                        )}
                    </SCContentSectionWrapper>
                </SCContentSectionResponsiveWrapper>
            </SCContentWrapper>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCPageWrapper>
    );
};

export { ClaimLookup };
