import React, { useEffect, useRef, useState } from 'react';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { FormDisabilityStateContext } from 'context/formDisabilityStateContext';
import { IClientContextState } from 'types/client-context';
import { IFormDisabilityData, IFormDisabilityState } from 'types/form';
import { IFormDisabilityProps } from './types';
import {
    $deepBlue,
    $digitalBlack,
    SCBodyLarge,
    SCContentDirectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCElementsWrapper,
    SCElementWrapper,
    SCErrorLabel,
    SCForm,
    SCFormNavigationWrapper,
    SCFormRefWrapper,
    SCFormTopNavWrapper,
    SCFormWrapper,
    SCSectionBody,
    SCSectionSubHead,
    SCSectionWrapper,
    SCSubHead
} from 'styles/global-styles';
import { TimelineState } from 'enums/TimeLine';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { IFormDisabilityProgressState } from 'types/form-progress';
import {
    keysBorrowDetail,
    keysDisabilityDetailsHealthcare,
    labelMapDisability
} from 'components/organisms/FormIU/fieldTypes';
import {
    validateDate,
    validateEmail,
    validatePhoneNumber,
    validateLast4SSN,
    validateZip,
    errorMapIU
} from 'utils/form/form';
import { FormNavigation } from 'components/molecules/FormNavigation';
import { TSBackNavigation } from 'components/molecules/TSBackNavigation';
import { TSFormProgressStepper } from 'components/molecules/TSFormProgressStepper';
import { TSTextField } from 'components/atoms/TSTextField';
import { TSMaskedTextField } from 'components/atoms/TSMaskedTextField';
import { TSAutocomplete } from 'components/atoms/TSAutocomplete';
import { TSRadioGroup } from 'components/atoms/TSRadioGroup';
import { BeforeUnload } from 'components/molecules/BeforeUnload';
import { stateOptionDict } from 'support/stateDictionary';
import { MaxFieldLengths } from 'enums/MaxFieldLengths';
import { SCChangeFormType } from './styles';
import { ClaimTypesEnum, TrackerEnum } from 'enums';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum, FormFieldWidthEnum } from 'enums/StyleTypes';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { Routes } from 'routes';
import { ClaimStateEnum } from 'enums/ClaimState';
import { FormTypeContext } from 'context/formTypeContext';
import { IFormTypeState } from 'types/claim-types';
import { TSDialog } from 'components/atoms/TSDialog';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { YesNoAutocompleteOptions, YesNoRadioOptions } from 'support/claimForm';
import { isPhoneIdentifierType } from 'utils/helpers/helpers';

const FormDisability = ({ children, changeFormType }: IFormDisabilityProps): JSX.Element => {
    const fieldRef = useRef<HTMLInputElement>(null);
    const { saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const { saveFormDisabilityState, formDisabilityState } = React.useContext(
        FormDisabilityStateContext
    ) as IFormDisabilityState;
    const { updateFormDisabilityProgressState, formDisabilityProgressState } = React.useContext(
        FormDisabilityProgressStateContext
    ) as IFormDisabilityProgressState;
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const [emailInputError, setEmailInputError] = useState<string | null>(null);
    const [zipInputError, setZipInputError] = useState<string | null>(null);
    const [phoneNumberInputError, setPhoneNumberInputError] = useState<string | null>(null);
    const [ssnInputError, setSsnInputError] = useState<string | null>(null);
    const [dateOfBirthInputError, setDateOfBirthInputError] = useState<string | null>(null);
    const [dateOfIncidentError, setDateOfIncidentError] = useState<string | null>(null);
    const [dateOfLastWorkedError, setDateOfLastWorkedError] = useState<string | null>(null);
    const [dateOfPhysicianSeenError, setDateOfPhysicianSeenError] = useState<string | null>(null);
    const [dateExpectedWorking, setDateExpectedWorking] = useState<string | null>(null);
    const [healthcareZipInputError, setHealthcareZipInputError] = useState<string | null>(null);
    const [healthcarePhoneNumberInputError, setHealthcarePhoneNumberInputError] = useState<string | null>(null);
    const [employerZipInputError, setEmployerZipInputError] = useState<string | null>(null);
    const [employerPhoneNumberInputError, setEmployerPhoneNumberInputError] = useState<string | null>(null);
    const [employerEmailInputError, setEmployerEmailInputError] = useState<string | null>(null);
    const [submitterPhoneNumberInputError, setSubmitterPhoneNumberInputError] = useState<string | null>(null);
    const [submitterEmailInputError, setSubmitterEmailInputError] = useState<string | null>(null);
    const [ssnInputType, setSsnInputType] = useState<string>('text');
    const [onBehalfOf, setOnBehalfOf] = useState<boolean>(false);
    const [knowWhenExpectedWorking, setKnowWhenExpectedWorking] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<IFormDisabilityData | Record<string, unknown>>();
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const [formDeepCopy, setFormDeepCopy] = React.useState<IFormDisabilityData | Record<string, unknown>>();
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [isPersonalInfoActive, setIsPersonalInfoActive] = useState(false);
    const [isDisabilityDetailsActive, setIsDisabilityDetailsActive] = useState(false);
    const [isEmployerInfoActive, setIsEmployerInfoActive] = useState(false);
    const [isHealthcareInfoActive, setIsHealthcareInfoActive] = useState(false);
    const [isCommentsActive, setIsCommentsActive] = useState(false);
    const [isBorrowerFirstAndLastPopulated, setIsBorrowerFirstAndLastPopulated] = useState(false);
    useEffect(() => {
        const contextStateClaimEditDeepCopy = structuredClone(formData);
        setFormDeepCopy(contextStateClaimEditDeepCopy);
    }, [formData]);
    useEffect(() => {
        formDeepCopy && JSON.stringify(formDeepCopy) !== JSON.stringify(formData) && setIsFormDirty(true);
    }, [formData, formDeepCopy]);
    const allowedRoutes = [
        `${window.location.origin}${Routes.CALLBACK}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}/default/v1/logout?id_token_hint`
    ];
    const stateOptions = Object.keys(stateOptionDict);
    const yesNoAutocompleteOptionValues = Object.keys(YesNoAutocompleteOptions);
    useEffect(() => {
        setKnowWhenExpectedWorking(formData?.disabilityKnowWhenExpectedWorking == 'yes');
    }, [formData?.disabilityKnowWhenExpectedWorking]);
    useEffect(() => {
        setOnBehalfOf(formData?.disabilityFiledOnBehalfOf === 'yes');
    }, [formData?.disabilityFiledOnBehalfOf]);
    const handleForm = (formEvent: React.FormEvent<HTMLInputElement>): void => {
        if (
            formEvent.currentTarget.id === 'borrowerMailingState' ||
            formEvent.currentTarget.id === 'disabilityHealthcareState' ||
            formEvent.currentTarget.id === 'employerState'
        ) {
            formEvent.currentTarget.value = formEvent.currentTarget.value.toUpperCase();
        }
        setFormData({
            ...formData,
            [formEvent.currentTarget.id]: formEvent.currentTarget.value
        });
        dispatchLastFieldChangedEvent(formEvent.currentTarget.id);
    };
    const handleEmailInput = (name: string, value: string): void => {
        if (name === 'borrowerEmail') {
            value.length > 0 && !validateEmail(value)
                ? setEmailInputError(errorMapIU['emailError'])
                : setEmailInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'employerEmail') {
            value.length > 0 && !validateEmail(value)
                ? setEmployerEmailInputError(errorMapIU['emailError'])
                : setEmployerEmailInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'submitterEmail') {
            value.length > 0 && !validateEmail(value)
                ? setSubmitterEmailInputError(errorMapIU['emailError'])
                : setSubmitterEmailInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handleSSNInput = (name: string, value: string): void => {
        value.length > 0 && !validateLast4SSN(value)
            ? setSsnInputError(errorMapIU['ssnError'])
            : setSsnInputError(null);
        setFormData({
            ...formData,
            [name]: value
        });
        dispatchLastFieldChangedEvent(name);
    };
    const handleDateInput = (name: string, value: string): void => {
        if (name === 'disabilityDateOfIncident') {
            value.length > 0 && !validateDate(value)
                ? setDateOfIncidentError(errorMapIU['dateError'])
                : setDateOfIncidentError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'disabilityDateLastWorked') {
            value.length > 0 && !validateDate(value)
                ? setDateOfLastWorkedError(errorMapIU['dateError'])
                : setDateOfLastWorkedError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'disabilityFirstPhysicianDate') {
            value.length > 0 && !validateDate(value)
                ? setDateOfPhysicianSeenError(errorMapIU['dateError'])
                : setDateOfPhysicianSeenError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'disabilityDateExpectedWorking') {
            value.length > 0 && !validateDate(value)
                ? setDateExpectedWorking(errorMapIU['dateError'])
                : setDateExpectedWorking(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'borrowerDateOfBirth') {
            value.length > 0 && !validateDate(value)
                ? setDateOfBirthInputError(errorMapIU['dateError'])
                : setDateOfBirthInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handleZIPInput = (name: string, value: string): void => {
        if (name === 'borrowerMailingZip') {
            value.length > 0 && !validateZip(value) ? setZipInputError(errorMapIU['zipError']) : setZipInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'employerZip') {
            value.length > 0 && !validateZip(value)
                ? setEmployerZipInputError(errorMapIU['zipError'])
                : setEmployerZipInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'disabilityHealthcareZip') {
            value.length > 0 && !validateZip(value)
                ? setHealthcareZipInputError(errorMapIU['zipError'])
                : setHealthcareZipInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handlePhoneNumberInput = (name: string, value: string): void => {
        if (name === 'borrowerPhone') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setPhoneNumberInputError(errorMapIU['phoneError'])
                : setPhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'employerContactNumber') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setEmployerPhoneNumberInputError(errorMapIU['phoneError'])
                : setEmployerPhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'disabilityHealthcareContactNumber') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setHealthcarePhoneNumberInputError(errorMapIU['phoneError'])
                : setHealthcarePhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'submitterPhone') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setSubmitterPhoneNumberInputError(errorMapIU['phoneError'])
                : setSubmitterPhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handleRadioInput = (
        name: string,
        radioValue: string | boolean,
        dependentPropertiesToReset: string[] = []
    ): void => {
        setFormData({
            ...formData,
            [name]: radioValue,
            ...Object.fromEntries(dependentPropertiesToReset.map((prop) => [prop, null]))
        });
        dispatchLastFieldChangedEvent(name);
    };
    const handleAutocompleteInput = (name: string, value: string, dependentPropertiesToReset: string[] = []): void => {
        setFormData({
            ...formData,
            [name]: value,
            ...Object.fromEntries(dependentPropertiesToReset.map((prop) => [prop, null]))
        });
        dispatchLastFieldChangedEvent(name);
    };
    // TODO: no any
    const handleSaveFormState = (event: React.FormEvent, formData: IFormDisabilityData | any): void => {
        saveFormDisabilityState(formData);
    };
    const dispatchLastFieldChangedEvent = (nameOfLastFieldChanged: string): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.LAST_CLAIM_FIELD_CHANGED, {
                detail: {
                    claimType: ClaimTypesEnum.Disability,
                    distributorName: clientContextDataState.payload.distributorName,
                    lastClaimFieldChanged: nameOfLastFieldChanged
                }
            })
        );
    };
    const dispatchClaimProgressEvent = (nameOfClaimStep: string): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, {
                detail: {
                    claimType: ClaimTypesEnum.Disability,
                    distributorName: clientContextDataState.payload.distributorName,
                    id: nameOfClaimStep
                }
            })
        );
    };
    const dispatchBorrowerNameChangedEvent = (isBorrowerFirstAndLastPopulated: boolean): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.BORROWER_NAME_CHANGED, {
                detail: {
                    claimType: ClaimTypesEnum.Disability,
                    distributorName: clientContextDataState.payload.distributorName,
                    isBorrowerFirstLastNamePopulated: isBorrowerFirstAndLastPopulated
                }
            })
        );
    };
    useEffect(() => {
        const comboBoxButtons = fieldRef.current?.getElementsByClassName('Combobox__menu-toggle');
        if (typeof comboBoxButtons !== 'undefined') {
            Array.from(comboBoxButtons).forEach((el) => el.setAttribute('type', 'button'));
        }
    }, [formDisabilityProgressState, formData]);
    useEffect(() => {
        if (fieldRef.current && formDisabilityProgressState[0].status !== TimelineState.ACTIVE) {
            fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setIsPersonalInfoActive(formDisabilityProgressState[0].status == TimelineState.ACTIVE);
        setIsDisabilityDetailsActive(formDisabilityProgressState[1].status == TimelineState.ACTIVE);
        setIsEmployerInfoActive(formDisabilityProgressState[2].status == TimelineState.ACTIVE);
        setIsHealthcareInfoActive(formDisabilityProgressState[3].status == TimelineState.ACTIVE);
        setIsCommentsActive(formDisabilityProgressState[4].status == TimelineState.ACTIVE);
    }, [formDisabilityProgressState]);
    useEffect(() => {
        if (isPersonalInfoActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_PERSONAL_INFO);
        }
    }, [isPersonalInfoActive]);
    useEffect(() => {
        if (isDisabilityDetailsActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_DISABILITY_DETAILS);
        }
    }, [isDisabilityDetailsActive]);
    useEffect(() => {
        if (isEmployerInfoActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_EMPLOYER_INFO);
        }
    }, [isEmployerInfoActive]);
    useEffect(() => {
        if (isHealthcareInfoActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_HEALTHCARE_INFO);
        }
    }, [isHealthcareInfoActive]);
    useEffect(() => {
        if (isCommentsActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_COMMENTS);
        }
    }, [isCommentsActive]);
    useEffect(() => {
        if (formData?.borrowerNameFirst && formData?.borrowerNameLast) {
            setIsBorrowerFirstAndLastPopulated(true);
        } else {
            setIsBorrowerFirstAndLastPopulated(false);
        }
    }, [formData?.borrowerNameFirst, formData?.borrowerNameLast]);
    useEffect(() => {
        dispatchBorrowerNameChangedEvent(isBorrowerFirstAndLastPopulated);
    }, [isBorrowerFirstAndLastPopulated]);
    useEffect(() => {
        const formStatusAllComplete = formDisabilityProgressState.some((obj) => obj.status === TimelineState.COMPLETE);
        if (formStatusAllComplete) {
            return;
        } else {
            updateFormDisabilityProgressState('id-1', TimelineState.ACTIVE);
        }
    }, []);
    useEffect(() => {
        setFormData(formDisabilityState);
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormDisability:::::::::::::::::::::::::::::::::',
    //     '\n::formData::',
    //     formData,
    //     '\n::formDisabilityProgressState::',
    //     formDisabilityProgressState,
    //     '\n::isFormDirty::',
    //     isFormDirty,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCContentWrapper>
            <SCContentDirectionWrapper
                flexDirection={FlexDirectionEnum.COLUMN}
                flexAlignment={FlexAlignItemsEnum.FLEX_START}
            >
                <SCContentSectionResponsiveWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexGap={'24px'}
                    sectionWidth={'100%'}
                >
                    <SCFormRefWrapper ref={fieldRef}>
                        <SCFormTopNavWrapper>
                            <TSBackNavigation isFormDirty={isFormDirty} />
                            <TSFormProgressStepper />
                        </SCFormTopNavWrapper>
                        <SCSubHead textColor={$deepBlue}>File a Claim: Disability</SCSubHead>
                        {formDisabilityProgressState[0].status === TimelineState.ACTIVE && (
                            <SCSectionWrapper>
                                {clientContextDataState?.payload?.client_content?.claim_options.find(
                                    (x) => x.value == ClaimTypesEnum.Jobloss
                                ) && (
                                    <SCChangeFormType
                                        onClick={(): void => {
                                            isFormDirty
                                                ? setConfirmModalIsVisible(true)
                                                : changeFormType && changeFormType();
                                            document.dispatchEvent(
                                                new CustomEvent(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, {
                                                    detail: {
                                                        claimType: ClaimTypesEnum.Jobloss,
                                                        distributorName: clientContextDataState.payload.distributorName,
                                                        id: TrackerEnum.CLAIMSTEP_START
                                                    }
                                                })
                                            );
                                        }}
                                    >
                                        Do you need to file a job loss claim instead? Click here.
                                    </SCChangeFormType>
                                )}
                                <SCBodyLarge>
                                    Please enter in the following information so we can better understand your
                                    situation.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                        className={SCForm}
                                    >
                                        <SCSectionSubHead>Personal Information</SCSectionSubHead>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSTextField
                                                    name={'fname'}
                                                    id={'borrowerNameFirst'}
                                                    ariaLabel={labelMapDisability['borrowerNameFirst']}
                                                    label="First Name"
                                                    value={formData?.borrowerNameFirst as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['borrowerNameFirst']}`}
                                                    maxLength={MaxFieldLengths.FIRST_NAME}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSTextField
                                                    name={'lname'}
                                                    id={'borrowerNameLast'}
                                                    ariaLabel={labelMapDisability['borrowerNameLast']}
                                                    label="Last Name"
                                                    value={formData?.borrowerNameLast as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['borrowerNameLast']}`}
                                                    maxLength={MaxFieldLengths.LAST_NAME}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSTextField
                                                    name={'email'}
                                                    ariaLabel={labelMapDisability['borrowerEmail']}
                                                    id={'borrowerEmail'}
                                                    label="Email Address"
                                                    type="email"
                                                    value={formData?.borrowerEmail as string}
                                                    onChange={(formEvent: React.FormEvent<HTMLInputElement>): void => {
                                                        handleEmailInput(
                                                            'borrowerEmail',
                                                            formEvent.currentTarget.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['borrowerEmail']}`}
                                                    maxLength={MaxFieldLengths.EMAIL}
                                                ></TSTextField>
                                                <SCErrorLabel>{emailInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name={'phone'}
                                                    id={'borrowerPhone'}
                                                    ariaLabel={labelMapDisability['borrowerPhone']}
                                                    label="Phone Number"
                                                    value={formData?.borrowerPhone as string}
                                                    onChange={(event): void => {
                                                        handlePhoneNumberInput('borrowerPhone', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['borrowerPhone']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{phoneNumberInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            {!isPhoneIdentifierType(
                                                clientContextDataState?.payload.borrowerIdentifierType as number
                                            ) && (
                                                <>
                                                    <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                        <TSTextField
                                                            name={'ssn'}
                                                            ariaLabel={labelMapDisability['borrowerSSN']}
                                                            id="borrowerSSN"
                                                            label={labelMapDisability['borrowerSSN']}
                                                            type={ssnInputType}
                                                            value={formData?.borrowerSSN as string}
                                                            onChange={(
                                                                formEvent: React.FormEvent<HTMLInputElement>
                                                            ): void => {
                                                                handleSSNInput(
                                                                    'borrowerSSN',
                                                                    formEvent.currentTarget.value
                                                                );
                                                            }}
                                                            onFocus={(event): void => {
                                                                setSsnInputType('text');
                                                            }}
                                                            onBlur={(event): void => {
                                                                setSsnInputType('password');
                                                            }}
                                                            placeholder={`Enter ${labelMapDisability['borrowerSSN']}`}
                                                            maxLength={MaxFieldLengths.SSN}
                                                        ></TSTextField>
                                                        <SCErrorLabel>{ssnInputError}</SCErrorLabel>
                                                    </SCElementWrapper>
                                                    <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                        We need this to find your policy
                                                    </SCElementWrapper>
                                                </>
                                            )}
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name={'dateOfBirth'}
                                                    fieldType="date"
                                                    id={'borrowerDateOfBirth'}
                                                    value={formData?.borrowerDateOfBirth as string}
                                                    ariaLabel={labelMapDisability['borrowerDateOfBirth']}
                                                    label={labelMapDisability['borrowerDateOfBirth']}
                                                    onChange={(event): void => {
                                                        handleDateInput('borrowerDateOfBirth', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['borrowerDateOfBirth']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfBirthInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                We need this to find your policy
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCSectionSubHead>Mailing Address</SCSectionSubHead>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    name={'address1'}
                                                    ariaLabel={labelMapDisability['borrowerMailingAddress']}
                                                    id={'borrowerMailingAddress'}
                                                    label="Mailing Address"
                                                    value={formData?.borrowerMailingAddress as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['borrowerMailingAddress']}`}
                                                    maxLength={MaxFieldLengths.ADDRESS}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSTextField
                                                    name={'city'}
                                                    id={'borrowerMailingCity'}
                                                    ariaLabel={labelMapDisability['borrowerMailingCity']}
                                                    label="City"
                                                    value={formData?.borrowerMailingCity as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['borrowerMailingCity']}`}
                                                    maxLength={MaxFieldLengths.CITY}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSAutocomplete
                                                    name={'state'}
                                                    id="borrowerMailingState"
                                                    options={stateOptions}
                                                    getOptionLabel={(stateAbbreviation): string =>
                                                        stateOptionDict[stateAbbreviation]
                                                    }
                                                    value={formData?.borrowerMailingState}
                                                    label="State"
                                                    onChange={(event, value, reason): void => {
                                                        handleAutocompleteInput('borrowerMailingState', value);
                                                    }}
                                                ></TSAutocomplete>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    ariaLabel={labelMapDisability['borrowerMailingZip']}
                                                    id={'borrowerMailingZip'}
                                                    fieldType="zipCode"
                                                    label="Zip"
                                                    value={formData?.borrowerMailingZip as string}
                                                    onChange={(event): void => {
                                                        handleZIPInput('borrowerMailingZip', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['borrowerMailingZip']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{zipInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCFormNavigationWrapper>
                                            {formDisabilityProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    isFormDirty={isFormDirty}
                                                    requiredFields={
                                                        !isPhoneIdentifierType(
                                                            clientContextDataState?.payload
                                                                .borrowerIdentifierType as number
                                                        )
                                                            ? keysBorrowDetail
                                                            : [
                                                                  'borrowerNameFirst',
                                                                  'borrowerNameLast',
                                                                  'borrowerEmail',
                                                                  'borrowerPhone',
                                                                  'borrowerMailingAddress',
                                                                  'borrowerMailingCity',
                                                                  'borrowerMailingState',
                                                                  'borrowerMailingZip',
                                                                  'borrowerDateOfBirth'
                                                              ]
                                                    }
                                                    validationFields={[
                                                        !emailInputError,
                                                        !ssnInputError,
                                                        !phoneNumberInputError,
                                                        !zipInputError,
                                                        !dateOfBirthInputError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                        {isDisabilityDetailsActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    We’ve got your personal details saved. Next, please enter in the following
                                    disability details so we can better understand your situation.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCSectionSubHead>Disability Details</SCSectionSubHead>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name={'date'}
                                                    fieldType="date"
                                                    id={'disabilityDateOfIncident'}
                                                    value={formData?.disabilityDateOfIncident as string}
                                                    ariaLabel={labelMapDisability['disabilityDateOfIncident']}
                                                    label={labelMapDisability['disabilityDateOfIncident']}
                                                    onChange={(event): void => {
                                                        handleDateInput('disabilityDateOfIncident', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityDateOfIncident']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfIncidentError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name={'date'}
                                                    fieldType="date"
                                                    id={'disabilityDateLastWorked'}
                                                    value={formData?.disabilityDateLastWorked as string}
                                                    ariaLabel={labelMapDisability['disabilityDateLastWorked']}
                                                    label={labelMapDisability['disabilityDateLastWorked']}
                                                    onChange={(event): void => {
                                                        handleDateInput('disabilityDateLastWorked', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityDateLastWorked']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfLastWorkedError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSRadioGroup
                                                    radioGroupValues={YesNoRadioOptions}
                                                    radioGroupLabel={
                                                        labelMapDisability['disabilityKnowWhenExpectedWorking']
                                                    }
                                                    value={formData?.disabilityKnowWhenExpectedWorking}
                                                    onChange={(event): void => {
                                                        handleRadioInput(
                                                            'disabilityKnowWhenExpectedWorking',
                                                            event.target.value,
                                                            ['disabilityDateExpectedWorking']
                                                        );
                                                    }}
                                                    id="disabilityKnowWhenExpectedWorking"
                                                ></TSRadioGroup>
                                            </SCElementWrapper>
                                            {knowWhenExpectedWorking && (
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSMaskedTextField
                                                        name={'date'}
                                                        fieldType="date"
                                                        id={'disabilityDateExpectedWorking'}
                                                        value={formData?.disabilityDateExpectedWorking as string}
                                                        ariaLabel={labelMapDisability['disabilityDateExpectedWorking']}
                                                        label={labelMapDisability['disabilityDateExpectedWorking']}
                                                        onChange={(event): void => {
                                                            handleDateInput(
                                                                'disabilityDateExpectedWorking',
                                                                event.target.value
                                                            );
                                                        }}
                                                        placeholder={`Enter ${labelMapDisability['disabilityDateExpectedWorking']}`}
                                                    ></TSMaskedTextField>
                                                    <SCErrorLabel>{dateExpectedWorking}</SCErrorLabel>
                                                </SCElementWrapper>
                                            )}
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    id={'disabilityExplanationOf'}
                                                    value={formData?.disabilityExplanationOf as string}
                                                    ariaLabel={labelMapDisability['disabilityExplanationOf']}
                                                    label={labelMapDisability['disabilityExplanationOf']}
                                                    onChange={(event): void => {
                                                        handleForm(event as any);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityExplanationOf']}`}
                                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                                    multiline={true}
                                                    minRows={5}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    id={'disabilityTypeOfSurgery'}
                                                    ariaLabel={labelMapDisability['disabilityTypeOfSurgery']}
                                                    label={labelMapDisability['disabilityTypeOfSurgery']}
                                                    value={formData?.disabilityTypeOfSurgery as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['disabilityTypeOfSurgery']}`}
                                                    maxLength={MaxFieldLengths.DISABILITY_TYPE_OF_SURGERY}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    fieldType="date"
                                                    id={'disabilityFirstPhysicianDate'}
                                                    value={formData?.disabilityFirstPhysicianDate as string}
                                                    ariaLabel={labelMapDisability['disabilityFirstPhysicianDate']}
                                                    label={labelMapDisability['disabilityFirstPhysicianDate']}
                                                    onChange={(event): void => {
                                                        handleDateInput(
                                                            'disabilityFirstPhysicianDate',
                                                            event.target.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityFirstPhysicianDate']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfPhysicianSeenError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    id={'disabilityJobDuties'}
                                                    value={formData?.disabilityJobDuties as string}
                                                    ariaLabel={labelMapDisability['disabilityJobDuties']}
                                                    label={labelMapDisability['disabilityJobDuties']}
                                                    onChange={(event): void => {
                                                        handleForm(event as any);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityJobDuties']}`}
                                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                                    multiline={true}
                                                    minRows={5}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCFormNavigationWrapper>
                                            {formDisabilityProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={[
                                                        'disabilityExplanationOf',
                                                        'disabilityKnowWhenExpectedWorking',
                                                        'disabilityDateLastWorked',
                                                        'disabilityDateOfIncident',
                                                        'disabilityFirstPhysicianDate'
                                                    ]}
                                                    validationFields={[
                                                        !dateOfIncidentError,
                                                        !dateOfLastWorkedError,
                                                        !dateOfPhysicianSeenError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                        {isEmployerInfoActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    Great, we’ve got your claim details recorded. Next, please provide us with your
                                    employer’s contact information (for example, the corporate office, a manager, or the
                                    HR department.) If we need to contact them during the claim review, we will notify
                                    you to obtain your consent to do so.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCElementWrapper>
                                            <SCSectionSubHead>Employer Details</SCSectionSubHead>
                                            <SCBodyLarge>
                                                We may need to obtain information from your employer to confirm your
                                                employment information.
                                            </SCBodyLarge>
                                        </SCElementWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'employerName'}
                                                    ariaLabel={labelMapDisability['employerName']}
                                                    label={labelMapDisability['employerName']}
                                                    value={formData?.employerName as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['employerName']}`}
                                                    maxLength={MaxFieldLengths.COMPANY_NAME}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'employerEmail'}
                                                    type="email"
                                                    ariaLabel={labelMapDisability['employerEmail']}
                                                    label={labelMapDisability['employerEmail']}
                                                    value={formData?.employerEmail as string}
                                                    onChange={(formEvent: React.FormEvent<HTMLInputElement>): void => {
                                                        handleEmailInput(
                                                            'employerEmail',
                                                            formEvent.currentTarget.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['employerEmail']}`}
                                                    maxLength={MaxFieldLengths.EMAIL}
                                                ></TSTextField>
                                                <SCErrorLabel>{employerEmailInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSMaskedTextField
                                                    fieldType="phone"
                                                    id={'employerContactNumber'}
                                                    value={formData?.employerContactNumber as string}
                                                    ariaLabel={labelMapDisability['employerContactNumber']}
                                                    label={labelMapDisability['employerContactNumber']}
                                                    onChange={(event): void => {
                                                        handlePhoneNumberInput(
                                                            'employerContactNumber',
                                                            event.target.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['employerContactNumber']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{employerPhoneNumberInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    id={'employerAddress'}
                                                    ariaLabel={labelMapDisability['employerAddress']}
                                                    label={labelMapDisability['employerAddress']}
                                                    value={formData?.employerAddress as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['employerAddress']}`}
                                                    maxLength={MaxFieldLengths.ADDRESS}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'employerCity'}
                                                    ariaLabel={labelMapDisability['employerCity']}
                                                    label={labelMapDisability['employerCity']}
                                                    value={formData?.employerCity as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['employerCity']}`}
                                                    maxLength={MaxFieldLengths.CITY}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSAutocomplete
                                                    id="employerState"
                                                    options={stateOptions}
                                                    getOptionLabel={(stateAbbreviation): string =>
                                                        stateOptionDict[stateAbbreviation]
                                                    }
                                                    value={formData?.employerState}
                                                    label={labelMapDisability['employerState']}
                                                    onChange={(event, value, reason): void => {
                                                        handleAutocompleteInput('employerState', value);
                                                    }}
                                                ></TSAutocomplete>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSMaskedTextField
                                                    ariaLabel={labelMapDisability['employerZip']}
                                                    id={'employerZip'}
                                                    fieldType="zipCode"
                                                    label={labelMapDisability['employerZip']}
                                                    value={formData?.employerZip as string}
                                                    onChange={(event): void => {
                                                        handleZIPInput('employerZip', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['employerZip']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{employerZipInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCFormNavigationWrapper>
                                            {formDisabilityProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={[
                                                        'employerName',
                                                        'employerContactNumber',
                                                        'employerAddress',
                                                        'employerCity',
                                                        'employerState',
                                                        'employerZip'
                                                    ]}
                                                    validationFields={[
                                                        !employerPhoneNumberInputError,
                                                        !employerZipInputError,
                                                        !employerEmailInputError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                        {isHealthcareInfoActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    Okay, we’ve got your employment information recorded. Next, please provide us with
                                    your healthcare provider details.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCElementWrapper>
                                            <SCSectionSubHead>Healthcare Information</SCSectionSubHead>
                                            <SCBodyLarge>
                                                We may need to obtain information from your Healthcare Provider’s office
                                                to confirm information regarding your disability.
                                            </SCBodyLarge>
                                        </SCElementWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'disabilityHealthcareName'}
                                                    ariaLabel={labelMapDisability['disabilityHealthcareName']}
                                                    label={labelMapDisability['disabilityHealthcareName']}
                                                    value={formData?.disabilityHealthcareName as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['disabilityHealthcareName']}`}
                                                    maxLength={MaxFieldLengths.DISABILITY_HEALTHCARE_NAME}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'disabilityHealthcareHospitalName'}
                                                    ariaLabel={labelMapDisability['disabilityHealthcareHospitalName']}
                                                    label={labelMapDisability['disabilityHealthcareHospitalName']}
                                                    value={formData?.disabilityHealthcareHospitalName as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['disabilityHealthcareHospitalName']}`}
                                                    maxLength={MaxFieldLengths.DISABILITY_HEALTHCARE_HOSPITAL_NAME}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSMaskedTextField
                                                    name={'phone'}
                                                    ariaLabel={labelMapDisability['disabilityHealthcareContactNumber']}
                                                    id={'disabilityHealthcareContactNumber'}
                                                    fieldType="phone"
                                                    label={labelMapDisability['disabilityHealthcareContactNumber']}
                                                    value={formData?.disabilityHealthcareContactNumber as string}
                                                    onChange={(event): void => {
                                                        handlePhoneNumberInput(
                                                            'disabilityHealthcareContactNumber',
                                                            event.target.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityHealthcareContactNumber']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{healthcarePhoneNumberInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    name={'address1'}
                                                    id={'disabilityHealthcareAddress'}
                                                    ariaLabel={labelMapDisability['disabilityHealthcareAddress']}
                                                    label={labelMapDisability['disabilityHealthcareAddress']}
                                                    value={formData?.disabilityHealthcareAddress as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['disabilityHealthcareAddress']}`}
                                                    maxLength={MaxFieldLengths.ADDRESS}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    name={'city'}
                                                    id={'disabilityHealthcareCity'}
                                                    ariaLabel={labelMapDisability['disabilityHealthcareCity']}
                                                    label={labelMapDisability['disabilityHealthcareCity']}
                                                    value={formData?.disabilityHealthcareCity as string}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapDisability['disabilityHealthcareCity']}`}
                                                    maxLength={MaxFieldLengths.CITY}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSAutocomplete
                                                    id="disabilityHealthcareState"
                                                    options={stateOptions}
                                                    label={labelMapDisability['disabilityHealthcareState']}
                                                    getOptionLabel={(stateAbbreviation): string =>
                                                        stateOptionDict[stateAbbreviation]
                                                    }
                                                    value={formData?.disabilityHealthcareState}
                                                    onChange={(event, value, reason): void => {
                                                        handleAutocompleteInput('disabilityHealthcareState', value);
                                                    }}
                                                ></TSAutocomplete>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSMaskedTextField
                                                    name={'zip'}
                                                    ariaLabel={labelMapDisability['disabilityHealthcareZip']}
                                                    id={'disabilityHealthcareZip'}
                                                    fieldType="zipCode"
                                                    label={labelMapDisability['disabilityHealthcareZip']}
                                                    value={formData?.disabilityHealthcareZip as string}
                                                    onChange={(event): void => {
                                                        handleZIPInput('disabilityHealthcareZip', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['disabilityHealthcareZip']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{healthcareZipInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCFormNavigationWrapper>
                                            {formDisabilityProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={keysDisabilityDetailsHealthcare}
                                                    validationFields={[
                                                        !healthcarePhoneNumberInputError,
                                                        !healthcareZipInputError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                        {isCommentsActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    Almost done! We’ve got your healthcare details all recorded. If you have any
                                    additional comments or important notes, you can add them here before submitting your
                                    claim.{' '}
                                </SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCElementsWrapper>
                                            <SCSectionSubHead>Comments (optional)</SCSectionSubHead>
                                        </SCElementsWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    ariaLabel={labelMapDisability['borrowerComments']}
                                                    label={labelMapDisability['borrowerComments']}
                                                    id={'borrowerComments'}
                                                    value={formData?.borrowerComments as string}
                                                    onChange={(event: unknown): void => {
                                                        handleForm(event as React.FormEvent<HTMLInputElement>);
                                                    }}
                                                    placeholder={`Enter ${labelMapDisability['borrowerComments']}`}
                                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                                    multiline={true}
                                                    minRows={5}
                                                />
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSAutocomplete
                                                    id="disabilityFiledOnBehalfOf"
                                                    options={yesNoAutocompleteOptionValues}
                                                    getOptionLabel={(yesNoValue): string =>
                                                        YesNoAutocompleteOptions[yesNoValue]
                                                    }
                                                    value={formData?.disabilityFiledOnBehalfOf}
                                                    label={labelMapDisability['disabilityFiledOnBehalfOf']}
                                                    onChange={(event, value, reason): void => {
                                                        handleAutocompleteInput('disabilityFiledOnBehalfOf', value, [
                                                            'submitterCompanyName',
                                                            'submitterNameFirst',
                                                            'submitterNameLast',
                                                            'submitterEmail',
                                                            'submitterPhone'
                                                        ]);
                                                    }}
                                                ></TSAutocomplete>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        {formData && onBehalfOf && (
                                            <SCElementsWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                    <TSTextField
                                                        id={'submitterCompanyName'}
                                                        ariaLabel={labelMapDisability['submitterCompanyName']}
                                                        label={`${labelMapDisability['submitterCompanyName']}`}
                                                        value={formData?.submitterCompanyName as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapDisability['submitterCompanyName']}`}
                                                        maxLength={MaxFieldLengths.COMPANY_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'submitterNameFirst'}
                                                        ariaLabel={labelMapDisability['submitterNameFirst']}
                                                        label={`${labelMapDisability['submitterNameFirst']}`}
                                                        value={formData?.submitterNameFirst as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapDisability['submitterNameFirst']}`}
                                                        maxLength={MaxFieldLengths.FIRST_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'submitterNameLast'}
                                                        ariaLabel={labelMapDisability['submitterNameLast']}
                                                        label={`${labelMapDisability['submitterNameLast']}`}
                                                        value={formData?.submitterNameLast as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapDisability['submitterNameLast']}`}
                                                        maxLength={MaxFieldLengths.LAST_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        ariaLabel={labelMapDisability['submitterEmail']}
                                                        id={'submitterEmail'}
                                                        label={labelMapDisability['submitterEmail']}
                                                        type="email"
                                                        value={formData?.submitterEmail as string}
                                                        onChange={(
                                                            formEvent: React.FormEvent<HTMLInputElement>
                                                        ): void => {
                                                            handleEmailInput(
                                                                'submitterEmail',
                                                                formEvent.currentTarget.value
                                                            );
                                                        }}
                                                        placeholder={`Enter ${labelMapDisability['submitterEmail']}`}
                                                        maxLength={MaxFieldLengths.EMAIL}
                                                    ></TSTextField>
                                                    <SCErrorLabel>{submitterEmailInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSMaskedTextField
                                                        id={'submitterPhone'}
                                                        ariaLabel={labelMapDisability['submitterPhone']}
                                                        label={labelMapDisability['submitterPhone']}
                                                        value={formData?.submitterPhone as string}
                                                        onChange={(event): void => {
                                                            handlePhoneNumberInput(
                                                                'submitterPhone',
                                                                event.target.value
                                                            );
                                                        }}
                                                        placeholder={`Enter ${labelMapDisability['submitterPhone']}`}
                                                    ></TSMaskedTextField>
                                                    <SCErrorLabel>{submitterPhoneNumberInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                            </SCElementsWrapper>
                                        )}
                                        <SCFormNavigationWrapper>
                                            {formDisabilityProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={
                                                        formData?.disabilityFiledOnBehalfOf === 'yes'
                                                            ? [
                                                                  'submitterCompanyName',
                                                                  'submitterNameFirst',
                                                                  'submitterNameLast',
                                                                  'submitterEmail',
                                                                  'submitterPhone'
                                                              ]
                                                            : []
                                                    }
                                                    validationFields={[
                                                        !submitterEmailInputError,
                                                        !submitterPhoneNumberInputError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                    </SCFormRefWrapper>
                    <BeforeUnload
                        isLoggedIn={authenticationData.isAuthenticated ? authenticationData.isAuthenticated : false}
                        cancelHeaderButtonText={'Close'}
                        modalBody={
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                                <SCSectionBody>
                                    You have unsaved claim information, continuing may cause your information to be lost
                                </SCSectionBody>
                            </SCContentSectionWrapper>
                        }
                        nativeDialogue={true}
                        customDialogue={true}
                        modalEnabled={isFormDirty}
                        continueButtonText={'Proceed without saving'}
                        cancelButtonText={'Return to page'}
                        onContinue={(): void => {
                            setIsFormDirty(false);
                            saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
                        }}
                        allowedRoutes={allowedRoutes}
                        onCancel={(): void => {}}
                    />
                    <TSDialog
                        ariaLabel={'Unsaved Changes dialog'}
                        hideSecondaryCloseButton
                        handleClose={(): void => {
                            setConfirmModalIsVisible(false);
                        }}
                        id={'unsavedChangesDialog'}
                        open={confirmModalIsVisible}
                        dialogSize={'sm'}
                        bannerHeaderCloseButtonText={'Close'}
                        content={
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                                <SCSectionBody>
                                    You have unsaved claim information, continuing may cause your information to be lost
                                </SCSectionBody>
                            </SCContentSectionWrapper>
                        }
                        actionChildren={
                            <SCContentSectionResponsiveWrapper
                                flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                                flexAlignment={FlexAlignItemsEnum.CENTER}
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexGap={'16px'}
                            >
                                <TSButton
                                    ariaLabel="dialog return to page"
                                    tsVariant={TSButtonVariantsEnum.TEXT}
                                    size={TSButtonSizeEnum.MEDIUM}
                                    onClick={(): void => {
                                        setConfirmModalIsVisible(false);
                                    }}
                                >
                                    Return to page
                                </TSButton>
                                <TSButton
                                    ariaLabel="dialog proceed without saving"
                                    tsVariant={TSButtonVariantsEnum.ARROW}
                                    size={TSButtonSizeEnum.MEDIUM}
                                    onClick={(): void => {
                                        changeFormType && changeFormType();
                                        setConfirmModalIsVisible(false);
                                    }}
                                >
                                    Proceed without saving
                                </TSButton>
                            </SCContentSectionResponsiveWrapper>
                        }
                    ></TSDialog>
                </SCContentSectionResponsiveWrapper>
            </SCContentDirectionWrapper>
        </SCContentWrapper>
    );
};

export { FormDisability };
