import React, { SyntheticEvent, useState } from 'react';
import { SCButtonWrapper, SCRootWrapper } from './styles';
import { ITSFormProgressStepperProps } from './types';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { IFormDisabilityProgressState, IFormJoblossProgressState } from 'types/form-progress';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { FormTypeContext } from 'context/formTypeContext';
import { IFormTypeState } from 'types/claim-types';
import { ClaimTypesEnum, TimelineState } from 'enums';
import { ClaimStateEnum } from 'enums/ClaimState';

const TSFormProgressStepper = ({ children }: ITSFormProgressStepperProps): JSX.Element => {
    const { updateFormJoblossProgressState, formJoblossProgressState, updateFormJoblossProgressCompletionStatus } =
        React.useContext(FormJoblossProgressStateContext) as IFormJoblossProgressState;
    const { updateFormDisabilityProgressState, formDisabilityProgressState } = React.useContext(
        FormDisabilityProgressStateContext
    ) as IFormDisabilityProgressState;
    const { formType, saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const currentFormState =
        formType.type === ClaimTypesEnum.Jobloss ? formJoblossProgressState : formDisabilityProgressState;
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSFormProgressStepper:::::::::::::::::::::::::::::::::',
    //     '\n::formType.type::',
    //     formType.type,
    //     '\n::currentFormState::',
    //     currentFormState,
    //     '\n::formJoblossProgressState::',
    //     formJoblossProgressState,
    //     '\n::formDisabilityProgressState::',
    //     formDisabilityProgressState,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCRootWrapper>
            {currentFormState.map((element: { status: string; label: string }, index) => {
                return (
                    <SCButtonWrapper key={`keyValue${index}`}>
                        <TSButton
                            tsVariant={TSButtonVariantsEnum.STEPPER}
                            ariaLabel={'button for both indicating step state and step navigation'}
                            disabled={element.status === TimelineState.INCOMPLETE}
                            onClick={(event: SyntheticEvent<Element, Event>): void => {
                                if (
                                    formType.type === ClaimTypesEnum.Jobloss &&
                                    element.status !== TimelineState.INCOMPLETE
                                ) {
                                    saveFormType({ type: ClaimTypesEnum.Jobloss, reviewState: ClaimStateEnum.ACTIVE });
                                    formJoblossProgressState.map(
                                        (state) =>
                                            state.status === TimelineState.ACTIVE &&
                                            (state.status = TimelineState.COMPLETE)
                                    );
                                    updateFormJoblossProgressState(`id-${index}`, TimelineState.COMPLETE);
                                    updateFormJoblossProgressState(`id-${index + 1}`, TimelineState.ACTIVE);
                                }
                                if (
                                    formType.type === ClaimTypesEnum.Disability &&
                                    element.status !== TimelineState.INCOMPLETE
                                ) {
                                    saveFormType({
                                        type: ClaimTypesEnum.Disability,
                                        reviewState: ClaimStateEnum.ACTIVE
                                    });
                                    formDisabilityProgressState.map(
                                        (state) =>
                                            state.status === TimelineState.ACTIVE &&
                                            (state.status = TimelineState.COMPLETE)
                                    );
                                    updateFormDisabilityProgressState(`id-${index}`, TimelineState.COMPLETE);
                                    updateFormDisabilityProgressState(`id-${index + 1}`, TimelineState.ACTIVE);
                                }
                            }}
                            stepValue={index + 1}
                            status={element.status}
                        ></TSButton>
                        {children}
                    </SCButtonWrapper>
                );
            })}
        </SCRootWrapper>
    );
};

export { TSFormProgressStepper };
