import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { $digitalBlack } from 'styles/global-styles';

interface IDialogWrapper {
    $fullscreen?: string;
    $maxWidth?: string;
}
export const SCDialogWrapper = styled(Dialog)<IDialogWrapper>`
    .MuiPaper-root {
        max-height: ${(props): string => (props.$fullscreen ? `97vh` : `none`)};
        width: 100%;
        max-width: ${(props): string => {
            return props.$fullscreen === 'true'
                ? `96vw`
                : props.$maxWidth === 'xs'
                ? `320px`
                : props.$maxWidth === 'sm'
                ? `460px`
                : props.$maxWidth === 'md'
                ? `560px`
                : props.$maxWidth === 'lg'
                ? `890px`
                : props.$maxWidth === 'xl'
                ? `1060px`
                : `560px`;
        }};
    }
`;
export const SCDialogActionsWrapper = styled(DialogActions)<IDialogWrapper>`
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-content: center;
    gap: 24px;
    width: 100%;
    height: auto;
    min-height: 80px;
    padding: 16px !important;
`;
export const SCDialogContentWrapper = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    min-height: 200px;
`;
export const SCDialogHeader = styled.div`
    padding: 24px 10px 20px 16px;
    display: block;
`;
export const SCDialogHeadline = styled.span`
    font-weight: 700;
    font-style: normal;
    color: ${$digitalBlack};
    font-size: 24px;
    line-height: 26px;
    @media (max-width: 1024px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 18.35px;
    }
`;
export const SCDialogHeaderLabel = styled.span`
    float: left;
`;
export const SCDialogCloseButton = styled.span`
    float: right;
`;
export const SCDialogActionButton = styled.div`
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    button {
        width: fit-content;
        min-width: 250px;
    }
`;
