import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
    SCButtonWrapper,
    SCHeaderContent,
    SCHeaderContentWrapper,
    SCHeaderWrapper,
    SCNavElement,
    SCStackContent
} from './styles';
import { IGlobalHeaderProps } from './types';
import { GlobalThemeContext } from 'context/globalThemeContext';
import { Theme, ThemeContextType } from 'types/theme';
import { TruStageLogoU } from 'components/atoms/TruStageLogoU/TruStageLogoU';
import { Routes } from 'routes';
import { useOktaAuth } from '@okta/okta-react';
import { TSTopNavigation } from 'components/molecules/TSTopNavigation';
import { TSButton } from 'components/atoms/TSButton';
import { Button } from '@mui/material';
import { TSButtonSizeEnum, TSButtonVariantsEnum, TSButtonWeightEnum } from 'enums/TSButtonVariants';

const TSGlobalHeader = ({ children }: IGlobalHeaderProps): ReactElement => {
    const { oktaAuth, authState } = useOktaAuth();
    const login = async (): Promise<void> => oktaAuth.signInWithRedirect();
    const logout = async (): Promise<void> => oktaAuth.signOut();
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::GlobalThemeWrapper:::::::::::::::::::::::::::::::::',
    //     '\n::theme::',
    //     theme,
    //     '\n::process.env::',
    //     process.env,
    //     '\n::process.env.REACT_APP_USER_ENV::',
    //     process.env.REACT_APP_USER_ENV,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCHeaderWrapper>
            <SCHeaderContentWrapper>
                <SCHeaderContent>
                    <Link to={Routes.HOME}>
                        <TruStageLogoU />
                    </Link>
                    {(authState?.isAuthenticated && (
                        <TSButton
                            ariaLabel="Log Out"
                            tsVariant={TSButtonVariantsEnum.SLIDE}
                            size={TSButtonSizeEnum.SMALL}
                            weight={TSButtonWeightEnum.BOLD}
                            onClick={logout}
                        >
                            Log Out
                        </TSButton>
                    )) || <SCButtonWrapper />}
                </SCHeaderContent>
                <SCHeaderContent>
                    <TSTopNavigation isAuthenticated={authState?.isAuthenticated} />
                </SCHeaderContent>
            </SCHeaderContentWrapper>
        </SCHeaderWrapper>
    );
};

export { TSGlobalHeader };
