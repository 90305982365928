import * as React from 'react';
import { IClaimFormResponseTypeState, IClaimFormResponseType } from './ClaimFormResponseTypes';
import { IChildrenProps } from 'types/children';

export const ClaimFormResponseContext = React.createContext<IClaimFormResponseTypeState | null>(null);
const ClaimFormResponseProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [stateClaimFormResponse, setStateClaimFormResponse] = React.useState<IClaimFormResponseType>({});
    const stateObject: IClaimFormResponseTypeState = {
        contextStateClaimFormResponse: stateClaimFormResponse,
        updateContextStateClaimFormResponse: setStateClaimFormResponse
    };
    return <ClaimFormResponseContext.Provider value={stateObject}>{children}</ClaimFormResponseContext.Provider>;
};

export default ClaimFormResponseProvider;
