import { HeadCellClaimLookup } from 'components/pages/ClaimLookup';
import { editFormLabels } from 'support/formLabels';

export const headCells: readonly HeadCellClaimLookup[] = [
    {
        id: 'loanNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.loanNumber
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: editFormLabels.status
    },
    {
        id: 'dateOfBenefitDecision',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.benefitDecisionDate
    },
    {
        id: 'amount',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.amount
    }
];
