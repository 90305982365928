import React, { ReactElement } from 'react';
import { IEditIconProps } from './types';
import { SCSvgRoot, SCSvgWrapper } from './styles';

const EditIcon = ({ children }: IEditIconProps): ReactElement => {
    return (
        <SCSvgWrapper>
            <SCSvgRoot
                width="100%"
                height="100%"
                viewBox="0 0 192 193"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
            >
                <g transform="matrix(0.692877,-0.691869,0.706592,0.707621,-40.2254,96.4353)">
                    <g transform="matrix(1.03634,-5.55112e-17,-1.11022e-16,1,1.41194,-7.10543e-15)">
                        <path d="M-38.849,96L10,72L152,72L152,80.002L14,80L-17,96L-17,96.003L14,112.003L152,112.001L152,120.003L10,120.003L-38.849,96.003L-38.855,96.003L-38.852,96.002L-38.855,96L-38.849,96Z" />
                    </g>
                    <g>
                        <g transform="matrix(1.00631,-1.13586e-16,-1.11022e-16,1,5.45331,2.84553e-14)">
                            <rect x="13.113" y="92.21" width="138.82" height="8.196" />
                        </g>
                        <g transform="matrix(0.871391,0.521528,-0.532626,0.853234,63.0681,-62.4421)">
                            <path d="M19.82,167.679L24.947,176.131L14.693,176.131L19.82,167.679Z" />
                        </g>
                        <g transform="matrix(1,-5.55112e-17,0,1.01787,0,-1.29243)">
                            <rect x="168.435" y="72.317" width="44.268" height="46.34" />
                        </g>
                    </g>
                </g>
            </SCSvgRoot>
        </SCSvgWrapper>
    );
};

export { EditIcon };
