import React, { ReactElement } from 'react';
import { ITableHeadEnhancedProps } from './types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box } from '@mui/material';
import { Data } from 'components/pages/ClaimsManagement';
import { visuallyHidden } from '@mui/utils';
import { SCTableHeadCellWrapper } from './styles';
import ArrowSortIcon from 'components/atoms/ArrowSortIcon/ArrowSortIcon';
import { headCells } from 'support/formLabels';

const TableHeadEnhanced = ({ order, orderBy, onRequestSort }: ITableHeadEnhancedProps): ReactElement => {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TableHeadEnhanced:::::::::::::::::::::::::::::::::',
    //     '\n::onRequestSort::',
    //     onRequestSort,
    //     '\n::headCells::',
    //     headCells,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <TableHead>
            <TableRow>
                <SCTableHeadCellWrapper />
                {headCells.map((headCell) => (
                    <SCTableHeadCellWrapper key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowSortIcon}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </SCTableHeadCellWrapper>
                ))}
            </TableRow>
        </TableHead>
    );
};

export { TableHeadEnhanced };
