import * as React from 'react';
import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
    name: 'MopeimIcon',
    shouldForwardProp: (prop) => prop !== 'fill'
})<SvgIconProps>(() => ({
    fill: 'none',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2.25px'
}));

SvgIcon.defaultProps = {
    viewBox: '0 0 9 13',
    width: '100%',
    height: '100%',
    focusable: 'false',
    'aria-hidden': 'true'
};

const ArrowSortIcon: React.FunctionComponent<SvgIconProps> = (props) => {
    return (
        <>
            <div>
                <SvgIcon {...props}>
                    <path d="M4.5 13 9 8 0 8 4.5 13Z" stroke="white" strokeWidth={0} fill="white" />
                    <path d="M4.5 0 0 5 9 5 4.5 0Z" stroke="black" strokeWidth={0} fill="black" opacity="60%" />
                </SvgIcon>
            </div>
        </>
    );
};

export default ArrowSortIcon;
