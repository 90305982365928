/** Mapping to use for Radio buttons that feature Yes & No. Label is the button's visual value, and "value" is the internal value that gets set */
export const YesNoRadioOptions = [
    {
        label: 'Yes',
        value: 'yes'
    },
    {
        label: 'No',
        value: 'no'
    }
];
/** Mapping to use for Autocomplete dropdowns that feature Yes & No. Key is the dropdown option's internal value, and the key's value is the label that appears for the user */
export const YesNoAutocompleteOptions = {
    yes: 'Yes',
    no: 'No'
};
export const YesNoBooleanRadioOptions = [
    {
        label: 'Yes',
        value: true
    },
    {
        label: 'No',
        value: false
    }
];
