import { FormControl, Input, InputAdornment, InputBaseComponentProps, InputLabel } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';
import { SCHelperText, SCTextFieldWrapper } from '../TSTextField/styles';

import { ITSMaskedTextFieldProps } from './types';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

// eslint-disable-next-line react/display-name
const PhoneMaskComponent = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onAccept={(value: any): void => onChange({ target: { name: props.name, value } })}
            onChange={(): void => {}}
            overwrite
        />
    );
});

// eslint-disable-next-line react/display-name
const ZipMaskComponent = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="00000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onAccept={(value: any): void => onChange({ target: { name: props.name, value } })}
            onChange={(): void => {}}
            overwrite
        />
    );
});

// eslint-disable-next-line react/display-name
const DateMaskComponent = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="00/00/0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onAccept={(value: any): void => onChange({ target: { name: props.name, value } })}
            onChange={(): void => {}}
            overwrite
        />
    );
});

const TSMaskedTextField = ({
    children,
    id,
    name,
    fieldType,
    onChange,
    value,
    defaultValue,
    isDisabled,
    variant,
    placeholder,
    label,
    iconType,
    iconLocation,
    isRequired,
    type,
    size,
    helperText,
    maxLength,
    minLength,
    max,
    min,
    readOnly,
    ariaLabel
}: ITSMaskedTextFieldProps): JSX.Element => {
    let maskComponent: any;
    switch (fieldType) {
        case 'phone': {
            maskComponent = PhoneMaskComponent;
            break;
        }
        case 'zipCode': {
            maskComponent = ZipMaskComponent;
            break;
        }
        case 'date': {
            maskComponent = DateMaskComponent;
            break;
        }
        default: {
            maskComponent = PhoneMaskComponent;
        }
    }
    return (
        <SCTextFieldWrapper>
            <FormControl variant={variant ? variant : 'standard'}>
                {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
                <Input
                    id={id}
                    name={name}
                    value={value || ''}
                    size={size ? size : 'medium'}
                    onChange={onChange}
                    placeholder={placeholder ? placeholder : ''}
                    disabled={isDisabled}
                    defaultValue={defaultValue}
                    required={isRequired}
                    type={type ? type : 'text'}
                    inputComponent={maskComponent as any}
                    readOnly={readOnly}
                    disableUnderline={readOnly}
                    inputProps={{
                        maxLength: maxLength,
                        minLength: minLength,
                        min: min,
                        max: max,
                        'aria-label': ariaLabel
                    }}
                    startAdornment={
                        iconLocation &&
                        iconLocation == 'start' &&
                        iconType && <InputAdornment position="start">{iconType}</InputAdornment>
                    }
                    endAdornment={
                        iconLocation &&
                        iconLocation == 'end' &&
                        iconType && <InputAdornment position="start">{iconType}</InputAdornment>
                    }
                />
            </FormControl>
            {helperText && <SCHelperText>{helperText}</SCHelperText>}
        </SCTextFieldWrapper>
    );
};

export { TSMaskedTextField };
