import styled from 'styled-components';

export const SCSearchClaimButton = styled.div`
    display: flex;
    flex-wrap: nowrap;
    padding-top: 20px;
    justify-content: flex-end;
    width: 100%;
    button {
        font-weight: 700;
        text-transform: none;
        padding: 0;
    }
`;
export const SCErrorLabel = styled.div`
    width: 100%;
    font-size: 12px;
    color: rgba(190, 19, 19, 1);
    text-transform: capitalize;
    letter-spacing: 0.06rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
`;
export const SCInfoLabel = styled.div`
    width: 100%;
    font-size: 12px;
    color: rgba(69, 69, 71, 1);
    text-transform: capitalize;
    letter-spacing: 0.06rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
`;
export const SCFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 5px;
    @media (max-width: 768px) {
        padding: 0px;
    }
`;
export const SCInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 0;
    gap: 20px;
    .item1 {
        width: 70%;
    }
    width: 100%;
    div {
        margin: 0;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const SCCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const SCElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    width: 100%;
`;
