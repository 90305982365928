import * as React from 'react';
import { useState } from 'react';
import { IChildrenProps } from 'types/children';
import { TimelineState } from 'enums/TimeLine';
import { IFormDisabilityProgressState, IFormProgressData } from 'types/form-progress';

export const FormDisabilityProgressStateContext = React.createContext<IFormDisabilityProgressState | null>(null);
const FormDisabilityProgressStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = [
        {
            id: 'id-1',
            label: 'Personal Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-2',
            label: 'Disability Details',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-3',
            label: 'Employer Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-4',
            label: 'Healthcare Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-5',
            label: 'Comments (optional)',
            status: TimelineState.INCOMPLETE
        }
    ];
    const [formDisabilityProgressState, setFormDisabilityProgressState] =
        React.useState<IFormProgressData[]>(initialState);
    const updateFormDisabilityProgressState = (id: string, status: TimelineState): void => {
        formDisabilityProgressState.filter((formData) => {
            if (formData.id === id) {
                formData.status = status;
                setFormDisabilityProgressState([...formDisabilityProgressState]);
            }
        });
    };
    const resetFormDisabilityProgressState = (): void => {
        setFormDisabilityProgressState(initialState);
    };
    const [formDisabilityProgressCompletionStatus, setFormProgressCompletionStatus] = useState(false);
    const updateFormDisabilityProgressCompletionStatus = (status: boolean): void => {
        setFormProgressCompletionStatus(status);
    };
    const updateAllFormDisabilityProgressStatusComplete = (): void => {
        formDisabilityProgressState.map((obj) => {
            obj.status = TimelineState.COMPLETE;
            setFormDisabilityProgressState([...formDisabilityProgressState]);
        });
    };

    return (
        <FormDisabilityProgressStateContext.Provider
            value={{
                formDisabilityProgressState,
                updateFormDisabilityProgressState,
                resetFormDisabilityProgressState,
                formDisabilityProgressCompletionStatus,
                updateFormDisabilityProgressCompletionStatus,
                updateAllFormDisabilityProgressStatusComplete
            }}
        >
            {children}
        </FormDisabilityProgressStateContext.Provider>
    );
};

export default FormDisabilityProgressStateProvider;
