import styled from 'styled-components';

export const SCDiv = styled.div``;
export const SCHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 24px;
    width: 100%;
    min-height: 52px;
    cursor: pointer;
    @media (max-width: 1440px) {
        flex-direction: column;
        align-items: start;
    }
`;
export const SCKeyValueSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 92%;
    @media (max-width: 1440px) {
        flex-wrap: wrap;
        width: 100%;
    }
`;
export const SCKeyValueListWrapper = styled.div`
    width: 50%;
    font-size: 14px;
    letter-spacing: 0.4px;
    padding: 36px 0px 0px 0px;
    @media (max-width: 1440px) {
        width: 100%;
        flex-wrap: wrap;
    }
`;
