import styled from 'styled-components';

export const SCRadioButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: none;
    order: 0;
    flex-wrap: nowrap;
    label {
        color: #231f20;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.56px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
    }
`;

export const SCRadioButtonLabel = styled.div`
    font-size: 3px;
    line-height: 21px;
    letter-spacing: 0.56px;
    color: #231f20;
    flex: none;
    order: 1;
    span {
        color: #231f20;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.56px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
    }
`;

export const SCRadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: none;
    order: 0;
    padding: 0 0 0 10px;
    flex-wrap: no-wrap;
`;

export const SCRadio = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: none;
    order: 0;
    padding: 0px;
    flex-wrap: no-wrap;
    gap: 12px;
    span {
        padding: 0px;
    }
`;

export const SCFormLabel = styled.div`
    label {
        line-height: 21px;
    }
`;
