export enum TSButtonVariantsEnum {
    ARROW = 'arrow',
    PLUS = 'plus',
    MINUS = 'minus',
    STEPPER = 'stepper',
    BACK = 'back',
    SLIDE = 'slide',
    TEXT = 'text',
    UNDERLINE = 'underline',
    SIMPLE = 'simple'
}
export enum TSButtonSizeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}
export enum TSButtonWeightEnum {
    LIGHT = 'light',
    MEDIUM = 'medium',
    BOLD = 'bold'
}
export enum TSButtonLetterSpacingEnum {
    NARROW = 'narrow',
    WIDE = 'wide'
}
