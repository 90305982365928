import React, { ReactElement, useEffect, useState } from 'react';
import { IBeforeUnloadProps } from './types';
import { Prompt, useHistory } from 'react-router-dom';
import { TSDialog } from 'components/atoms/TSDialog';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { SCBeforeUnloadWrapper } from './styles';
import { SCContentSectionResponsiveWrapper } from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { FormTypeContext } from 'context/formTypeContext';
import { IFormTypeState } from 'types/claim-types';
import { ClaimStateEnum } from 'enums/ClaimState';

const BeforeUnload = ({
    customDialogue,
    nativeDialogue,
    modalTitle,
    modalBody,
    continueButtonText,
    cancelHeaderButtonText,
    cancelButtonText,
    onContinue,
    onCancel,
    allowedRoutes,
    modalEnabled,
    isLoggedIn,
    beforeUnloadExecute
}: IBeforeUnloadProps): ReactElement => {
    const { saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const history = useHistory();
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<string | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [customConfirmationModalState, setCustomConfirmationModalState] = useState(false);
    useEffect(() => {
        const handleLeavePage = (eventRef: BeforeUnloadEvent): string => {
            const confirmationMessage = 'required string (do not remove)';
            eventRef.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        if (nativeDialogue && modalEnabled) {
            window.addEventListener('beforeunload', handleLeavePage);
        }
        return (): void => {
            window.removeEventListener('beforeunload', handleLeavePage);
        };
    }, [nativeDialogue, modalEnabled]);
    const handleBlockedNavigation = (nextLocation: Location): boolean | string => {
        const params = new URLSearchParams(nextLocation.search);
        const nextLocationAllowed =
            params.get('allowed') === 'true' ||
            (allowedRoutes && allowedRoutes?.filter((route) => nextLocation.pathname.indexOf(route) !== -1).length > 0);
        if (
            !confirmedNavigation &&
            typeof nextLocation !== 'undefined' &&
            lastLocation === null &&
            !nextLocationAllowed &&
            modalEnabled
        ) {
            setCustomConfirmationModalState(true);
            setLastLocation(nextLocation.pathname);
            return false;
        }
        return true;
    };
    const handleCancelStates = (): void => {
        onCancel && onCancel();
        setCustomConfirmationModalState(false);
        setConfirmedNavigation(false);
        setLastLocation(null);
    };
    useEffect(() => {
        const askUserConfirmation = async (): Promise<void | (() => void)> => {
            setConfirmModalIsVisible(true);
        };
        confirmModalIsVisible && askUserConfirmation();
    }, [
        confirmModalIsVisible,
        lastLocation,
        onContinue,
        onCancel,
        modalBody,
        modalTitle,
        continueButtonText,
        cancelButtonText,
        isLoggedIn
    ]);
    useEffect(() => {
        beforeUnloadExecute && beforeUnloadExecute();
        if (lastLocation !== null && confirmedNavigation) {
            history.push(lastLocation);
            saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
        }
    }, [confirmedNavigation, lastLocation, history]);

    return (
        <SCBeforeUnloadWrapper>
            <Prompt when={!confirmedNavigation} message={handleBlockedNavigation as any} />
            <TSDialog
                ariaLabel={modalTitle ? modalTitle : 'Unsaved Changes'}
                bannerHeaderText={modalTitle}
                bannerHeaderCloseButtonText={cancelHeaderButtonText}
                hideSecondaryCloseButton={true}
                handleClose={(): void => {
                    onCancel && onCancel();
                    handleCancelStates();
                }}
                id={'idString'}
                open={customConfirmationModalState && !!customDialogue}
                dialogSize={'sm'}
                content={modalBody}
                actionChildren={
                    <SCContentSectionResponsiveWrapper
                        flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexGap={'16px'}
                    >
                        <TSButton
                            ariaLabel="dialog close"
                            tsVariant={TSButtonVariantsEnum.TEXT}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                handleCancelStates();
                            }}
                        >
                            Return to page
                        </TSButton>
                        <TSButton
                            ariaLabel="dialog close"
                            tsVariant={TSButtonVariantsEnum.ARROW}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                setCustomConfirmationModalState(false);
                                setConfirmedNavigation(true);
                            }}
                        >
                            Proceed without saving
                        </TSButton>
                    </SCContentSectionResponsiveWrapper>
                }
            ></TSDialog>
        </SCBeforeUnloadWrapper>
    );
};

export { BeforeUnload };
