import * as React from 'react';
import { IChildrenProps } from 'types/children';
import { IClaimsManagementData, IClaimsManagementState } from 'types/claims-management';
import { IClaimEditType } from 'context/ClaimEdit/ClaimEditTypes';

export const ClaimsManagementStateContext: React.Context<IClaimsManagementState | null> =
    React.createContext<IClaimsManagementState | null>(null);
const ClaimsManagementStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = [
        {
            Borrower: {
                NameLast: '',
                NameFirst: '',
                Phone: '',
                SSNLast4: '',
                EDeliveryConfirmation: '',
                Email: '',
                Address: '',
                City: '',
                State: '',
                Zipcode: '',
                BorrowerIdentifierKey: '',
                EConsent: ''
            },
            ClaimInfo: {
                ClaimBenefits: [
                    {
                        ClaimBenefitId: '',
                        ClaimPolicyRelationshipId: '',
                        ExternalSystemIdentifier: '',
                        AmountPayable: '',
                        DecisionStatus: '',
                        DecisionDateTimeUTC: '',
                        DecisionReason: '',
                        DecisionEmailSentDateTimeUTC: '',
                        FastTracked: false,
                        DecisionAdditionalInfo: '',
                        IsDeleted: false
                    }
                ],
                JoblossNotificationDate: '',
                JoblossDate: '',
                JoblossLayoffTypicalAtThisTime: '',
                ClaimSubmittedRole: '',
                JoblossQualifiedForStateUnemployment: '',
                JoblossReason: '',
                JoblossLastDayWorkedDate: '',
                JoblossOccupation: '',
                JoblossKnowledgeOfLayoffWhenHired: '',
                JoblossRejectionStateBenefitReason: '',
                JoblossIsSelfEmployed: '',
                DisabilityDisabilityBeginDate: '',
                DisabilityExplanation: '',
                DisabilityHealthcareProfessionalName: '',
                DisabilityLastWorkedDate: '',
                DisabilityTypeOfSurgery: '',
                DisabilityFirstPhysicianDate: '',
                DisabilityJobDuties: '',
                DistributorName: '',
                LoanServicerName: '',
                ClaimId: '',
                ClaimNumber: -1,
                Peril: '',
                DisabilityJobClassification: '',
                DisabilityExpectedBackToWorkDate: '',
                DisabilityMedicalCategories: '',
                LoanNumber: -1,
                ClaimDecisionDateTime: '',
                ClaimDecision: '',
                AmountPayable: '',
                DisabilityDisablingCondition: '',
                DecisionReason: '',
                DecisionEmailSentDateTime: '',
                FastTrack: 'false',
                AdditionalInfo: '',
                ClaimStatus: '',
                Comment: '',
                NameOfClaimSpecialist: '',
                Notes: '',
                ClaimCreateDateTimeUTC: '',
                IncurredDate: '',
                DisablingCondition: ''
            },
            Submitter: {
                NameLast: '',
                NameFirst: '',
                Email: '',
                Phone: '',
                CompanyName: ''
            },
            EmployerInfo: {
                CompanyName: '',
                Email: '',
                Phone: '',
                Address: '',
                City: '',
                State: '',
                Zipcode: ''
            },
            HealthcareProvider: {
                Phone: '',
                Email: '',
                ContactName: '',
                CompanyName: '',
                Address: '',
                City: '',
                State: '',
                Zipcode: ''
            },
            CaptchaToken: '',
            AmountPayable: '',
            BorrowerIdentifierKey: '',
            ClaimDecision: '',
            ClaimNumber: '',
            NameFirst: '',
            NameLast: '',
            LoanNumber: '',
            Peril: '',
            DecisionReason: '',
            DecisionDateTimeUTC: '',
            ClaimStatusLastUpdateDateTimeUTC: '',
            ClaimSubmitDateTimeUTC: '',
            EditStatus: false
        }
    ];
    const [claimsManagementTableState, setClaimsManagementTableState] =
        React.useState<IClaimsManagementData[]>(initialState);
    const [claimsManagementEditArrayState, setClaimsManagementEditArrayState] =
        React.useState<IClaimsManagementData[]>(initialState);
    const initializeTableRowStatusState = (claimsManagementData: IClaimsManagementData[]): void => {
        if (claimsManagementData.length > 0) {
            setClaimsManagementTableState(claimsManagementData);
        }
    };
    const initializeTableDataState = (claimsManagementData: IClaimsManagementData[]): void => {
        if (claimsManagementData.length > 0) {
            setClaimsManagementEditArrayState(claimsManagementData);
        }
    };
    const updateTableRowStatusState = (claimNumber: number): void => {
        claimsManagementTableState.filter((rowData) => {
            if (rowData.ClaimInfo.ClaimNumber === claimNumber) {
                rowData.EditStatus = true;
                setClaimsManagementTableState([...claimsManagementTableState]);
            }
        });
    };
    const updateClaimManagementRecord = (claimsManagementData: IClaimEditType): void => {
        const existingRecord: IClaimsManagementData | undefined = claimsManagementEditArrayState.find(
            (record) => record.ClaimInfo.ClaimNumber === claimsManagementData.claimNumber
        );
        console.info(
            '\n::::::::::::::::::::::::::::::::ClaimsManagementStateContext::updateClaimManagementRecord:::::::::::::::::::::::::::::::',
            '\n::claimsManagementEditArrayState::',
            claimsManagementEditArrayState,
            '\n::updateClaimManagementRecord::',
            updateClaimManagementRecord,
            '\n::claimsManagementData::',
            claimsManagementData,
            '\n::claimsManagementData.amountPayable::',
            claimsManagementData.amountPayable,
            '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
        );
        const claimsManagementDataAssignment: IClaimsManagementData = {
            Borrower: {
                NameLast: claimsManagementData.nameLast,
                NameFirst: claimsManagementData.nameFirst,
                SSNLast4: claimsManagementData.last4SSN,
                EConsent: claimsManagementData.eConsent,
                Phone:
                    existingRecord && existingRecord.Borrower.Phone
                        ? existingRecord && existingRecord.Borrower.Phone
                        : '',
                EDeliveryConfirmation:
                    existingRecord && existingRecord.Borrower.EDeliveryConfirmation
                        ? existingRecord && existingRecord.Borrower.EDeliveryConfirmation
                        : '',
                Email:
                    existingRecord && existingRecord.Borrower.Email
                        ? existingRecord && existingRecord.Borrower.Email
                        : '',
                Address:
                    existingRecord && existingRecord.Borrower.Address
                        ? existingRecord && existingRecord.Borrower.Address
                        : '',
                City:
                    existingRecord && existingRecord.Borrower.City
                        ? existingRecord && existingRecord.Borrower.City
                        : '',
                State:
                    existingRecord && existingRecord.Borrower.State
                        ? existingRecord && existingRecord.Borrower.State
                        : '',
                Zipcode:
                    existingRecord && existingRecord.Borrower.Zipcode
                        ? existingRecord && existingRecord.Borrower.Zipcode
                        : '',
                BorrowerIdentifierKey:
                    existingRecord && existingRecord.Borrower.BorrowerIdentifierKey
                        ? existingRecord && existingRecord.Borrower.BorrowerIdentifierKey
                        : ''
            },
            ClaimInfo: {
                ClaimBenefits: claimsManagementData.ClaimBenefits,
                AmountPayable: claimsManagementData.amountPayable,
                DistributorName: claimsManagementData.distributorName,
                LoanServicerName: claimsManagementData.loanServicerName,
                ClaimNumber: claimsManagementData.claimNumber,
                ClaimDecisionDateTime: claimsManagementData.dateOfClaimDecision,
                ClaimDecision: claimsManagementData.claimDecision,
                DecisionReason: claimsManagementData.decisionReason,
                DecisionEmailSentDateTime: claimsManagementData.dateDecisionEmailSent,
                JoblossReason: claimsManagementData.jobLossReason,
                Peril: claimsManagementData.peril,
                LoanNumber: claimsManagementData.loanNumber,
                FastTrack: claimsManagementData.fastTrack,
                AdditionalInfo: claimsManagementData.additionalInfo,
                NameOfClaimSpecialist: claimsManagementData.nameOfClaimSpecialist,
                Notes: claimsManagementData.notes,
                IncurredDate: claimsManagementData.dateIncurred,
                ClaimCreateDateTimeUTC: claimsManagementData.claimCreateDate,
                DisablingCondition: claimsManagementData.disablingCondition,
                JoblossNotificationDate:
                    existingRecord && existingRecord.ClaimInfo.JoblossNotificationDate
                        ? existingRecord && existingRecord.ClaimInfo.JoblossNotificationDate
                        : '',
                JoblossDate:
                    existingRecord && existingRecord.ClaimInfo.JoblossDate
                        ? existingRecord && existingRecord.ClaimInfo.JoblossDate
                        : '',
                JoblossLayoffTypicalAtThisTime:
                    existingRecord && existingRecord.ClaimInfo.JoblossLayoffTypicalAtThisTime
                        ? existingRecord && existingRecord.ClaimInfo.JoblossLayoffTypicalAtThisTime
                        : '',
                ClaimSubmittedRole:
                    existingRecord && existingRecord.ClaimInfo.ClaimSubmittedRole
                        ? existingRecord && existingRecord.ClaimInfo.ClaimSubmittedRole
                        : '',
                JoblossQualifiedForStateUnemployment:
                    existingRecord && existingRecord.ClaimInfo.JoblossQualifiedForStateUnemployment
                        ? existingRecord && existingRecord.ClaimInfo.JoblossQualifiedForStateUnemployment
                        : '',
                JoblossLastDayWorkedDate:
                    existingRecord && existingRecord.ClaimInfo.JoblossLastDayWorkedDate
                        ? existingRecord && existingRecord.ClaimInfo.JoblossLastDayWorkedDate
                        : '',
                JoblossOccupation:
                    existingRecord && existingRecord.ClaimInfo.JoblossOccupation
                        ? existingRecord && existingRecord.ClaimInfo.JoblossOccupation
                        : '',
                JoblossKnowledgeOfLayoffWhenHired:
                    existingRecord && existingRecord.ClaimInfo.JoblossKnowledgeOfLayoffWhenHired
                        ? existingRecord && existingRecord.ClaimInfo.JoblossKnowledgeOfLayoffWhenHired
                        : '',
                JoblossRejectionStateBenefitReason:
                    existingRecord && existingRecord.ClaimInfo.JoblossRejectionStateBenefitReason
                        ? existingRecord && existingRecord.ClaimInfo.JoblossRejectionStateBenefitReason
                        : '',
                JoblossIsSelfEmployed:
                    existingRecord && existingRecord.ClaimInfo.JoblossIsSelfEmployed
                        ? existingRecord && existingRecord.ClaimInfo.JoblossIsSelfEmployed
                        : '',
                DisabilityJobDuties:
                    existingRecord && existingRecord.ClaimInfo.DisabilityJobDuties
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityJobDuties
                        : '',
                DisabilityDisabilityBeginDate:
                    existingRecord && existingRecord.ClaimInfo.DisabilityDisabilityBeginDate
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityDisabilityBeginDate
                        : '',
                DisabilityExplanation:
                    existingRecord && existingRecord.ClaimInfo.DisabilityExplanation
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityExplanation
                        : '',
                DisabilityHealthcareProfessionalName:
                    existingRecord && existingRecord.ClaimInfo.DisabilityHealthcareProfessionalName
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityHealthcareProfessionalName
                        : '',
                DisabilityLastWorkedDate:
                    existingRecord && existingRecord.ClaimInfo.DisabilityLastWorkedDate
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityLastWorkedDate
                        : '',
                DisabilityTypeOfSurgery:
                    existingRecord && existingRecord.ClaimInfo.DisabilityTypeOfSurgery
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityTypeOfSurgery
                        : '',
                DisabilityFirstPhysicianDate:
                    existingRecord && existingRecord.ClaimInfo.DisabilityFirstPhysicianDate
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityFirstPhysicianDate
                        : '',
                DisabilityJobClassification:
                    existingRecord && existingRecord.ClaimInfo.DisabilityJobClassification
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityJobClassification
                        : '',
                DisabilityExpectedBackToWorkDate:
                    existingRecord && existingRecord.ClaimInfo.DisabilityExpectedBackToWorkDate
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityExpectedBackToWorkDate
                        : '',
                DisabilityMedicalCategories:
                    existingRecord && existingRecord.ClaimInfo.DisabilityMedicalCategories
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityMedicalCategories
                        : '',
                ClaimId:
                    existingRecord && existingRecord.ClaimInfo.ClaimId
                        ? existingRecord && existingRecord.ClaimInfo.ClaimId
                        : '',
                DisabilityDisablingCondition:
                    existingRecord && existingRecord.ClaimInfo.DisabilityDisablingCondition
                        ? existingRecord && existingRecord.ClaimInfo.DisabilityDisablingCondition
                        : '',
                ClaimStatus:
                    existingRecord && existingRecord.ClaimInfo.ClaimStatus
                        ? existingRecord && existingRecord.ClaimInfo.ClaimStatus
                        : '',
                Comment:
                    existingRecord && existingRecord.ClaimInfo.Comment
                        ? existingRecord && existingRecord.ClaimInfo.Comment
                        : ''
            },
            EmployerInfo: {
                CompanyName:
                    existingRecord && existingRecord.EmployerInfo.CompanyName
                        ? existingRecord && existingRecord.EmployerInfo.CompanyName
                        : '',
                Email:
                    existingRecord && existingRecord.EmployerInfo.Email
                        ? existingRecord && existingRecord.EmployerInfo.Email
                        : '',
                Phone:
                    existingRecord && existingRecord.EmployerInfo.Phone
                        ? existingRecord && existingRecord.EmployerInfo.Phone
                        : '',
                Address:
                    existingRecord && existingRecord.EmployerInfo.Address
                        ? existingRecord && existingRecord.EmployerInfo.Address
                        : '',
                City:
                    existingRecord && existingRecord.EmployerInfo.City
                        ? existingRecord && existingRecord.EmployerInfo.City
                        : '',
                State:
                    existingRecord && existingRecord.EmployerInfo.State
                        ? existingRecord && existingRecord.EmployerInfo.State
                        : '',
                Zipcode:
                    existingRecord && existingRecord.EmployerInfo.Zipcode
                        ? existingRecord && existingRecord.EmployerInfo.Zipcode
                        : ''
            },
            HealthcareProvider: {
                Phone:
                    existingRecord && existingRecord.HealthcareProvider.Phone
                        ? existingRecord && existingRecord.HealthcareProvider.Phone
                        : '',
                CompanyName:
                    existingRecord && existingRecord.HealthcareProvider.CompanyName
                        ? existingRecord && existingRecord.HealthcareProvider.CompanyName
                        : '',
                Email:
                    existingRecord && existingRecord.HealthcareProvider.Email
                        ? existingRecord && existingRecord.HealthcareProvider.Email
                        : '',
                ContactName:
                    existingRecord && existingRecord.HealthcareProvider.ContactName
                        ? existingRecord && existingRecord.HealthcareProvider.ContactName
                        : '',
                Address:
                    existingRecord && existingRecord.HealthcareProvider.Address
                        ? existingRecord && existingRecord.HealthcareProvider.Address
                        : '',
                City:
                    existingRecord && existingRecord.HealthcareProvider.City
                        ? existingRecord && existingRecord.HealthcareProvider.City
                        : '',
                State:
                    existingRecord && existingRecord.HealthcareProvider.State
                        ? existingRecord && existingRecord.HealthcareProvider.State
                        : '',
                Zipcode:
                    existingRecord && existingRecord.HealthcareProvider.Zipcode
                        ? existingRecord && existingRecord.HealthcareProvider.Zipcode
                        : ''
            },
            Submitter: {
                Phone:
                    existingRecord && existingRecord.Submitter.Phone
                        ? existingRecord && existingRecord.Submitter.Phone
                        : '',
                CompanyName:
                    existingRecord && existingRecord.Submitter.CompanyName
                        ? existingRecord && existingRecord.Submitter.CompanyName
                        : '',
                Email:
                    existingRecord && existingRecord.Submitter.Email
                        ? existingRecord && existingRecord.Submitter.Email
                        : '',
                NameLast:
                    existingRecord && existingRecord.Submitter.NameLast
                        ? existingRecord && existingRecord.Submitter.NameLast
                        : '',
                NameFirst:
                    existingRecord && existingRecord.Submitter.NameFirst
                        ? existingRecord && existingRecord.Submitter.NameFirst
                        : ''
            },
            EditStatus: true,
            CaptchaToken:
                existingRecord && existingRecord.CaptchaToken ? existingRecord && existingRecord.CaptchaToken : '',
            AmountPayable: existingRecord?.AmountPayable ? existingRecord?.AmountPayable : '',
            BorrowerIdentifierKey: existingRecord?.Borrower?.BorrowerIdentifierKey
                ? existingRecord?.Borrower?.BorrowerIdentifierKey
                : '',
            ClaimDecision:
                existingRecord && existingRecord.ClaimDecision ? existingRecord && existingRecord.ClaimDecision : '',
            ClaimNumber:
                existingRecord && existingRecord.ClaimNumber ? existingRecord && existingRecord.ClaimNumber : '',
            NameFirst: existingRecord && existingRecord.NameFirst ? existingRecord && existingRecord.NameFirst : '',
            NameLast: existingRecord && existingRecord.NameLast ? existingRecord && existingRecord.NameLast : '',
            LoanNumber: existingRecord && existingRecord.LoanNumber ? existingRecord && existingRecord.LoanNumber : '',
            Peril: existingRecord && existingRecord.Peril ? existingRecord && existingRecord.Peril : '',
            DecisionReason:
                existingRecord && existingRecord.DecisionReason ? existingRecord && existingRecord.DecisionReason : '',
            DecisionDateTimeUTC:
                existingRecord && existingRecord.DecisionDateTimeUTC
                    ? existingRecord && existingRecord.DecisionDateTimeUTC
                    : '',
            ClaimStatusLastUpdateDateTimeUTC:
                existingRecord && existingRecord.ClaimStatusLastUpdateDateTimeUTC
                    ? existingRecord && existingRecord.ClaimStatusLastUpdateDateTimeUTC
                    : '',
            ClaimSubmitDateTimeUTC:
                existingRecord && existingRecord.ClaimSubmitDateTimeUTC
                    ? existingRecord && existingRecord.ClaimSubmitDateTimeUTC
                    : ''
        };
        const mapMatchResult = claimsManagementEditArrayState.map((record) =>
            record.ClaimInfo.ClaimNumber === claimsManagementDataAssignment.ClaimInfo.ClaimNumber
                ? claimsManagementDataAssignment
                : record
        );
        setClaimsManagementEditArrayState(mapMatchResult);
        console.info(
            '\n::::::::::::::::::::::::::::::::ClaimsManagementStateContext::updateClaimManagementRecord:::::::::::::::::::::::::::::::',
            '\n::claimsManagementEditArrayState::',
            claimsManagementEditArrayState,
            '\n::claimsManagementData::',
            claimsManagementData,
            '\n::claimsManagementDataAssignment::',
            claimsManagementDataAssignment,
            '\n::claimsManagementDataAssignment.BorrowerIdentifierKey::',
            claimsManagementDataAssignment.BorrowerIdentifierKey,
            '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
        );
    };

    return (
        <ClaimsManagementStateContext.Provider
            value={{
                claimsManagementEditArrayState,
                initializeTableDataState,
                claimsManagementTableState,
                initializeTableRowStatusState,
                updateTableRowStatusState,
                updateClaimManagementRecord
            }}
        >
            {children}
        </ClaimsManagementStateContext.Provider>
    );
};

export default ClaimsManagementStateProvider;
