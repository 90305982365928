import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { SCRootWrapper } from './styles';
import { ITSBackNavigationProps } from './types';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimTypesEnum, TimelineState } from 'enums';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { IFormDisabilityProgressState, IFormJoblossProgressState } from 'types/form-progress';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { FormTypeContext } from 'context/formTypeContext';
import { IFormTypeState } from 'types/claim-types';
import { ClaimStateEnum } from 'enums/ClaimState';
import {
    $digitalBlack,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCSectionBody,
    SCSectionSubHead
} from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { TSDialog } from 'components/atoms/TSDialog';

const TSBackNavigation = ({ isFormDirty }: ITSBackNavigationProps): ReactElement => {
    const { updateFormJoblossProgressState, formJoblossProgressState, updateFormJoblossProgressCompletionStatus } =
        React.useContext(FormJoblossProgressStateContext) as IFormJoblossProgressState;
    const { updateFormDisabilityProgressState, formDisabilityProgressState } = React.useContext(
        FormDisabilityProgressStateContext
    ) as IFormDisabilityProgressState;
    const { formType, saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const currentId =
        formType.type === ClaimTypesEnum.Jobloss
            ? formJoblossProgressState
                  .filter((item): boolean => item.status === TimelineState.ACTIVE)
                  .map((item) => item.id)
            : formDisabilityProgressState
                  .filter((item): boolean => item.status === TimelineState.ACTIVE)
                  .map((item) => item.id);
    const decrementId = currentId[0]
        ? currentId[0].replace(/\d+$/, (n): string => {
              return (parseInt(n) - 1) as unknown as string;
          })
        : '';
    const handleBackFormStates = (): void => {
        if (currentId[0] === 'id-1') {
            saveFormType({
                type: undefined,
                reviewState: ClaimStateEnum.ACTIVE
            });
            updateFormJoblossProgressState(currentId[0], TimelineState.COMPLETE);
            updateFormDisabilityProgressState(currentId[0], TimelineState.COMPLETE);
            setConfirmModalIsVisible(true);
        }
        if (formType.type === ClaimTypesEnum.Jobloss) {
            updateFormJoblossProgressState(decrementId, TimelineState.ACTIVE);
            updateFormJoblossProgressState(currentId[0], TimelineState.COMPLETE);
        } else if (formType.type === ClaimTypesEnum.Disability) {
            updateFormDisabilityProgressState(decrementId, TimelineState.ACTIVE);
            updateFormDisabilityProgressState(currentId[0], TimelineState.COMPLETE);
        }
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSBackNavigation:::::::::::::::::::::::::::::::::',
    //     '\n::currentId::',
    //     currentId,
    //     '\n::decrementId::',
    //     decrementId,
    //     '\n::formJoblossProgressState::',
    //     formJoblossProgressState,
    //     '\n::formDisabilityProgressState::',
    //     formDisabilityProgressState,
    //     '\n::isFormDirty::',
    //     isFormDirty,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <>
            <SCRootWrapper>
                <TSButton
                    tsVariant={TSButtonVariantsEnum.BACK}
                    ariaLabel={'button for saving table changes'}
                    onClick={(event: SyntheticEvent<Element, Event>): void => {
                        isFormDirty && currentId[0] === 'id-1'
                            ? setConfirmModalIsVisible(true)
                            : handleBackFormStates();
                    }}
                >
                    Back
                </TSButton>
            </SCRootWrapper>
            <TSDialog
                ariaLabel={'Unsaved Changes dialog'}
                hideSecondaryCloseButton
                handleClose={(): void => {
                    setConfirmModalIsVisible(false);
                }}
                id={'unsavedChangesDialog'}
                open={confirmModalIsVisible}
                dialogSize={'sm'}
                bannerHeaderCloseButtonText={'Close'}
                content={
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                    >
                        <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                        <SCSectionBody>
                            You have unsaved claim information, continuing may cause your information to be lost
                        </SCSectionBody>
                    </SCContentSectionWrapper>
                }
                actionChildren={
                    <SCContentSectionResponsiveWrapper
                        flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexGap={'16px'}
                    >
                        <TSButton
                            ariaLabel="dialog return to page"
                            tsVariant={TSButtonVariantsEnum.TEXT}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                setConfirmModalIsVisible(false);
                            }}
                        >
                            Return to page
                        </TSButton>
                        <TSButton
                            ariaLabel="dialog proceed without saving"
                            tsVariant={TSButtonVariantsEnum.ARROW}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                handleBackFormStates();
                                setConfirmModalIsVisible(false);
                            }}
                        >
                            Proceed without saving
                        </TSButton>
                    </SCContentSectionResponsiveWrapper>
                }
            ></TSDialog>
        </>
    );
};

export { TSBackNavigation };
