import * as React from 'react';
import { Theme, ThemeContextType } from 'types/theme';
import { IChildrenProps } from 'types/children';

export const GlobalThemeContext = React.createContext<ThemeContextType | null>(null);

const ThemeProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [themeMode, setThemeMode] = React.useState<Theme>('light');
    return (
        <GlobalThemeContext.Provider value={{ theme: themeMode, changeTheme: setThemeMode }}>
            {children}
        </GlobalThemeContext.Provider>
    );
};

export default ThemeProvider;
