import styled from 'styled-components';
import { $brightBlue20, $contrastGrey03, $darkGray, $greyScaleWhite, $white } from 'styles/global-styles';

export const SCTableHeadCellWrapper = styled.th`
    height: 64px;
    background: ${$darkGray};
    border-width: 0 4px 2px 0;
    border-style: solid;
    border-color: ${$white};
    vertical-align: middle;
    padding: 0 0 0 8px;
    margin: 0 8px 0 0;
    border-right: 4px solid ${$contrastGrey03};
    border-bottom: 2px solid ${$contrastGrey03};
    span {
        display: flex;
        flex-direction: row;
        -webkit-box-pack: justify;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
        white-space: nowrap;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 4px;
        color: ${$white};
    }
    svg {
        path {
            fill: ${$white};
        }
    }
    .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
        color: ${$white};
        svg {
            path {
                fill: ${$greyScaleWhite};
            }
        }
    }
    .MuiButtonBase-root.MuiTableSortLabel-root:hover {
        color: ${$greyScaleWhite};
        svg {
            path {
                fill: ${$brightBlue20};
            }
        }
    }
`;
