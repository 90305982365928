import React, { ReactElement, useState } from 'react';
import { ITSButtonProps } from './types';
import { ArrowIcon } from 'components/atoms/ArrowIcon';
import { CaretIcon } from 'components/atoms/CaretIcon';
import { Button } from '@mui/material';
import {
    SCButtonContentWrapperStepper,
    SCButtonIconWrapperArrow,
    SCButtonIconWrapperBack,
    SCButtonIconWrapperMinus,
    SCButtonIconWrapperPlus,
    SCButtonWrapper,
    SCButtonWrapperArrow,
    SCButtonWrapperBack,
    SCButtonWrapperMinus,
    SCButtonWrapperSlide,
    SCButtonWrapperStepper,
    SCButtonWrapperUnderline,
    SCSimpleButtonWrapper,
    SCTextWrapper,
    SCTextWrapperArrow,
    SCTextWrapperBack,
    SCTextWrapperMinus,
    SCTextWrapperSlide
} from 'components/atoms/TSButton/styles';
import { TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { StatusIcon } from 'components/atoms/StatusIcon';
import { PlusIcon } from 'components/atoms/PlusIcon';
import { MinusIcon } from 'components/atoms/MinusIcon';

const TSButton = ({
    tsVariant,
    children,
    ariaLabel,
    onClick,
    disabled,
    size,
    weight,
    status,
    stepValue,
    letterSpacing
}: ITSButtonProps): ReactElement => {
    const [buttonOverState, setButtonOverState] = useState(false);
    const [buttonSlideOver, setButtonSlideOver] = useState(false);
    const [buttonSlideOut, setButtonSlideOut] = useState('false');
    tsVariant = tsVariant ? tsVariant : TSButtonVariantsEnum.ARROW;
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSButton:::::::::::::::::::::::::::::::::',
    //     '\n::size::',
    //     size,
    //     '\n::buttonOverState::',
    //     buttonOverState,
    //     '\n::disabled::',
    //     disabled,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <>
            {tsVariant === TSButtonVariantsEnum.ARROW && (
                <SCButtonWrapperArrow data-testid={buttonOverState ? 'ArrowButtonActive' : 'ArrowButtonInactive'}>
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                        size={size}
                        startIcon={
                            <SCButtonIconWrapperArrow size={size}>
                                <ArrowIcon iconState={buttonOverState} disabled={disabled ? disabled : false} />
                            </SCButtonIconWrapperArrow>
                        }
                    >
                        <SCTextWrapperArrow size={size}>{children}</SCTextWrapperArrow>
                    </Button>
                </SCButtonWrapperArrow>
            )}
            {tsVariant === TSButtonVariantsEnum.PLUS && (
                <SCButtonWrapperArrow data-testid={buttonOverState ? 'PlusButtonActive' : 'PlusButtonInactive'}>
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                        size={size}
                        startIcon={
                            <SCButtonIconWrapperPlus size={size}>
                                <PlusIcon iconState={buttonOverState} disabled={disabled ? disabled : false} />
                            </SCButtonIconWrapperPlus>
                        }
                    >
                        <SCTextWrapperArrow size={size}>{children}</SCTextWrapperArrow>
                    </Button>
                </SCButtonWrapperArrow>
            )}
            {tsVariant === TSButtonVariantsEnum.SIMPLE && (
                <SCSimpleButtonWrapper data-testid={buttonOverState ? 'ArrowButtonActive' : 'ArrowButtonInactive'}>
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                        size={size}
                        sx={{
                            backgroundColor: '#fae07e',
                            borderColor: 'black',
                            border: 1,
                            width: '80%',
                            color: 'black'
                        }}
                    >
                        {children}
                    </Button>
                </SCSimpleButtonWrapper>
            )}
            {tsVariant === TSButtonVariantsEnum.STEPPER && (
                <SCButtonWrapperStepper data-testid={buttonOverState ? 'StepperButtonActive' : 'StepperButtonInactive'}>
                    <Button
                        aria-label={`step ${stepValue}`}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="outlined"
                    >
                        <SCButtonContentWrapperStepper>
                            <StatusIcon iconState={buttonOverState} stepValue={stepValue} status={status}></StatusIcon>
                        </SCButtonContentWrapperStepper>
                    </Button>
                </SCButtonWrapperStepper>
            )}
            {tsVariant === TSButtonVariantsEnum.BACK && (
                <SCButtonWrapperBack data-testid={buttonOverState ? 'BackButtonActive' : 'BackButtonInactive'}>
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                        size={'small'}
                        startIcon={
                            <SCButtonIconWrapperBack size={size}>
                                <CaretIcon iconState={buttonOverState} disabled={disabled ? disabled : false} />
                            </SCButtonIconWrapperBack>
                        }
                    >
                        <SCTextWrapperBack size={size}>{children}</SCTextWrapperBack>
                    </Button>
                </SCButtonWrapperBack>
            )}
            {tsVariant === TSButtonVariantsEnum.MINUS && (
                <SCButtonWrapperMinus data-testid={buttonOverState ? 'MinusButtonActive' : 'MinusButtonInactive'}>
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                        size={'small'}
                        startIcon={
                            <SCButtonIconWrapperMinus size={size}>
                                <MinusIcon iconState={buttonOverState} disabled={disabled ? disabled : false} />
                            </SCButtonIconWrapperMinus>
                        }
                    >
                        <SCTextWrapperMinus size={size}>{children}</SCTextWrapperMinus>
                    </Button>
                </SCButtonWrapperMinus>
            )}
            {tsVariant === TSButtonVariantsEnum.SLIDE && (
                <SCButtonWrapperSlide
                    onMouseEnter={(): void => {
                        setButtonSlideOver(true);
                        setButtonSlideOut('false');
                    }}
                    onMouseLeave={(): void => {
                        setButtonSlideOver(false);
                        setButtonSlideOut('true');
                    }}
                    slideOverState={buttonSlideOver}
                    slideOutState={buttonSlideOut}
                    size={size}
                    weight={weight}
                    data-testid={buttonOverState ? 'SlideButtonActive' : 'SlideButtonInactive'}
                >
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                    >
                        <SCTextWrapperSlide>{children}</SCTextWrapperSlide>
                    </Button>
                </SCButtonWrapperSlide>
            )}
            {tsVariant === TSButtonVariantsEnum.TEXT && (
                <SCButtonWrapper
                    onMouseEnter={(): void => {
                        setButtonSlideOver(true);
                        setButtonSlideOut('false');
                    }}
                    onMouseLeave={(): void => {
                        setButtonSlideOver(false);
                        setButtonSlideOut('true');
                    }}
                    data-testid={buttonOverState ? `TextButtonActive` : `TextButtonInactive`}
                >
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                    >
                        <SCTextWrapper size={size}>{children}</SCTextWrapper>
                    </Button>
                </SCButtonWrapper>
            )}
            {tsVariant === TSButtonVariantsEnum.UNDERLINE && (
                <SCButtonWrapperUnderline
                    onMouseEnter={(): void => {
                        setButtonSlideOver(true);
                        setButtonSlideOut('false');
                    }}
                    onMouseLeave={(): void => {
                        setButtonSlideOver(false);
                        setButtonSlideOut('true');
                    }}
                    slideOverState={buttonSlideOver}
                    data-testid={buttonOverState ? `UnderlineButtonActive` : `UnderlineButtonInactive`}
                    size={size}
                    weight={weight}
                    letterSpacing={letterSpacing}
                >
                    <Button
                        aria-label={ariaLabel}
                        disabled={disabled ? disabled : false}
                        onMouseEnter={(): void => {
                            setButtonOverState(true);
                        }}
                        onMouseLeave={(): void => {
                            setButtonOverState(false);
                        }}
                        onClick={onClick}
                        variant="text"
                    >
                        <SCTextWrapper size={size}>{children}</SCTextWrapper>
                    </Button>
                </SCButtonWrapperUnderline>
            )}
        </>
    );
};

export { TSButton };
