import styled from 'styled-components';
import { $yellow } from 'styles/global-styles';

export const SCTSFooterWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
`;
export const SCTSFooterColorBar = styled.div`
    background-color: rgba(250, 224, 126, 1);
    width: 100%;
    height: 9px;
`;
export const SCTSFooterColorBarWrapper = styled.div`
    width: 100%;
    height: 9px;
`;
export const SCTSFooterContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    width: 100%;
    max-width: 1383px;
    padding: 32px 0 0 0;
    @media (max-width: 1440px) {
        max-width: calc(100vw - 230px);
    }
    @media (max-width: 1024px) {
        padding: 16px 0 0 0;
        margin-left: 0;
    }
    @media (max-width: 768px) {
        padding: 32px 32px 79px 32px;
        max-width: 100%;
    }
`;

export const SCTSTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    min-height: 225px;
    button {
        margin: 8px;
        max-width: 270px;
    }
    @media (max-width: 1024px) {
        > * {
            &:nth-child(4) {
                display: none;
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        > * {
            &:nth-child(3) {
                margin: 0;
            }
            &:nth-child(4) {
                display: none;
            }
        }
    }
`;
export const SCTSBottomWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const SCTSColumContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    margin: 0 12px 0 0;
    @media (max-width: 768px) {
        padding: 16px 0 0 0;
        margin: 0 0 71px 0;
    }
`;
export const SCTSFooterContent = styled.div`
    width: 100%;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;
export const SCTopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    width: 100%;
    button {
        margin: 8px;
        max-width: 270px;
    }
`;
export const SCContactContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 30%;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: 16px 0 16px 0;
    }
`;
export const SCContentRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 5px;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        padding: 0 0 16px 0;
        margin: 16px 0 16px 0;
    }
`;
export const SCColumnHeader = styled.div`
    width: 100%;
    margin: 0 0 16px 0;
    font-size: 24px;
    font-style: normal;
    line-height: 36px;
    font-weight: 700;
`;
export const SCTSContactContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(35, 31, 32, 1);
    text-align: left;
    a {
        margin: 12px 0 24px 0;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.5600000023841858px;
        text-align: left;
        color: rgba(25, 48, 98, 1);
    }
    p {
        margin: 12px 0 0px 0;
    }
    button {
        max-height: 21px;
        font-weight: 400;
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        padding-right: 0;
    }
`;
