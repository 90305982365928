export const labelMapIU = {
    claimNumber: 'Claim Number',
    borrowerIdentifierKey: ['Last 4 of SSN', 'Last 4 of Phone Number'],
    peril: 'Type of Claim',
    status: 'Status',
    borrowerName: 'Name',
    dateFiled: 'Date Filed',
    datePaid: 'Date Paid',
    incurredDate: 'Date Incurred',
    distributorName: 'Institution',
    claimDecisionDate: 'Date of Claim Decision',
    amountPaid: 'Amount Paid',
    loanNumber: 'Loan Number',
    claimDecision: 'Claim Decision',
    claimDecisionReason: 'Decision Reason'
};
