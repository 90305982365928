import styled, { css, ThemeProps, FlattenInterpolation } from 'styled-components';
import { SCElementWrapper, SCLabel } from 'styles/global-styles';

export const $deepBlue: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.secondary.deepBlue}
`;
export const SCCustomElementWrapper = styled(SCElementWrapper)`
    min-height: 20px;
    flex-direction: column;
    width: fit-content;
    padding: 0;
`;
export const SCFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 5px;
    @media (max-width: 768px) {
        padding: 0;
    }
`;
export const SCLineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;
export const SCColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const SCSingleLineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 48px;
    width: 100%;
    height: auto;
    padding: 0;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 12px;
    }
    span {
        width: 150px;
    }
`;
export const SCContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    margin: 0 0 48px 0;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
    }
`;
interface IDivider {
    verticalWidth: boolean;
}
export const SCDivider = styled.div<IDivider>`
    ${(props): string => (props.verticalWidth ? 'width: 1px;' : 'width: 100%; height 1px;')}
    margin: 6px 0;
    background: #d1d6e0;
    @media (max-width: 768px) {
        width: 100%;
        height: 1px;
    }
`;
export const SCSmallLabel = styled(SCLabel)`
    font-weight: 500;
    line-height: 26px;
    font-style: normal;
    font-size: 14px !important;
    color: #646468;
`;
export const SCValueBold = styled.div`
    width: fit-content;
    white-space: nowrap;
    font-weight: 700;
    font-style: normal;
    font-size: 24px !important;
    line-height: 30px;
    text-transform: capitalize;
    text-align: left;
    padding: 2px 0 0 0;
    overflow-wrap: break-word;
    @media (max-width: 768px) {
        font-size: 20px !important;
    }
`;
export const SCLabelSmallBold = styled(SCLabel)`
    width: fit-content;
    white-space: nowrap;
    color: #000000;
    font-weight: 700;
    font-style: normal;
    font-size: 14px !important;
    line-height: 30px;
    text-transform: capitalize;
    text-align: left;
`;
export const SCValue = styled.div`
    font-size: 14px !important;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
`;
export const SCBackButton = styled.div`
    display: flex;
    flex-wrap: nowrap;
    padding-top: 20px;
    align-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    button {
        font-weight: 700;
        text-transform: none;
        padding: 0;
    }
`;
export const SCBenefitHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    height: fit-content;
    width: 100%;
    padding: 0;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    color: ${$deepBlue};
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 12px;
    }
    span {
        width: 150px;
    }
`;
export const SCTableHeadWrapper = styled.th`
    height: 64px;
    vertical-align: middle;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 4px;
    justify-content: left;
    font-size: 20px;
    padding: 0 16px 0 0;
`;
export const SCTableOverrides = styled.div`
    border: transparent;
    .MuiFormControl-fullWidth {
        font-size: 20px;
    }
`;
