import React, { ReactElement, useEffect, useState } from 'react';
import { FormTypeContext } from 'context/formTypeContext';
import {
    keysBorrowDetailColumn1,
    keysBorrowDetailColumn2,
    keysIUJobLossDetailsRow1Column1,
    keysIUJobLossDetailsRow1Column2,
    keysIUJobLossDetailsRow2Column1,
    keysIUJobLossDetailsRow2Column2,
    keysEmployerDetailColumn1,
    keysEmployerDetailColumn2,
    keysDisabilityDetailsRow1Column1,
    keysDisabilityDetailsRow1Column2,
    keysDisabilityDetailsRow2Column1,
    keysIUCommentsColumn1,
    keysIUCommentsColumn2,
    keysDisabilityCommentsColumn1,
    keysDisabilityCommentsColumn2,
    keysDisabilityDetailsHealthcareRow1Column1,
    keysDisabilityDetailsHealthcareRow1Column2
} from 'components/organisms/FormIU/fieldTypes';
import { ClaimTypesEnum } from 'enums/ClaimTypes';
import { IFormTypeState } from 'types/claim-types';
import { IFormReviewProps } from './types';
import { SCDiv } from './styles';
import { SCHRElement } from 'styles/global-styles';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { IClientContextState } from 'types/client-context';
import { FormSummarySection } from 'components/molecules/FormSummarySection';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { isLast4SSNIdentifierType } from 'utils/helpers/helpers';
import { TrackerEnum } from 'enums';

const FormReview = ({ formData }: IFormReviewProps): ReactElement => {
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const { formType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    formData &&
    clientContextDataState?.payload?.borrowerIdentifierType != null &&
    !isLast4SSNIdentifierType(clientContextDataState.payload.borrowerIdentifierType) &&
    'borrowerSSN' in formData
        ? delete formData.borrowerSSN
        : null;
    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, {
                detail: {
                    claimType: formType.type,
                    distributorName: clientContextDataState.payload.distributorName,
                    id: TrackerEnum.CLAIMSTEP_REVIEW
                }
            })
        );
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormReview:::::::::::::::::::::::::::::::::',
    //     '\n::formData::',
    //     formData,
    //     '\n::Object.entries(formData)::',
    //     Object.entries(formData),
    //     '\n::formType::',
    //     formType,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <>
            {ClaimTypesEnum.Jobloss === formType.type && (
                <SCDiv>
                    <FormSummarySection
                        title="Borrower Information"
                        id="id-1"
                        formData={formData}
                        Column1Row1={keysBorrowDetailColumn1}
                        Column1Row2={keysBorrowDetailColumn2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Job Loss Information"
                        id="id-2"
                        formData={formData}
                        Column1Row1={keysIUJobLossDetailsRow1Column1}
                        Column1Row2={keysIUJobLossDetailsRow1Column2}
                        Column2Row1={keysIUJobLossDetailsRow2Column1}
                        Column2Row2={keysIUJobLossDetailsRow2Column2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Employer Information"
                        id="id-3"
                        formData={formData}
                        Column1Row1={keysEmployerDetailColumn1}
                        Column1Row2={keysEmployerDetailColumn2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Comments"
                        id="id-4"
                        formData={formData}
                        Column1Row1={keysIUCommentsColumn1}
                        Column1Row2={keysIUCommentsColumn2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                </SCDiv>
            )}
            {ClaimTypesEnum.Disability === formType.type && (
                <SCDiv>
                    <FormSummarySection
                        title="Borrower Information"
                        id="id-1"
                        formData={formData}
                        Column1Row1={keysBorrowDetailColumn1}
                        Column1Row2={keysBorrowDetailColumn2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Disability Information"
                        id="id-2"
                        formData={formData}
                        Column1Row1={keysDisabilityDetailsRow1Column1}
                        Column1Row2={keysDisabilityDetailsRow1Column2}
                        Column2Row1={keysDisabilityDetailsRow2Column1}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Employer Information"
                        id="id-3"
                        formData={formData}
                        Column1Row1={keysEmployerDetailColumn1}
                        Column1Row2={keysEmployerDetailColumn2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Healthcare Information"
                        id="id-4"
                        formData={formData}
                        Column1Row1={keysDisabilityDetailsHealthcareRow1Column1}
                        Column1Row2={keysDisabilityDetailsHealthcareRow1Column2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                    <FormSummarySection
                        title="Comments"
                        id="id-5"
                        formData={formData}
                        Column1Row1={keysDisabilityCommentsColumn1}
                        Column1Row2={keysDisabilityCommentsColumn2}
                        claimType={formType.type}
                    ></FormSummarySection>
                    <SCHRElement />
                </SCDiv>
            )}
        </>
    );
};

export { FormReview };
