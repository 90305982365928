import styled from 'styled-components';
export const SCSvgWrapper = styled.div`
    width: 100%;
    height: auto;
`;
export const SCSvgRoot = styled.svg`
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
`;
