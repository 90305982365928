import * as React from 'react';
import { IChildrenProps } from 'types/children';
import { IAuthenticationData, IAuthenticationDataState } from 'types/authenticatation-session';
import { ClaimStateEnum } from 'enums/ClaimState';

export const AuthenticationSessionContext = React.createContext<IAuthenticationDataState | null>(null);
const AuthenticationSessionProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [authenticationData, setAuthenticationData] = React.useState<IAuthenticationData>({
        reviewState: ClaimStateEnum.ACTIVE,
        authenticatedRedirectPath: ''
    });
    const updateAuthenticationType = (authenticationData: IAuthenticationData): void => {
        const newAuthenticationData: IAuthenticationData = {
            roleLevel: authenticationData.roleLevel,
            reviewState: authenticationData.reviewState,
            isAuthenticated: authenticationData.isAuthenticated,
            authenticatedRedirectPath: authenticationData.authenticatedRedirectPath,
            userName: authenticationData.userName,
            distributorKey: authenticationData.distributorKey,
            loanServicerKey: authenticationData.loanServicerKey,
            signOut: authenticationData.signOut,
            groups: authenticationData.groups
        };
        setAuthenticationData(newAuthenticationData);
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::AuthenticationSessionContext:::::::::::::::::::::::::::::::::',
    //     '\n::authenticationData::',
    //     authenticationData,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <AuthenticationSessionContext.Provider value={{ authenticationData, updateAuthenticationType }}>
            {children}
        </AuthenticationSessionContext.Provider>
    );
};

export default AuthenticationSessionProvider;
