import styled from 'styled-components';
import { $deepBlue, SCHeadline } from 'styles/global-styles';

export const SCHeadlineDeepBlue = styled(SCHeadline)`
    color: ${$deepBlue};
`;
export const SCLeftColumnWrapper = styled.div`
    width: 67%;
    @media (max-width: 1024px) {
        width: 100%;
        padding: 0 32px 0 32px;
        margin: 0 0 80px 0;
    }
`;
export const SCRightColumnWrapper = styled.div`
    width: 33%;
    max-width: 420px;
    min-width: 420px;
    @media (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
    @media (max-width: 768px) {
        padding: 0 32px 0 32px;
    }
    @media (max-width: 425px) {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 0 8px 0 8px;
        //border: dashed 1px indianred;
    }
`;
