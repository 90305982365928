import React, { ReactElement } from 'react';
import { IArrowIconProps } from './types';
import { SCSvgArrow, SCSvgRectangle, SCSvgRoot, SCSvgWrapper } from './styles';

const ArrowIcon = ({ iconState, disabled }: IArrowIconProps): ReactElement => {
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ArrowIcon:::::::::::::::::::::::::::::::::',
    //     '\n::iconState::',
    //     iconState,
    //     '\n::disabled::',
    //     disabled,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCSvgWrapper
            data-testid={'arrow_icon'}
            aria-disabled={disabled ? 'true' : 'false'}
            role="img"
            aria-label="Arrow icon"
        >
            <SCSvgRoot
                aria-labelledby="Arrow icon"
                role="group"
                width="100%"
                height="100%"
                viewBox="0 0 55 55"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
            >
                <g transform="matrix(1,0,0,1,-8.0266,-7.0397)">
                    <SCSvgRectangle
                        animate={iconState}
                        data-testid={iconState ? 'RectangleIconActive' : 'RectangleIconInactive'}
                        x="8.027"
                        y="7.04"
                        width="54.879"
                        height="54.879"
                    />
                </g>
                <g transform="matrix(1.12975,0,0,1.29033,-12.8374,-17.7663)">
                    <SCSvgArrow
                        animate={iconState}
                        data-testid={iconState ? 'ArrowIconActive' : 'ArrowIconInactive'}
                        disabled={disabled}
                        d="M45.786,34.117L21.015,34.117L21.015,35.936L45.786,35.936L43.286,38.436L44.698,39.681L49.352,35.027L44.714,30.388L43.302,31.633L45.786,34.117Z"
                    />
                </g>
            </SCSvgRoot>
        </SCSvgWrapper>
    );
};

export { ArrowIcon };
