import styled from 'styled-components';
interface IBarOne {
    open: boolean;
}
interface IBarTwo {
    open: boolean;
}
interface IBarThree {
    open: boolean;
}
export const SCIconWrapper = styled.div`
    display: inline-block;
    cursor: pointer;
    height: 100%;
    width: 100%;
`;
export const SCBarOne = styled.div<IBarOne>`
    width: 20px;
    height: 2px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
    transform: ${(prop): string => (prop.open ? 'translate(0, 5px) rotate(-45deg);' : 'none')};
`;
export const SCBarTwo = styled.div<IBarTwo>`
    width: 25px;
    height: 2px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
    opacity: ${(prop): string => (prop.open ? '0' : '100')};
`;
export const SCBarThree = styled.div<IBarThree>`
    width: 20px;
    height: 2px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
    transform: ${(prop): string => (prop.open ? 'translate(0, -5px) rotate(45deg);' : 'none')};
`;
