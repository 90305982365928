import React from 'react';
import { SCCaretIcon, SCCaret, SCSvgRoot } from './styles';
import { ICaretIconProps } from './types';

const CaretIcon = ({ iconState, disabled }: ICaretIconProps): JSX.Element => {
    return (
        <SCCaretIcon
            data-testid={'caret_icon'}
            aria-disabled={disabled ? 'true' : 'false'}
            role="img"
            aria-label="Caret icon"
        >
            <SCSvgRoot
                aria-labelledby="Caret icon"
                role="group"
                width="100%"
                height="100%"
                viewBox="0 0 13 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlSpace="preserve"
            >
                <g transform="matrix(1,0,0,1,2.62389,2.24299)">
                    <SCCaret
                        animate={iconState}
                        disabled={disabled}
                        data-testid={iconState ? 'CaretIconActive' : 'CaretIconInactive'}
                        d="M6.439,10.447L2.851,6.501C2.851,6.501 6.439,2.553 6.439,2.553C6.81,2.144 6.78,1.511 6.372,1.14C5.963,0.769 5.33,0.799 4.959,1.207L0.148,6.501L4.959,11.793C5.331,12.201 5.964,12.231 6.372,11.86C6.78,11.489 6.81,10.856 6.439,10.447Z"
                    />
                </g>
            </SCSvgRoot>
        </SCCaretIcon>
    );
};

export { CaretIcon };
