export const labelMapIU = {
    claimNumber: 'Claim Number',
    borrowerIdentifierSsn: 'Last four digits of SSN',
    borrowerIdentifierPhone: 'Last four digits of phone number',
    peril: 'Peril',
    status: 'Status',
    borrowerName: 'Name',
    dateFiled: 'Date Filed',
    datePaid: 'Date Paid',
    distributorName: 'Distributor Name',
    amountPaid: 'Amount Paid',
    loanNumber: 'Loan Number'
};
export const errorMapIU = {
    borrowerIdentifierKeyError: 'Please enter last four digits.(ex. 1234)',
    claimNumberError: 'Please enter valid 10 digit claim number'
};
