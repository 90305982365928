import styled, { css, FlattenSimpleInterpolation, keyframes } from 'styled-components';
import { TSButtonLetterSpacingEnum, TSButtonSizeEnum, TSButtonWeightEnum } from 'enums/TSButtonVariants';

interface ITextWrapperArrow {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
interface IButtonIconWrapperArrow {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
interface IButtonIconWrapperPlus {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
interface IButtonIconWrapperBack {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
interface ITextWrapperBack {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
interface ITextWrapperMinus {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
interface IButtonWrapperSlide {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
    slideOverState?: boolean;
    slideOutState?: string;
}
interface IButtonWrapperUnderline {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
    letterSpacing?: TSButtonLetterSpacingEnum;
    slideOverState?: boolean;
}
interface ITextWrapper {
    weight?: TSButtonWeightEnum;
    size?: TSButtonSizeEnum;
}
export const SCButtonWrapperArrow = styled.div`
    display: inline-block;
    width: fit-content;
    border: rgba(117, 131, 161, 1) solid 1px;
    cursor: pointer;
    button {
        padding: 0;
        &:disabled div {
            color: rgba(100, 100, 104, 0.5);
        }
    }
`;
export const SCTextWrapperArrow = styled.div<ITextWrapperArrow>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 2px 16px 0 8px;
    color: rgba(25, 48, 98, 1);
    font-weight: ${(props): string =>
        props.weight === TSButtonWeightEnum.LIGHT
            ? `400`
            : props.weight === TSButtonWeightEnum.MEDIUM
            ? `500`
            : props.weight === TSButtonWeightEnum.BOLD
            ? `700`
            : `700`};
    font-size: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `14px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `15px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `16px`
            : `16px`};
    line-height: 20px;
    text-transform: none;
`;
export const SCSimpleButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 2px 16px 0 8px;
    color: rgba(25, 48, 98, 1);
    width: 100%;
    text-transform: none;
    cursor: pointer;
`;
export const SCButtonIconWrapperArrow = styled.div<IButtonIconWrapperArrow>`
    width: 100%;
    margin: 0 0 0 4px;
    max-width: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `29px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `45px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `56px`
            : `56px`};
    max-height: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `29px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `45px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `56px`
            : `56px`};
`;
export const SCButtonIconWrapperPlus = styled.div<IButtonIconWrapperPlus>`
    width: 100%;
    margin: 0 0 0 4px;
    max-width: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `29px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `45px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `56px`
            : `56px`};
    max-height: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `29px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `45px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `56px`
            : `56px`};
`;
export const SCButtonWrapperStepper = styled.div`
    display: inline-block;
    width: 63px;
    height: 64px;
    cursor: pointer;
    button {
        padding: 0;
        border-radius: 0;
        border: 0 solid transparent !important;
        min-width: 63px;
        max-width: 63px;
        min-height: 64px;
        &:disabled div {
            color: rgba(100, 100, 104, 0.5);
        }
    }
`;
export const SCButtonContentWrapperStepper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    height: 60px;
`;
export const SCButtonWrapperBack = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    min-height: 31px;
    cursor: pointer;
    button {
        padding: 0 0 0 0;
        &:disabled div {
            color: rgba(100, 100, 104, 0.5);
        }
    }
`;
export const SCButtonWrapperMinus = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    min-height: 31px;
    cursor: pointer;
    button {
        padding: 0 0 0 0;
        &:disabled div {
            color: rgba(100, 100, 104, 0.5);
        }
    }
`;
export const SCButtonIconWrapperBack = styled.div<IButtonIconWrapperBack>`
    width: 100%;
    height: 100%;
    max-width: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `10px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `12px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `14px`
            : `14px`};
    max-height: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `14px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `16px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `20px`
            : `20px`};
    padding-top: 1px;
    margin: 0 0 0 2px;
`;
export const SCButtonIconWrapperMinus = styled.div<IButtonIconWrapperBack>`
    width: 100%;
    height: 100%;
    max-width: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `10px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `12px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `14px`
            : `14px`};
    max-height: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `14px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `16px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `20px`
            : `20px`};
    padding-top: 1px;
    margin: 0 0 0 2px;
`;
export const SCTextWrapperBack = styled.div<ITextWrapperBack>`
    height: 100%;
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: rgba(25, 48, 98, 1);
    font-style: normal;
    font-weight: ${(props): string =>
        props.weight === TSButtonWeightEnum.LIGHT
            ? `400`
            : props.weight === TSButtonWeightEnum.MEDIUM
            ? `500`
            : props.weight === TSButtonWeightEnum.BOLD
            ? `700`
            : `700`};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.5px;
    text-transform: none;
`;
export const SCTextWrapperMinus = styled.div<ITextWrapperMinus>`
    height: 100%;
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: rgba(25, 48, 98, 1);
    font-style: normal;
    font-weight: ${(props): string =>
        props.weight === TSButtonWeightEnum.LIGHT
            ? `400`
            : props.weight === TSButtonWeightEnum.MEDIUM
            ? `500`
            : props.weight === TSButtonWeightEnum.BOLD
            ? `700`
            : `700`};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.5px;
    text-transform: none;
`;
const hoverOver = keyframes`
  from {
    background-image: linear-gradient(to right, rgba(250, 224, 126, 0) 50%, transparent 50%);
    background-position: 100%;
  }
  to {
    background-image: linear-gradient(to right, rgba(250, 224, 126, 1) 50%, transparent 50%);
    background-position: left;
  }
`;
const hoverOut = keyframes`
  from {
    background-image: linear-gradient(to right, rgba(250, 224, 126, 1) 50%, transparent 50%);
    background-position: left;
  }
  99% {
    background-image: linear-gradient(to right, rgba(250, 224, 126, 1) 50%, transparent 50%);
    background-position: -100%;
  }
  100% { 
    background-image: linear-gradient(to right, rgba(250, 224, 126, 0.0) 50%, transparent 50%);
    background-position: -100%;
  }
`;
export const SCButtonWrapperSlide = styled.div<IButtonWrapperSlide>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: rgba(25, 48, 98, 1);
    width: fit-content;
    cursor: pointer;
    min-height: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `32px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `33px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `34px`
            : `34px`};
    background-size: 200%;
    button {
        width: 100%;
        max-width: 100% !important;
        min-width: 100px;
        padding: 3px;
        div {
            font-size: ${(props): string =>
                props.size === TSButtonSizeEnum.SMALL
                    ? `14px`
                    : props.size === TSButtonSizeEnum.MEDIUM
                    ? `16px`
                    : props.size === TSButtonSizeEnum.LARGE
                    ? `18px`
                    : `18px`};
            font-weight: ${(props): string =>
                props.weight === TSButtonWeightEnum.LIGHT
                    ? `400`
                    : props.weight === TSButtonWeightEnum.MEDIUM
                    ? `500`
                    : props.weight === TSButtonWeightEnum.BOLD
                    ? `700`
                    : `700`};
            width: 100%;
            font-style: normal;
            line-height: 24px;
            letter-spacing: ${(props): string => (props.size === TSButtonSizeEnum.SMALL ? `1px` : `2px`)};
        }
        &:hover {
            background: transparent;
        }
    }
    animation: ${(props): FlattenSimpleInterpolation => {
        return props.slideOverState === true
            ? css`
                  ${hoverOver} 0.35s ease-out both
              `
            : css`
                  ${hoverOut} 0.45s ease-out both;
              `;
    }};
`;
export const SCTextWrapperSlide = styled.div`
    height: 100%;
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: rgba(25, 48, 98, 1);
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1.5px;
    text-transform: none;
`;
const underlineOver = keyframes`
  from {
    background-color: rgba(250, 224, 126, 0);
    padding: 0 0 0 0;
    margin: 0 3px 0 3px;
    border-bottom: 2px solid rgba(96, 140, 255, 1);
  }
  to {
    background-color: rgba(250, 224, 126, 1);
    padding: 0 3px 0 3px;
    margin: 0 0 0 0;
    border-bottom: 2px solid rgba(96, 140, 255, 0);
  }
`;
const underlineOut = keyframes`
  from {
    background-color: rgba(250, 224, 126, 1);
    padding: 0 3px 0 3px;
    margin: 0 0 0 0;
  }
  to {
    background-color: rgba(250, 224, 126, 0);
    padding: 0 0 0 0;
    margin: 0 3px 0 3px;
`;
export const SCButtonWrapperUnderline = styled.div<IButtonWrapperUnderline>`
    color: rgba(35, 31, 32, 1);
    display: inline-block;
    width: fit-content;
    min-height: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `24px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `29px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `34px`
            : `34px`};
    background-color: rgba(250, 224, 126, 0);
    border-bottom: 2px solid rgba(96, 140, 255, 1);
    cursor: pointer;
    button {
        width: 100%;
        padding-bottom: 0;
        div {
            font-size: ${(props): string =>
                props.size === TSButtonSizeEnum.SMALL
                    ? `14px`
                    : props.size === TSButtonSizeEnum.MEDIUM
                    ? `16px`
                    : props.size === TSButtonSizeEnum.LARGE
                    ? `18px`
                    : `18px`};
            font-weight: ${(props): string =>
                props.weight === TSButtonWeightEnum.LIGHT
                    ? `400`
                    : props.weight === TSButtonWeightEnum.MEDIUM
                    ? `500`
                    : props.weight === TSButtonWeightEnum.BOLD
                    ? `700`
                    : `700`};
            width: 100%;
            font-style: normal;
            line-height: 24px;
            letter-spacing: ${(props): string =>
                props.letterSpacing === TSButtonLetterSpacingEnum.NARROW
                    ? `0px`
                    : props.letterSpacing === TSButtonLetterSpacingEnum.WIDE
                    ? `2px`
                    : `2px`};
        }
    }
    animation: ${(props): FlattenSimpleInterpolation => {
        return props.slideOverState === true
            ? css`
                  ${underlineOver} 0.35s ease-out both
              `
            : css`
                  ${underlineOut} 0.45s ease-out both;
              `;
    }};
`;
export const SCButtonWrapper = styled.div`
    display: inline-block;
    width: fit-content;
    cursor: pointer;
    button {
        padding: 0 5px 0 0;
        border: 1px solid transparent;
        &:hover {
            border: 1px solid transparent !important;
            background-color: transparent;
        }
    }
`;

export const SCTextWrapper = styled.div<ITextWrapper>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: rgba(25, 48, 98, 1);
    font-weight: ${(props): string =>
        props.weight === TSButtonWeightEnum.LIGHT
            ? `400`
            : props.weight === TSButtonWeightEnum.MEDIUM
            ? `500`
            : props.weight === TSButtonWeightEnum.BOLD
            ? `700`
            : `700`};
    font-size: ${(props): string =>
        props.size === TSButtonSizeEnum.SMALL
            ? `14px`
            : props.size === TSButtonSizeEnum.MEDIUM
            ? `16px`
            : props.size === TSButtonSizeEnum.LARGE
            ? `18px`
            : `18px`};
    line-height: 20px;
    text-transform: none;
`;
