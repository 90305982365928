import * as React from 'react';
import { IFormDisabilityData, IFormDisabilityState } from 'types/form';
import { IChildrenProps } from 'types/children';

export const FormDisabilityStateContext = React.createContext<IFormDisabilityState | null>(null);
const FormDisabilityStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialisedState: IFormDisabilityData = {
        borrowerNameFirst: undefined,
        borrowerNameLast: undefined,
        borrowerMailingAddress: undefined,
        borrowerMailingCity: undefined,
        borrowerMailingState: undefined,
        borrowerMailingZip: undefined,
        borrowerEmail: undefined,
        borrowerPhone: undefined,
        borrowerSSN: undefined,
        borrowerDateOfBirth: undefined,
        borrowerComments: undefined,
        employerName: undefined,
        employerEmail: undefined,
        employerContactNumber: undefined,
        employerAddress: undefined,
        employerCity: undefined,
        employerState: undefined,
        employerZip: undefined,
        disabilityHealthcareName: undefined,
        disabilityHealthcareHospitalName: undefined,
        disabilityHealthcareContactNumber: undefined,
        disabilityDateOfIncident: undefined,
        disabilityExplanationOf: undefined,
        disabilityTypeOfSurgery: undefined,
        disabilityDateLastWorked: undefined,
        disabilityKnowWhenExpectedWorking: undefined,
        disabilityDateExpectedWorking: undefined,
        disabilityHealthcareAddress: undefined,
        disabilityHealthcareCity: undefined,
        disabilityHealthcareState: undefined,
        disabilityHealthcareZip: undefined,
        disabilityFirstPhysicianDate: undefined,
        disabilityJobDuties: undefined,
        disabilityFiledOnBehalfOf: undefined,
        submitterCompanyName: undefined,
        submitterNameFirst: undefined,
        submitterNameLast: undefined,
        submitterEmail: undefined,
        submitterPhone: undefined,
        eDeliveryConfirmation: undefined
    };
    const [formDisabilityState, setDisabilityFormState] = React.useState<IFormDisabilityData>(initialisedState);

    const saveFormDisabilityState = (formData: IFormDisabilityData): void => {
        const newFormData: IFormDisabilityData = {
            borrowerNameFirst: formData.borrowerNameFirst,
            borrowerNameLast: formData.borrowerNameLast,
            borrowerMailingAddress: formData.borrowerMailingAddress,
            borrowerMailingCity: formData.borrowerMailingCity,
            borrowerMailingState: formData.borrowerMailingState,
            borrowerMailingZip: formData.borrowerMailingZip,
            borrowerEmail: formData.borrowerEmail,
            borrowerPhone: formData.borrowerPhone,
            borrowerSSN: formData.borrowerSSN,
            borrowerDateOfBirth: formData.borrowerDateOfBirth,
            borrowerComments: formData.borrowerComments,
            disabilityDateOfIncident: formData.disabilityDateOfIncident,
            disabilityExplanationOf: formData.disabilityExplanationOf,
            disabilityTypeOfSurgery: formData.disabilityTypeOfSurgery,
            disabilityKnowWhenExpectedWorking: formData.disabilityKnowWhenExpectedWorking,
            disabilityDateLastWorked: formData.disabilityDateLastWorked,
            disabilityDateExpectedWorking: formData.disabilityDateExpectedWorking,
            disabilityFiledOnBehalfOf: formData.disabilityFiledOnBehalfOf,
            employerName: formData.employerName,
            employerEmail: formData.employerEmail,
            employerContactNumber: formData.employerContactNumber,
            employerAddress: formData.employerAddress,
            employerCity: formData.employerCity,
            employerState: formData.employerState,
            employerZip: formData.employerZip,
            disabilityHealthcareName: formData.disabilityHealthcareName,
            disabilityHealthcareHospitalName: formData.disabilityHealthcareHospitalName,
            disabilityHealthcareContactNumber: formData.disabilityHealthcareContactNumber,
            disabilityHealthcareAddress: formData.disabilityHealthcareAddress,
            disabilityHealthcareCity: formData.disabilityHealthcareCity,
            disabilityHealthcareState: formData.disabilityHealthcareState,
            disabilityHealthcareZip: formData.disabilityHealthcareZip,
            disabilityFirstPhysicianDate: formData.disabilityFirstPhysicianDate,
            disabilityJobDuties: formData.disabilityJobDuties,
            eDeliveryConfirmation: formData.eDeliveryConfirmation,
            submitterCompanyName: formData.submitterCompanyName,
            submitterNameFirst: formData.submitterNameFirst,
            submitterNameLast: formData.submitterNameLast,
            submitterEmail: formData.submitterEmail,
            submitterPhone: formData.submitterPhone
        };
        setDisabilityFormState(newFormData);
    };
    const reinitialiseFormDisabilityState = (): void => {
        const newFormData: IFormDisabilityData = initialisedState;
        setDisabilityFormState(newFormData);
    };

    return (
        <FormDisabilityStateContext.Provider
            value={{ formDisabilityState, saveFormDisabilityState, reinitialiseFormDisabilityState }}
        >
            {children}
        </FormDisabilityStateContext.Provider>
    );
};

export default FormDisabilityStateProvider;
