export enum MaxFieldLengths {
    FIRST_NAME = 50,
    LAST_NAME = 50,
    EMAIL = 150,
    SSN = 4,
    CITY = 20,
    ADDRESS = 100,
    JOBLOSS_OCCUPATION = 150,
    COMPANY_NAME = 150,
    DISABILITY_TYPE_OF_SURGERY = 150,
    DISABILITY_HEALTHCARE_NAME = 150,
    DISABILITY_HEALTHCARE_HOSPITAL_NAME = 150,
    NOTES_CLAIM_ATTRIBUTE = 4000,
    GENERAL_MAX = 2000
}
