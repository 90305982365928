import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { XIcon } from 'components/atoms/XIcon';
import { Button } from '@mui/material';
import {
    SCInnerRootWrapper,
    SCAlertContentWrapper,
    SCAlertOuterContentWrapper,
    SCTextWrapper,
    SCCTAButtonWrapper,
    SCOuterRootWrapper
} from './styles';
import { IAlertProps } from './types';
import { AlertCTATypeEnum } from 'enums/AlertCTATypes';

const Alert = ({ alert, handleDismiss, styleMessageLeftPadding }: IAlertProps): ReactElement => {
    const history = useHistory();
    return (
        <>
            {alert?.active && (
                <SCOuterRootWrapper>
                    <SCInnerRootWrapper alertType={alert.type}>
                        <SCAlertContentWrapper paddingLeft={styleMessageLeftPadding}>
                            {alert.message}
                            {alert.callToAction && (
                                <>
                                    {(alert.callToActionType === AlertCTATypeEnum.EMAIL && (
                                        <SCCTAButtonWrapper>
                                            <Button
                                                id="cta-button"
                                                aria-label="alert email button"
                                                onClick={(): void => {
                                                    window.location.href = 'mailto:paymentguard@trustage.com';
                                                }}
                                                variant="text"
                                                size="small"
                                            >
                                                Contact Us
                                            </Button>
                                        </SCCTAButtonWrapper>
                                    )) || (
                                        <SCCTAButtonWrapper>
                                            <Button
                                                id="cta-button"
                                                aria-label="alert action button"
                                                onClick={(): void => {
                                                    if (handleDismiss) {
                                                        handleDismiss();
                                                    }
                                                    history.push(alert.callToActionURL as LocationDescriptor<unknown>);
                                                }}
                                                variant="text"
                                                size="small"
                                            >
                                                <SCTextWrapper>{alert.callToAction}</SCTextWrapper>
                                            </Button>
                                        </SCCTAButtonWrapper>
                                    )}
                                </>
                            )}
                        </SCAlertContentWrapper>
                        <SCAlertOuterContentWrapper>
                            {alert.dismissible && (
                                <Button
                                    id="cta-button"
                                    aria-label="alert close button"
                                    onClick={(): void => {
                                        if (handleDismiss) {
                                            handleDismiss();
                                        }
                                    }}
                                    variant="text"
                                    size="small"
                                    startIcon={<XIcon size={'sm'} />}
                                >
                                    <SCTextWrapper>Close</SCTextWrapper>
                                </Button>
                            )}
                        </SCAlertOuterContentWrapper>
                    </SCInnerRootWrapper>
                </SCOuterRootWrapper>
            )}
        </>
    );
};

export { Alert };
