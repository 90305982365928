import axios from 'axios';
import { oktaAuthClient } from 'OktaAuth';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    (config) => {
        const token: string | undefined = oktaAuthClient.getAccessToken();
        token && (config.headers['Authorization'] = 'Bearer ' + token);
        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;

export const slotConfig = {
    params: {
        'x-ms-routing-name': window.location.origin.includes('-staging') ? 'staging' : 'self'
    }
};
