import * as React from 'react';
import { useState } from 'react';
import { TimelineState } from 'enums/TimeLine';
import { IChildrenProps } from 'types/children';
import { IFormProgressData, IFormJoblossProgressState } from 'types/form-progress';

export const FormJoblossProgressStateContext = React.createContext<IFormJoblossProgressState | null>(null);
const FormJoblossProgressStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = [
        {
            id: 'id-1',
            label: 'Personal Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-2',
            label: 'Claim details',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-3',
            label: 'Employer Details',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-4',
            label: 'Comments (optional)',
            status: TimelineState.INCOMPLETE
        }
    ];
    const [formJoblossProgressState, setFormJoblossProgressState] = React.useState<IFormProgressData[]>(initialState);
    const updateFormJoblossProgressState = (id: string, status: TimelineState): void => {
        formJoblossProgressState.filter((formData) => {
            if (formData.id === id) {
                formData.status = status;
                setFormJoblossProgressState([...formJoblossProgressState]);
            }
        });
    };
    const resetFormJoblossProgressState = (): void => {
        setFormJoblossProgressState(initialState);
    };
    const [formJoblossProgressCompletionStatus, setFormProgressCompletionStatus] = useState(false);
    const updateFormJoblossProgressCompletionStatus = (status: boolean): void => {
        setFormProgressCompletionStatus(status);
    };
    const updateAllFormJoblossProgressStatusComplete = (): void => {
        formJoblossProgressState.map((obj) => {
            obj.status = TimelineState.COMPLETE;
            setFormJoblossProgressState([...formJoblossProgressState]);
        });
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormJoblossProgressStateProvider:::::::::::::::::::::::::::::::::',
    //     '\n::formJoblossProgressState::',
    //     formJoblossProgressState,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <FormJoblossProgressStateContext.Provider
            value={{
                formJoblossProgressState,
                updateFormJoblossProgressState,
                resetFormJoblossProgressState,
                formJoblossProgressCompletionStatus,
                updateFormJoblossProgressCompletionStatus,
                updateAllFormJoblossProgressStatusComplete
            }}
        >
            {children}
        </FormJoblossProgressStateContext.Provider>
    );
};

export default FormJoblossProgressStateProvider;
