import * as React from 'react';
import { IFormNavigationType, IFormNavigationTypeState } from 'types/form-navigation-context';
import { IChildrenProps } from 'types/children';
import { ClaimStateEnum } from 'enums/ClaimState';

export const FormNavigationContext = React.createContext<IFormNavigationTypeState | null>(null);
const FormNavigationProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [formNavigationState, setFormNavigationState] = React.useState<IFormNavigationType>({
        type: undefined,
        reviewState: ClaimStateEnum.ACTIVE
    });
    const saveFormNavigationState = (formData: IFormNavigationType): void => {
        const newFormNavigationState: IFormNavigationType = {
            type: formData.type,
            reviewState: formData.reviewState
        };
        setFormNavigationState(newFormNavigationState);
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormNavigationProvider:::::::::::::::::::::::::::::::::',
    //     '\n::formNavigationState::',
    //     formNavigationState,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <FormNavigationContext.Provider value={{ formNavigationState, saveFormNavigationState }}>
            {children}
        </FormNavigationContext.Provider>
    );
};

export default FormNavigationProvider;
