import styled from 'styled-components';
import { SCHeroHeader } from '../../../styles/global-styles';

export const SCHeroHeaderCustom = styled(SCHeroHeader)`
    padding-bottom: 0px;
`;

export const SCPdfViewerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    padding: 16px 0 0 0;
`;
