import api from 'utils/api';
import { slotConfig } from 'utils/api';

export const ClaimLookupService = {
    postClaim: (body: FormData): void => {
        api.post(`${process.env.REACT_APP_CLAIM_LOOKUP_API_URL}`, body, slotConfig);
    },

    postClaimWithResponse: (body: FormData): any => {
        return api.post(`${process.env.REACT_APP_CLAIM_LOOKUP_API_URL}`, body, slotConfig);
    }
};
