import React, { ReactElement } from 'react';
import { SCSVGBar, SCSvgRoot, SCSvgWrapper } from './styles';
import { IMinusIconProps } from './types';

const MinusIcon = ({ disabled, iconState }: IMinusIconProps): ReactElement => {
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::PlusIcon:::::::::::::::::::::::::::::::::',
    //     '\n::iconState::',
    //     iconState,
    //     '\n::disabled::',
    //     disabled,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCSvgWrapper
            data-testid={'plus_icon'}
            aria-disabled={disabled ? 'true' : 'false'}
            role="img"
            aria-label="Plus icon"
        >
            <SCSvgRoot
                aria-labelledby="Plus icon"
                role="group"
                width="100%"
                height="100%"
                viewBox="0 0 13 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
            >
                <g transform="matrix(0.784715,0,0,0.982143,5.13545,0.0006875)">
                    <SCSVGBar
                        animate={iconState}
                        data-testid={iconState ? 'PlusIconActive' : 'PlusIconInactive'}
                        disabled={disabled}
                        x="12.84"
                        y="26.919"
                        width="31.32"
                        height="2.16"
                        fill={'rgb(25,48,98)'}
                    />
                </g>
                <g transform="matrix(1,0,0,1,0.309103,0.132642)">
                    <g id="Layer1">
                        {/*<rect x="0.687" y="7.574" width="11.008" height="1.997" />*/}

                        <SCSVGBar
                            animate={iconState}
                            data-testid={iconState ? 'PlusIconActive' : 'PlusIconInactive'}
                            disabled={disabled}
                            x="0.687"
                            y="7.574"
                            width="11.008"
                            height="1.997"
                            fill={'rgb(25,48,98)'}
                        />
                    </g>
                </g>
            </SCSvgRoot>
        </SCSvgWrapper>
    );
};

export { MinusIcon };
