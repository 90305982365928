import styled from 'styled-components';
import { $brightBlue, onLoad } from 'styles/global-styles';

export const SCDiv = styled.div``;
export const SCCheckBoxWrapper = styled.div`
    li {
        border: none;
        border-radius: 5px;
    }
`;
export const SCButtonRow = styled.div`
    float: right;
    @media (max-width: 1440px) {
        padding-right: 32px;
    }
`;
export const SCLoaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 60vh;
    animation: ${onLoad} 0.55s ease-out;
`;
export const SCDisclaimerContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media (max-width: 1024px) {
        height: 100%;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
`;
export const SCFraudDisclaimer = styled.div`
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 36px;
    letter-spacing: 0.4px;
    line-height: 21px;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    a,
    button {
        cursor: pointer !important;
    }
    ul {
        padding: 8px;
        list-style-type: disc;
    }
    li {
        margin-left: 16px;
    }
    b {
        font-weight: 600;
    }
`;
export const SCDisclaimerContent = styled.div`
    width: 100%;
    font-size: 12px;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    @media (max-width: 1440px) {
        width: 100%;
    }
`;
export const SCClaimSubmitDisclaimer = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.4px;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    a,
    button {
        cursor: pointer !important;
    }
    ul {
        padding: 8px;
        list-style-type: disc;
        font-weight: 400;
    }
    li {
        margin-left: 16px;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin: 0 0 16px 0;
    }
`;
export const SCConsentDisclaimer = styled.div`
    font-size: 10px;
    font-weight: lighter;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    a,
    button {
        cursor: pointer !important;
    }
    ul {
        padding: 8px;
        list-style-type: circle;
    }
    li {
        margin-left: 16px;
    }
    b {
        font-weight: 600 !important;
    }
`;
