import styled from 'styled-components';

interface ISvgArrow {
    animate: boolean;
    disabled: boolean;
}
interface ISvgRectangle {
    animate: boolean;
}
export const SCSvgWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #f5f6f7;
`;
export const SCSvgRoot = styled.svg`
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
`;
export const SCSvgArrow = styled.path<ISvgArrow>`
    fill: ${(props): string =>
        props.animate === true
            ? props.disabled
                ? `rgba(255, 255, 255, 0.5)`
                : `rgba(255, 255, 255, 1)`
            : props.disabled
            ? `rgba(25, 48, 98, 0.5)`
            : `rgba(25, 48, 98, 1)`};
    transition: fill 0.25s ease-out;
`;
export const SCSvgRectangle = styled.rect<ISvgRectangle>`
    fill: ${(props): string => (props.animate === true ? `rgba(8,124,171, 1)` : `rgba(250, 224, 126, 1)`)};
    transition: fill 0.35s ease-in-out;
`;
