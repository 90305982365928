import React from 'react';
import { SCSvgRectangle, SCSvgRoot, SCSvgWrapper, SCTextWrapperStepper } from './styles';
import { IStatusIconProps } from './types';
import { TimelineState } from 'enums';

const StatusIcon = ({ children, iconState, stepValue, status }: IStatusIconProps): JSX.Element => {
    const stepDisplayValue = stepValue ? ('0' + stepValue).slice(-2) : '';
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::StatusIcon:::::::::::::::::::::::::::::::::',
    //     '\n::children::',
    //     children,
    //     '\n::iconState::',
    //     iconState,
    //     '\n::stepDisplayValue::',
    //     stepDisplayValue,
    //     '\n::iconState::',
    //     iconState,
    //     '\n::status::',
    //     status,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCSvgWrapper
            data-testid={'status_icon'}
            aria-disabled={status === TimelineState.INCOMPLETE ? 'true' : 'false'}
            role="img"
            aria-label="status icon"
        >
            <SCTextWrapperStepper
                animate={iconState}
                status={status}
                data-testid={iconState ? 'StatusIconWrapperActive' : 'StatusIconWrapperInactive'}
            >
                {stepDisplayValue}
            </SCTextWrapperStepper>
            <SCSvgRoot
                aria-labelledby="Status Icon"
                role="group"
                width="100%"
                height="100%"
                viewBox="0 0 63 4"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
            >
                <SCSvgRectangle
                    animate={iconState}
                    status={status}
                    data-testid={iconState ? 'StatusIconRectangleActive' : 'StatusIconRectangleInactive'}
                    x="0"
                    y="0"
                    width="63"
                    height="4"
                />
            </SCSvgRoot>
        </SCSvgWrapper>
    );
};

export { StatusIcon };
