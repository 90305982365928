import styled, { keyframes } from 'styled-components';
import { $brightBlue, $deepBlue, $successGreenBackground, $warningRedBackground } from 'styles/global-styles';
import { AlertTypeEnum } from 'enums/AlertTypes';

export const onOpen = keyframes`
  from {
    opacity: 0;
    max-height:0;
  }
  to {
    opacity: 1;
    max-height: 70px;
  }
`;
interface IInnerRootWrapper {
    alertType: AlertTypeEnum;
}
interface IAlertContentWrapper {
    paddingLeft?: string;
}
export const SCOuterRootWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
export const SCInnerRootWrapper = styled.div<IInnerRootWrapper>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: ${(props): string => {
        return props.alertType === AlertTypeEnum.SUCCESS
            ? `${props.theme.tsTheme.colors.functional.successGreenBackground}`
            : `${props.theme.tsTheme.colors.functional.warningRedBackground}`;
    }};
    animation: ${onOpen} 0.25s ease-out;
    @media (max-width: 768px) {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        height: 100px;
    }
`;
export const SCAlertContentWrapper = styled.div<IAlertContentWrapper>`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1376px;
    padding-left: ${(props): string => {
        return props?.paddingLeft != null ? `${props.paddingLeft}` : `12%`;
    }};
    padding-right: 10px;
    @media (max-width: 768px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
`;
export const SCAlertOuterContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: fit-content;
    padding: 0 10px 0 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
`;
export const SCTextWrapper = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: rgba(25, 48, 98, 1);
    text-transform: none;
`;
export const SCCTAButtonWrapper = styled(SCTextWrapper)`
    #cta-button {
        color: ${$deepBlue};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.4px;
        text-transform: none;
        margin: -3px 4px 0 5px;
        padding: 5px 0 0 0;
        border: transparent;
        border-radius: 0;
        border-bottom: 1px solid ${$deepBlue};
        animation: all 0.55s ease-out;
        :hover {
            border: transparent !important;
            border-bottom: 1px solid ${$brightBlue};
        }
    }
`;
