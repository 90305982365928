import * as React from 'react';
import { IOverlayType, IOverlayTypeState } from 'types/overlay-context';
import { IChildrenProps } from 'types/children';

export const OverlayTypeContext = React.createContext<IOverlayTypeState | null>(null);
const OverlayTypeProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [overlayType, setOverlayType] = React.useState<IOverlayType>({
        type: undefined,
        reviewState: false
    });
    const saveOverlayType = (formData: IOverlayType): void => {
        const newTakeoverState: IOverlayType = {
            type: formData.type,
            reviewState: formData.reviewState
        };
        setOverlayType(newTakeoverState);
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::OverlayTypeContext:::::::::::::::::::::::::::::::::',
    //     '\n::overlayType::',
    //     overlayType,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );
    return (
        <OverlayTypeContext.Provider value={{ overlayType, saveOverlayType }}>{children}</OverlayTypeContext.Provider>
    );
};

export default OverlayTypeProvider;
