import { IClientContextApiResponse } from 'types/client-context';
import api from 'utils/api';
import { slotConfig } from 'utils/api';
import { AxiosResponse } from 'axios';

export const ClientContextsService = async (
    distributorKey: string,
    loanServicerKey?: string
): Promise<IClientContextApiResponse | unknown> => {
    try {
        const config = structuredClone(slotConfig);
        config.params['distributorKey'] = distributorKey;
        config.params['loanServicerKey'] = loanServicerKey;
        const response = await api.get<IClientContextApiResponse, AxiosResponse<IClientContextApiResponse>>(
            'clientcontextsV2',
            config
        );
        return response.data;
    } catch (error) {
        console.error('::Get ClientContextsService.getClientContexts API Error::', error);
        return;
    }
};
