import * as React from 'react';
import { IFormIUData, IFormIUState } from 'types/form';
import { IChildrenProps } from 'types/children';

export const FormIUStateContext = React.createContext<IFormIUState | null>(null);
const FormIUStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialisedState: IFormIUData = {
        borrowerNameFirst: undefined,
        borrowerNameLast: undefined,
        borrowerMailingAddress: undefined,
        borrowerMailingCity: undefined,
        borrowerMailingState: undefined,
        borrowerMailingZip: undefined,
        borrowerEmail: undefined,
        borrowerPhone: undefined,
        borrowerSSN: undefined,
        borrowerDateOfBirth: undefined,
        borrowerComments: undefined,
        iuDateOfJobLoss: undefined,
        employerName: undefined,
        employerAddress: undefined,
        employerCity: undefined,
        employerState: undefined,
        employerZip: undefined,
        employerEmail: undefined,
        employerContactNumber: undefined,
        iuDateOfJobLossNotification: undefined,
        iuJobLossReason: undefined,
        iuDateLastDayWorked: undefined,
        iuIsLayoffTypicalAtThisTime: undefined,
        iuKnowledgeOfLayoffWhenHired: undefined,
        iuOccupationAtTimeOfSeparation: undefined,
        iuQualifyForStateUnemployment: undefined,
        iuRejectionStateBenefitsReason: undefined,
        iuSelfEmployed: undefined,
        eDeliveryConfirmation: undefined,
        iuFiledOnBehalfOf: undefined,
        submitterCompanyName: undefined,
        submitterNameFirst: undefined,
        submitterNameLast: undefined,
        submitterEmail: undefined,
        submitterPhone: undefined
    };
    const [formIUState, setFormIUState] = React.useState<IFormIUData>(initialisedState);

    const saveFormIUState = (formData: IFormIUData): void => {
        const newFormData: IFormIUData = {
            borrowerNameFirst: formData.borrowerNameFirst,
            borrowerNameLast: formData.borrowerNameLast,
            borrowerMailingAddress: formData.borrowerMailingAddress,
            borrowerMailingCity: formData.borrowerMailingCity,
            borrowerMailingState: formData.borrowerMailingState,
            borrowerMailingZip: formData.borrowerMailingZip,
            borrowerEmail: formData.borrowerEmail,
            borrowerPhone: formData.borrowerPhone,
            borrowerSSN: formData.borrowerSSN,
            borrowerDateOfBirth: formData.borrowerDateOfBirth,
            borrowerComments: formData.borrowerComments,
            employerContactNumber: formData.employerContactNumber,
            iuDateOfJobLoss: formData.iuDateOfJobLoss,
            employerName: formData.employerName,
            employerEmail: formData.employerEmail,
            employerAddress: formData.employerAddress,
            employerCity: formData.employerCity,
            employerState: formData.employerState,
            employerZip: formData.employerZip,
            iuDateOfJobLossNotification: formData.iuDateOfJobLossNotification,
            iuJobLossReason: formData.iuJobLossReason,
            iuDateLastDayWorked: formData.iuDateLastDayWorked,
            iuIsLayoffTypicalAtThisTime: formData.iuIsLayoffTypicalAtThisTime,
            iuKnowledgeOfLayoffWhenHired: formData.iuKnowledgeOfLayoffWhenHired,
            iuOccupationAtTimeOfSeparation: formData.iuOccupationAtTimeOfSeparation,
            iuQualifyForStateUnemployment: formData.iuQualifyForStateUnemployment,
            iuRejectionStateBenefitsReason: formData.iuRejectionStateBenefitsReason,
            iuSelfEmployed: formData.iuSelfEmployed,
            eDeliveryConfirmation: formData.eDeliveryConfirmation,
            iuFiledOnBehalfOf: formData.iuFiledOnBehalfOf,
            submitterCompanyName: formData.submitterCompanyName,
            submitterNameFirst: formData.submitterNameFirst,
            submitterNameLast: formData.submitterNameLast,
            submitterEmail: formData.submitterEmail,
            submitterPhone: formData.submitterPhone
        };
        setFormIUState(newFormData);
    };
    const reinitialiseFormIUState = (): void => {
        setFormIUState(initialisedState);
    };

    return (
        <FormIUStateContext.Provider value={{ formIUState, saveFormIUState, reinitialiseFormIUState }}>
            {children}
        </FormIUStateContext.Provider>
    );
};

export default FormIUStateProvider;
