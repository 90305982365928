import styled from 'styled-components';
import { TableRow } from '@mui/material';
import { TableBackgroundEnum } from 'enums/StyleTypes';

interface ISCTableRow {
    $backgroundColor?: TableBackgroundEnum;
}
export const SCTableOverrides = styled.div`
    border: transparent;
    .MuiFormControl-fullWidth {
        font-size: 20px;
    }
`;
export const SCTablePagination = styled.div`
    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
        white-space: nowrap;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 4px;
    }
    p {
        white-space: nowrap;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }
`;
export const SCTableRow = styled(TableRow)<ISCTableRow>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    max-width: 1383px;
`;
export const SCTableColumn = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    max-width: 1383px;
`;
export const SCTableTextWrapper = styled.div`
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
`;
export const SCTableButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 56px;
    height: 30px;
`;
export const SCTableButtonIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    max-width: 22px;
    max-height: 22px;
    margin: 6px 0 6px 0;
`;
