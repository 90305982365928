export interface INavigationProps {
    children?: JSX.Element | JSX.Element[];
    isAuthenticated?: boolean | undefined;
}

export enum NavigationSelected {
    HOME = 'home',
    CLAIM = 'claim',
    CLAIM_LOOKUP = 'claimLookup',
    FAQS = 'faqs',
    CONTACT = 'contact',
    PRIVACY = 'privacy',
    ADMIN = 'admin',
    NONE = ''
}
