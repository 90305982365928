import styled from 'styled-components';
import { SCEyebrow, SCThreeColumnHeader, SCThreeColumnContentWrapper } from 'styles/global-styles';

export const SCAdminContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    @media (max-width: 768px) {
        padding: 0 32px 0 32px;
    }
`;
export const SCAdminContentEyebrow = styled(SCEyebrow)`
    margin: 0 0 30px 0;
    @media (max-width: 1440px) {
        padding: 16px 16px 0 0;
    }
    @media (max-width: 1024px) {
        padding: 16px 8px 0 0;
    }
`;

export const SCAdminBoxHeading = styled(SCThreeColumnHeader)`
    padding: 0;
    text-transform: none;
`;

export const SCThreeColumnAdminContentWrapper = styled(SCThreeColumnContentWrapper)`
    margin: 36px 0 0 0;
    gap: 16px;
    @media (max-width: 1024px) {
        padding: 0;
        margin: 24px 0 0 0;
    }
    @media (max-width: 768px) {
        padding: 0;
        margin: 24px 0 0 0;
    }
`;
