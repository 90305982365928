import styled from 'styled-components';
import { TSButton } from 'components/atoms/TSButton';
import { $deepBlue, $digitalBlack, $white } from 'styles/global-styles';
export const SCQuickLinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    background: ${$white};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
`;
export const SCQuickLinksContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    height: auto;
    padding: 40px 35px 40px;
    @media (max-width: 1024px) {
        padding: 64px;
    }
    @media (max-width: 768px) {
        padding: 64px 0 0 0;
    }
`;

export const SCQuickLinksTopBanner = styled.div`
    width: 100%;
    height: 8px;
    background: ${$digitalBlack};
`;

export const SCQuickLinksTitle = styled.div`
    line-height: 40px;
    font-size: 33px;
    font-weight: 700;
    text-align: left;
    color: ${$deepBlue};
    width: 100%;
    margin: 0 0 30px 0;
    @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 36px;
    }
`;

export const SCLinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: auto;
    height: auto;
`;

export const SCLinksButton = styled(TSButton)`
    color: ${$digitalBlack};
`;
export const SCLinksText = styled.div`
    width: auto;
    height: auto;
    padding-bottom: 36px;
    button {
        min-width: 100%;
        padding: 0;
        div {
            color: ${$digitalBlack};
        }
    }

    //border: #ff8000 dashed 1px;
`;
