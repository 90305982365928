import { useMemo } from 'react';

export const useQuery = (): URLSearchParams => {
    const search = window.location.search.substring(1);
    return useMemo(() => new URLSearchParams(search), [search]);
};
export const getQueryVariables = (): undefined | object => {
    const result = window.location.search.substring(1);
    const queryVariables = result.split('&');
    if (queryVariables.join('').length > 0) {
        const obj = {};
        for (const x of queryVariables) {
            const splitArray = x.split('=');
            obj[splitArray[0]] = splitArray[1];
        }
        return obj;
    } else {
        return undefined;
    }
};
