import styled from 'styled-components';
import { TimelineState } from 'enums';

interface ISvgWrapper {
    animate?: TimelineState;
    size?: 'sm' | 'md' | 'lg';
}
export const SCSvgWrapper = styled.div<ISvgWrapper>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: ${(props): string => {
        return props.size === 'lg' ? `29px` : props.size === 'md' ? `20px` : props.size === 'sm' ? `16px` : `16px`;
    }};
    height: auto;
    max-height: ${(props): string => {
        return props.size === 'lg' ? `29px` : props.size === 'md' ? `20px` : props.size === 'sm' ? `16px` : `16px`;
    }};
    transition: max-width 0.25s ease-out;
`;
export const SCRootSVG = styled.svg`
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
`;
export const SCPath = styled.path`
    fill: rgb(25, 48, 98);
`;
