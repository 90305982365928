import styled from 'styled-components';

interface ISCRoot {
    visibleState: boolean;
}
export const SCRoot = styled.div<ISCRoot>`
    width: 100%;
    //border: #0074d9 dashed 1px;
    display: ${(props): string => (props.visibleState === true ? 'none' : 'block')};
`;
