import styled from 'styled-components';

export const SCRootWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
`;

export const SCButtonWrapper = styled.div`
    padding: 0 22px 0 0;
`;
