import { OktaAuth } from '@okta/okta-auth-js';

export const oktaAuthClient: OktaAuth =
    process.env.NODE_ENV !== 'test'
        ? new OktaAuth({
              issuer: process.env.REACT_APP_IDP_ISSUER_URL,
              clientId: process.env.REACT_APP_IDP_CLIENT_ID,
              redirectUri: window.location.origin + `/callback`,
              scopes: ['openid', 'groups', 'offline_access'],
              storageManager: {
                  token: {
                      storageType: 'sessionStorage'
                  },
                  cache: {
                      storageType: 'sessionStorage'
                  },
                  transaction: {
                      storageType: 'sessionStorage'
                  }
              }
          })
        : new OktaAuth({
              issuer: `https://dev-apimanager.trustage.com/paymentguard-claims/v1/test/issuer/uri/`,
              clientId: `0oa7c706h4sy7LcZn1d7`,
              redirectUri: `/callback/test/uri/`,
              scopes: ['openid', 'groups', 'offline_access'],
              storageManager: {
                  token: {
                      storageType: 'memory'
                  },
                  cache: {
                      storageType: 'memory'
                  },
                  transaction: {
                      storageType: 'memory'
                  }
              }
          });
