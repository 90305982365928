import * as React from 'react';
import { IAlertTypeState, IAlertType } from './AlertTypes';
import { IChildrenProps } from 'types/children';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';

export const AlertContext = React.createContext<IAlertTypeState | null>(null);
const AlertProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = {};
    const [stateAlert, setStateAlert] = React.useState<IAlertType>(() => {
        localStorage.getItem(LocalStorageKeys.ALERT_STATE_DATA) === null &&
            localStorage.setItem(LocalStorageKeys.ALERT_STATE_DATA, JSON.stringify(initialState));
        const localStorageValue =
            localStorage.getItem(LocalStorageKeys.ALERT_STATE_DATA) !== null &&
            localStorage.getItem(LocalStorageKeys.ALERT_STATE_DATA);
        return JSON.parse(localStorageValue as string);
    });
    const updateContextAlertState = (data: IAlertType): void => {
        localStorage.setItem(
            LocalStorageKeys.ALERT_STATE_DATA,
            data !== null ? JSON.stringify(data) : JSON.stringify({})
        );
        setStateAlert(data);
    };
    const stateObject: IAlertTypeState = {
        contextStateAlert: stateAlert,
        updateContextStateAlert: updateContextAlertState
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::AlertContext:::::::::::::::::::::::::::::::::',
    //     '\n::stateAlert::',
    //     stateAlert,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return <AlertContext.Provider value={stateObject}>{children}</AlertContext.Provider>;
};

export default AlertProvider;
