import React, { ReactElement, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AlertContext, IAlertTypeState } from 'context/Alert';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { SCSessionWrapper } from './styles';
import { IAuthenticationSessionManagementProps } from './types';
import UseTimer from 'utils/timer';
import { InactivityCountDown } from 'components/molecules/InactivityCountDown';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';

dayjs.extend(duration);

const AuthenticationSessionManagement = ({ children }: IAuthenticationSessionManagementProps): ReactElement => {
    const { authState } = useOktaAuth();
    const { updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const [showModal, setShowModal] = useState(false);
    const events = ['click', 'scroll', 'load', 'keydown'];
    /**
     * 20 minutes
     **/
    const timeoutPeriod = 1.2e6;
    /**
     * 1 minute
     **/
    // const timeoutPeriod = 60000;
    /**
     * 10 second
     **/
    // const timeoutPeriod = 10000;
    /**
     * Interval period used for inaction comparisons
     **/
    const timeoutInterval = 60000;
    let lastInteractionTime = localStorage.getItem(LocalStorageKeys.LAST_INTERACTION_TIME);
    let difference = dayjs().diff(dayjs(lastInteractionTime));
    const { stopTimer, startTimer } = UseTimer(() => {
        lastInteractionTime = localStorage.getItem(LocalStorageKeys.LAST_INTERACTION_TIME);
        difference = dayjs().diff(dayjs(lastInteractionTime));
        if (difference > timeoutPeriod) {
            stopInactivityTimerHandler();
            setShowModal(true);
        }
    }, timeoutInterval);
    const eventHandler = (): void => {
        localStorage.setItem(LocalStorageKeys.LAST_INTERACTION_TIME, dayjs().toISOString());
        stopTimer();
        startTimer();
    };
    useEffect(() => {
        if (authState?.isAuthenticated) {
            addEvents();
            updateContextStateAlert({});
        }
        return (): void => {
            removeEvents();
        };
    }, [authState?.isAuthenticated]);
    const stopInactivityTimerHandler = (): void => {
        stopTimer();
    };
    const addEvents = (): void => {
        events.forEach((eventName: string): void => {
            window.addEventListener(eventName, eventHandler);
        });
        stopInactivityTimerHandler();
        localStorage.setItem(LocalStorageKeys.LAST_INTERACTION_TIME, dayjs().toISOString());
    };
    const removeEvents = (): void => {
        events.forEach((eventName: string): void => {
            window.removeEventListener(eventName, eventHandler);
        });
    };
    useEffect((): void => {
        authState?.isAuthenticated && !showModal && eventHandler();
    }, [authState?.isAuthenticated, showModal]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::AuthenticationSessionManagement:::::::::::::::::::::::::::::::::',
    //     '\n::oktaAuth::',
    //     oktaAuth,
    //     '\n::authState::',
    //     authState,
    //     '\n::contextStateAlert::',
    //     contextStateAlert,
    //     '\n::showModal::',
    //     showModal,
    //     '\n::isRunning::',
    //     isRunning,
    //     '\n::authState?.isAuthenticated::',
    //     authState?.isAuthenticated,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCSessionWrapper>
            {showModal && <InactivityCountDown setShowModal={setShowModal} eventHandler={eventHandler} />}
        </SCSessionWrapper>
    );
};

export { AuthenticationSessionManagement };
