import styled, { keyframes } from 'styled-components';

interface IFlexContentJustify {
    HeaderStyledProps: string;
}

export const hoverIn = keyframes`
  from {
    background-position: right;
  }
  to {
    background-position: left;
    background-size: 200% 24px;
    background-repeat: no-repeat;
  }
`;
export const hoverOut = keyframes`
  from {
    background-position: left;
  }
  to {
    background-position: -100%;
  }
`;

export const SCHeaderWrapper = styled.header`
    display: flex;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
    @media (max-width: 768px) {
        padding: 12px 18px 0 18px;
    }
`;
export const SCHeaderContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10% 0 10%;
    gap: 20px;
    align-content: flex-end;
    width: 100vw;
    height: 120px;
    max-width: 100%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    z-index: 1;
    @media (max-width: 768px) {
        height: 60px;
        gap: 29px;
        flex-wrap: wrap;
        align-content: space-around;
        padding: 0;
        box-shadow: initial;
    }
`;
export const SCHeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-end;
    width: 100%;
    height: auto;
    @media (max-width: 768px) {
        max-height: 30px;
        padding: 0;
        margin: 0 0 -20px 0;
    }
    a {
        margin: 0;
    }
`;
export const SCStackContent = styled.div<IFlexContentJustify>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: flex-end;
    width: 100%;
    height: 100%;
`;
export const SCButtonWrapper = styled.div`
    height: 30px;
`;
export const SCHeaderText = styled.div`
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-align: center;
`;

export const SCNavElement = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 20px;
    margin: 5px 0 5px 0;
    border: none !important;
    outline: none !important;
    a {
        margin: 8px 0 0 0;
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
            max-height: 20px;
        }
    }
    button {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
        color: rgba(25, 48, 98, 1);
        font-style: normal;
        font-size: 10px;
        font-weight: 750;
        width: 100%;
        height: 100%;
        //font-family: 'Sul Sans', 'Century Gothic', CenturyGothic, AppleGothic, Arial, Calibri, sans-serif !important;
        line-height: 24px;
        letter-spacing: 2px;
        background-image: linear-gradient(to right, rgba(250, 224, 126, 1) 50%, transparent 50%);
        background-size: 200% 24px;
        background-position: right;
        background-repeat: no-repeat;
        border-radius: 0 !important;
        animation: ${hoverOut} 0.55s ease-out;
        @media (max-width: 768px) {
            background-size: 200% 20px;
        }
    }
    button:hover {
        background-color: transparent;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        animation: ${hoverIn} 0.35s ease-out both;
        background-position: left;
    }
    button:focus {
        background-color: transparent;
        border: none !important;
        outline: none !important;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
        max-height: 20px;
    }
`;
