import React, { ReactElement, useEffect } from 'react';
import { FormTypeContext } from 'context/formTypeContext';
import { FormIUStateContext } from 'context/formIUStateContext';
import { FormDisabilityStateContext } from 'context/formDisabilityStateContext';
import { FormDisability } from 'components/organisms/FormDisability';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { IFormDisabilityState, IFormIUState } from 'types/form';
import { IFormTypeState } from 'types/claim-types';
import { ClaimTypesEnum, TimelineState, TrackerEnum } from 'enums';
import { IClaimFormProps } from './types';
import { SCContactInfoBottom, SCContactInfoTop, SCDecisionContent, SCDecisionItem } from './styles';
import {
    $darkGray,
    SCBodyLarge,
    SCContentDirectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCHeroHeader,
    SCPageWrapper,
    SCSectionBody,
    SCSectionSubHead,
    SCSubHead
} from 'styles/global-styles';
import { FormIU } from 'components/organisms/FormIU';
import { ConfirmAndSubmit } from 'components/molecules/ConfirmAndSubmit';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { IClientContextState } from 'types/client-context';
import { SCButtonWrapper } from 'components/atoms/TSButton/styles';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { IFormDisabilityProgressState, IFormJoblossProgressState } from 'types/form-progress';
import { ClaimConfirmation } from 'components/organisms/ClaimConfirmation';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { FAQSummary } from 'components/organisms/FAQSummary';
import { FAQGroupName } from 'enums/FAQGroupName';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';

const ClaimForm = ({ children }: IClaimFormProps): ReactElement => {
    const { formType, saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { reinitialiseFormIUState, formIUState, saveFormIUState } = React.useContext(
        FormIUStateContext
    ) as IFormIUState;
    const { reinitialiseFormDisabilityState, formDisabilityState, saveFormDisabilityState } = React.useContext(
        FormDisabilityStateContext
    ) as IFormDisabilityState;
    const { resetFormJoblossProgressState, updateFormJoblossProgressCompletionStatus, updateFormJoblossProgressState } =
        React.useContext(FormJoblossProgressStateContext) as IFormJoblossProgressState;
    const {
        resetFormDisabilityProgressState,
        updateFormDisabilityProgressCompletionStatus,
        updateFormDisabilityProgressState
    } = React.useContext(FormDisabilityProgressStateContext) as IFormDisabilityProgressState;
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    useEffect(() => {
        saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
        reinitialiseFormIUState();
        reinitialiseFormDisabilityState();
        resetFormJoblossProgressState();
        resetFormDisabilityProgressState();
        updateFormJoblossProgressCompletionStatus(false);
        updateFormDisabilityProgressCompletionStatus(false);
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ClaimForm:::::::::::::::::::::::::::::::::',
    //     '\n::formType::',
    //     formType,
    //     '\n::contextStateClaimFormAdvancement::',
    //     contextStateClaimFormAdvancement,
    //     '\n::distributorConfigurationDataState::',
    //     distributorConfigurationDataState,
    //     '\n::formDisabilityState::',
    //     formDisabilityState,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCPageWrapper>
            <SCContentWrapper>
                <SCContentDirectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                >
                    {(ClaimTypesEnum.Jobloss === formType.type && (
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.CENTER}
                            sectionWidth={'100%'}
                        >
                            {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.ACTIVE && (
                                <FormIU
                                    changeFormType={(): void => {
                                        saveFormType({
                                            type: ClaimTypesEnum.Disability,
                                            reviewState: ClaimStateEnum.ACTIVE
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.ACTIVE
                                        });
                                        updateFormDisabilityProgressState('id-1', TimelineState.ACTIVE);
                                    }}
                                />
                            )}
                            {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.REVIEW && (
                                <ConfirmAndSubmit formState={formIUState} saveFormState={saveFormIUState} />
                            )}
                            {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.CONFIRMATION && (
                                <ClaimConfirmation />
                            )}
                        </SCContentSectionWrapper>
                    )) ||
                        (ClaimTypesEnum.Disability === formType.type && (
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.CENTER}
                                sectionWidth={'100%'}
                            >
                                {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.ACTIVE && (
                                    <FormDisability
                                        changeFormType={(): void => {
                                            saveFormType({
                                                type: ClaimTypesEnum.Jobloss,
                                                reviewState: ClaimStateEnum.ACTIVE
                                            });
                                            updateContextStateClaimFormAdvancement({
                                                reviewState: ClaimStateEnum.ACTIVE
                                            });
                                            updateFormJoblossProgressState('id-1', TimelineState.ACTIVE);
                                        }}
                                    />
                                )}
                                {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.REVIEW && (
                                    <ConfirmAndSubmit
                                        formState={formDisabilityState}
                                        saveFormState={saveFormDisabilityState}
                                    />
                                )}
                                {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.CONFIRMATION && (
                                    <ClaimConfirmation />
                                )}
                            </SCContentSectionWrapper>
                        )) ||
                        (typeof formType.type === 'undefined' && (
                            <SCContentSectionResponsiveWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'80px'}
                            >
                                <SCContentSectionWrapper
                                    flexDirection={FlexDirectionEnum.COLUMN}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                    flexGap={'36px'}
                                >
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.ROW}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'0'}
                                    >
                                        <SCHeroHeader>File a Claim</SCHeroHeader>
                                        <SCContentSectionWrapper
                                            flexDirection={FlexDirectionEnum.COLUMN}
                                            flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                            flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                            flexGap={'0'}
                                            sectionWidth={'25%'}
                                        >
                                            <SCContactInfoTop>
                                                <h3> 1-855-997-7272</h3>
                                                <h4> 8:00 AM - 5:00 PM CT</h4>
                                                <h4>Monday - Friday</h4>
                                            </SCContactInfoTop>
                                        </SCContentSectionWrapper>
                                    </SCContentSectionWrapper>
                                    {clientContextDataState?.payload?.client_content &&
                                        clientContextDataState.payload.client_content.claim_options.length > 1 && (
                                            <SCContentSectionWrapper
                                                flexDirection={FlexDirectionEnum.COLUMN}
                                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                                flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                                flexGap={'24px'}
                                                sectionWidth={'80%'}
                                            >
                                                <SCSubHead textColor={$darkGray}>
                                                    First, let us know if you are filing for disability or job loss
                                                </SCSubHead>
                                                <SCBodyLarge>
                                                    Choose from the list below to help us identify which claims
                                                    experience to guide you to.{' '}
                                                </SCBodyLarge>
                                            </SCContentSectionWrapper>
                                        )}
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.ROW}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'24px'}
                                        sectionWidth={'80%'}
                                    >
                                        {clientContextDataState?.payload?.client_content?.claim_options.find(
                                            (x) => x.value === ClaimTypesEnum.Disability
                                        ) && (
                                            <SCDecisionContent widthProps={'50%'}>
                                                <SCDecisionItem>
                                                    <SCContentSectionWrapper
                                                        flexDirection={FlexDirectionEnum.COLUMN}
                                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                                        flexGap={'30px'}
                                                    >
                                                        <SCSectionSubHead>
                                                            I need to file a claim for a Disability incident
                                                        </SCSectionSubHead>
                                                        <SCSectionBody>
                                                            I have experienced a disability incident which affected my
                                                            employment.
                                                        </SCSectionBody>
                                                        <SCButtonWrapper>
                                                            <TSButton
                                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                                ariaLabel={''}
                                                                onClick={(): void => {
                                                                    saveFormType({
                                                                        type: ClaimTypesEnum.Disability,
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateContextStateClaimFormAdvancement({
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateFormDisabilityProgressState(
                                                                        'id-1',
                                                                        TimelineState.ACTIVE
                                                                    );
                                                                    document.dispatchEvent(
                                                                        new CustomEvent(
                                                                            TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS,
                                                                            {
                                                                                detail: {
                                                                                    claimType:
                                                                                        ClaimTypesEnum.Disability,
                                                                                    distributorName:
                                                                                        clientContextDataState.payload
                                                                                            .distributorName,
                                                                                    id: TrackerEnum.CLAIMSTEP_START
                                                                                }
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                File a Disability Claim
                                                            </TSButton>
                                                        </SCButtonWrapper>
                                                    </SCContentSectionWrapper>
                                                </SCDecisionItem>
                                            </SCDecisionContent>
                                        )}
                                        {clientContextDataState?.payload?.client_content?.claim_options.find(
                                            (x) => x.value === ClaimTypesEnum.Jobloss
                                        ) && (
                                            <SCDecisionContent widthProps={'50%'}>
                                                <SCDecisionItem>
                                                    <SCContentSectionWrapper
                                                        flexDirection={FlexDirectionEnum.COLUMN}
                                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                                        flexGap={'30px'}
                                                    >
                                                        <SCSectionSubHead>
                                                            I need to file a claim for Job Loss
                                                        </SCSectionSubHead>
                                                        <SCSectionBody>
                                                            I have experienced involuntary unemployment or job loss.{' '}
                                                        </SCSectionBody>
                                                        <SCButtonWrapper>
                                                            <TSButton
                                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                                ariaLabel={''}
                                                                onClick={(): void => {
                                                                    saveFormType({
                                                                        type: ClaimTypesEnum.Jobloss,
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateContextStateClaimFormAdvancement({
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateFormJoblossProgressState(
                                                                        'id-1',
                                                                        TimelineState.ACTIVE
                                                                    );
                                                                    document.dispatchEvent(
                                                                        new CustomEvent(
                                                                            TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS,
                                                                            {
                                                                                detail: {
                                                                                    claimType: ClaimTypesEnum.Jobloss,
                                                                                    distributorName:
                                                                                        clientContextDataState.payload
                                                                                            .distributorName,
                                                                                    id: TrackerEnum.CLAIMSTEP_START
                                                                                }
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                File a Job Loss Claim
                                                            </TSButton>
                                                        </SCButtonWrapper>
                                                    </SCContentSectionWrapper>
                                                </SCDecisionItem>
                                            </SCDecisionContent>
                                        )}
                                    </SCContentSectionWrapper>
                                    <SCContactInfoBottom>
                                        <h3> 1-855-997-7272</h3>
                                        <h4> 8:00 AM - 5:00 PM CT</h4>
                                        <h4>Monday - Friday</h4>
                                    </SCContactInfoBottom>
                                </SCContentSectionWrapper>
                                {clientContextDataState?.payload?.client_content && (
                                    <FAQSummary
                                        distributorConfigurationData={clientContextDataState.payload.client_content}
                                        groupName={FAQGroupName.claim}
                                    ></FAQSummary>
                                )}
                            </SCContentSectionResponsiveWrapper>
                        ))}
                </SCContentDirectionWrapper>
            </SCContentWrapper>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCPageWrapper>
    );
};

export { ClaimForm };
