import React from 'react';
import { SCSvgWrapper, SCSvgFillBlue, SCSvgFillYellow, SCSvgRoot } from './styles';
import { ITrueStageLogoUProps } from './types';

const TruStageLogoU = ({ children }: ITrueStageLogoUProps): JSX.Element => {
    return (
        <SCSvgWrapper>
            <SCSvgRoot
                width="100%"
                height="100%"
                viewBox="0 0 1000 205"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlSpace="preserve"
            >
                <SCSvgFillYellow>
                    <rect x="0.475" y="0.097" width="155.793" height="42.704" />
                    <rect x="0.475" y="54.127" width="116.376" height="42.518" />
                    <rect x="0.475" y="107.971" width="76.958" height="42.518" />
                    <rect x="0.475" y="161.814" width="37.54" height="42.518" />
                </SCSvgFillYellow>
                <SCSvgFillBlue>
                    <rect x="169.399" y="0.097" width="37.54" height="42.704" />
                    <rect x="129.992" y="54.127" width="76.958" height="42.518" />
                    <rect x="90.563" y="107.971" width="116.376" height="42.518" />
                    <rect x="51.156" y="161.814" width="155.793" height="42.518" />

                    <path d="M328.202,61.739l-34.725,0l0,-18.752l89.159,-0l0,18.752l-34.725,0l-0,99.89l-19.709,0l0,-99.89Z" />
                    <path d="M378.128,78.079l18.583,-0l-0,14.853c3.191,-9.469 12.576,-15.782 22.149,-15.782c2.44,0 4.692,0.186 7.32,0.743l-0,18.938c-3.191,-0.928 -5.819,-1.485 -9.198,-1.485c-10.699,0 -20.271,8.912 -20.271,25.623l-0,40.847l-18.583,-0l-0,-83.737Z" />
                    <path d="M440.629,78.079l18.583,0l-0,46.975c-0,13.368 6.006,21.537 16.893,21.537c12.013,0 20.46,-9.469 20.46,-27.479l-0,-41.033l18.582,0l-0,83.551l-18.582,0l-0,-11.325c-4.881,8.355 -13.891,12.996 -24.589,12.996c-19.334,0 -31.347,-13.925 -31.347,-35.277l-0,-49.945Z" />
                    <path d="M542.562,130.067c6.191,9.469 16.14,15.225 27.776,15.225c11.641,0 19.333,-5.756 19.333,-14.668c-0,-8.355 -5.628,-13.925 -17.081,-18.01l-13.136,-4.642c-18.961,-6.87 -28.344,-17.267 -28.344,-33.42c0,-20.795 16.703,-33.421 38.852,-33.421c14.828,0 27.405,5.942 36.23,16.153l-13.705,13.183c-6.004,-7.055 -13.512,-10.954 -22.708,-10.954c-10.137,-0 -18.774,5.198 -18.774,13.739c0,8.541 5.821,12.626 18.21,17.082l12.2,4.456c17.645,6.498 28.341,17.081 28.153,34.72c-0,20.795 -16.517,34.349 -39.977,34.349c-19.338,-0 -35.29,-8.726 -42.611,-21.352l15.582,-12.44Z" />
                    <path d="M722.946,76.407c12.2,0 22.149,6.128 27.029,12.812l-0,-11.14l18.585,-0l0,83.551l-18.585,-0l-0,-11.14c-4.692,6.684 -14.829,12.811 -27.029,12.811c-23.084,-0 -40.541,-19.496 -40.541,-43.447c-0,-23.951 17.457,-43.447 40.541,-43.447Zm3.38,16.711c-15.016,-0 -25.153,11.697 -25.153,26.736c-0,15.04 10.137,26.737 25.153,26.737c15.017,-0 25.154,-11.697 25.154,-26.737c-0,-15.039 -10.137,-26.736 -25.154,-26.736Z" />
                    <path d="M824.301,198.207c-16.522,0 -31.162,-6.127 -39.418,-15.411l11.26,-13.182c7.132,7.612 16.704,12.254 27.97,12.254c13.888,0 25.336,-9.098 25.336,-25.994l-0,-7.612c-5.063,7.427 -15.388,12.997 -27.029,12.997c-22.708,-0 -40.353,-18.753 -40.353,-42.519c-0,-23.765 17.645,-42.333 40.353,-42.333c11.641,0 21.966,5.571 27.029,12.812l-0,-11.14l18.585,-0l0,77.795c0,29.15 -18.768,42.333 -43.733,42.333Zm1.687,-54.03c14.265,-0 25.154,-10.769 25.154,-25.622c-0,-14.854 -10.701,-25.623 -25.154,-25.623c-14.452,0 -25.153,10.769 -25.153,25.623c0,14.853 10.889,25.622 25.153,25.622Z" />
                    <path d="M924.914,76.407c22.149,0 40.541,14.483 40.541,41.405c0,1.856 0,3.342 -0.188,5.941l-66.259,0c0.753,13.74 12.013,22.652 25.906,22.652c11.824,0 19.709,-5.384 24.025,-12.625l14.076,10.025c-7.508,11.884 -20.644,19.31 -38.477,19.31c-25.53,0 -43.922,-18.009 -43.922,-43.447c0.376,-24.322 18.769,-43.261 44.298,-43.261Zm-24.965,33.978l46.738,0c-2.064,-12.068 -11.824,-18.567 -22.525,-18.567c-10.701,0.186 -21.585,6.313 -24.213,18.567Z" />
                    <path d="M613.33,78.078l-0,16.339l17.457,0l-0,40.291c-0,18.938 12.576,27.664 28.722,27.664c5.627,0 10.131,-0.742 13.888,-1.856l-0,-16.339c-2.628,0.928 -6.38,1.671 -9.949,1.671c-9.572,-0 -14.076,-3.899 -14.076,-14.297l0,-37.319l24.025,-0l-0,-16.339l-24.025,-0l0,-23.023l-18.585,-0l-0,10.397c-0,7.056 -5.816,12.626 -12.765,12.811l-4.692,0Z" />
                    <path d="M964.445,92.66l-0,-17.791l-6.359,0l0,-3.396l16.194,0l0,3.396l-6.353,0l0,17.791l-3.482,0Zm15.667,0l-0,-21.187l3.45,0l6.272,13.525l6.24,-13.525l3.451,0l-0,21.187l-3.483,0l0,-13.408l-6.24,13.641l-6.267,-13.612l0,13.379l-3.423,0Z" />
                </SCSvgFillBlue>
            </SCSvgRoot>
        </SCSvgWrapper>
    );
};

export { TruStageLogoU };
