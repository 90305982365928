import React, { ReactElement, useEffect, useState } from 'react';
import { SCMarketingContentWrapper, SCBlue, SCParagraph, SCUnorderedList, SCOrderedList, SCUnderlined, SCLeftColumnWrapper, SCRightColumnWrapper } from './styles';
import { IMarketingItems, IMarketingJson } from './types';
import { $darkGray, SCContentSectionResponsiveWrapper, SCContentWrapper, SCEyebrow, SCFooterWrapper, SCPageWrapper, SCSectionWrapper, SCSubHead } from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';
import { Accordion } from 'components/molecules/Accordion';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { TSQuickLinks } from 'components/atoms/TSQuickLinks';

const MarketingGuidelines = (): ReactElement => {
    const [marketingGuidelineItems, setMarketingGuidelineItems] = useState<IMarketingItems[]>();
    const quickLinks = [
        { title: 'Pre-Complied Copy Content', destination: 'groupId_preCompliedCopyContents', arrivalOption: 'scroll' },
        { title: 'Marketing Content Generated By You', destination: 'groupId_marketingContentGeneratedByYou', arrivalOption: 'scroll' },
        { title: 'Branding Guidelines', destination: 'groupId_brandingGuidelines', arrivalOption: 'scroll' }
    ];
    useEffect(() => {
        const data = (staticContent);
        setMarketingGuidelineItems(data.marketing_guidelines_collections);
    }, []);
    return (
        <>
            <SCPageWrapper>
                <SCContentWrapper>
                    <SCContentSectionResponsiveWrapper
                        flexDirection={FlexDirectionEnum.ROW}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'40px'}
                    >
                        <SCLeftColumnWrapper>
                            <SCContentSectionResponsiveWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCEyebrow>{'Marketing Content'}</SCEyebrow>
                                <SCSubHead textColor={$darkGray}>{'Marketing Guidelines'}</SCSubHead>
                                {marketingGuidelineItems &&
                                    marketingGuidelineItems.map((data: IMarketingItems, index) => {
                                        return (
                                            <SCSectionWrapper key={`faq_item_${index}`}>
                                                <SCMarketingContentWrapper>
                                                    <Accordion
                                                        data={data.group_list}
                                                        id={data.id}
                                                        group_description={`${data.group_description}`}
                                                        group_label={`${data.group_label}`}
                                                    ></Accordion>
                                                </SCMarketingContentWrapper>
                                            </SCSectionWrapper>
                                        );
                                    })}
                            </SCContentSectionResponsiveWrapper>
                        </SCLeftColumnWrapper>
                        <SCRightColumnWrapper>
                            <TSQuickLinks
                                ariaLabel={'Marketing Guidelines Quick Links'}
                                id={'marketingQuickLinks'}
                                links={quickLinks}
                                title={'Marketing Guidelines'}
                            />
                        </SCRightColumnWrapper>
                    </SCContentSectionResponsiveWrapper>
                </SCContentWrapper>
                <SCFooterWrapper>
                    <GlobalFooter />
                </SCFooterWrapper>
            </SCPageWrapper>
        </>
    );
};

/** 
 * -Notes on Markup formatting below-
 *  I aimed to make the JSX-markup below as humanly-readable as possible - here are a few rules I followed for myself.
 *  1. Paragraph content always gets a new line.
 *  2. Inline styles (such as "<SCBlue>" , "<SCUnderline>" , and <b/>  - for blue, underlined, and bold font) are always defined inline with their content and adjacent content that is expected to also be in the same line.
 *  3. Content will be written using a bracket-plus-doublequote structure. Ex: <div>{"There's some readable content here."}</div> 
 *       - This behaves the best with all the content (such as the various apostrophes and occasional "< >" text that appears in the content.) Using this format resulted in no special exceptions needing to be made for any of the JSX-formatting.
 *  Note: I have marked this file in .prettierignore to be ignored from evaluation. The suggestions it was giving were ultimately making this file less readable.
 */

const UsingPreCompliedContent = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"To assist you with the implementation of the Payment Guard product, we have developed the following pre-complied Payment Guard copy for use on your website to inform your customers about this new product."}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{'The pre-complied content below has been designed for use on your website and must be used ‘as is’, without any edits/changes.'}</li>
                    <li>{"The pre-complied content below, including the disclosure, unique ID number, and copyright, must be used in its entirety."}</li>
                    <li>
                        {"Content descriptions in "} <SCBlue>{"blue"}</SCBlue> {" are descriptive text and not intended to be included in the final design."}
                    </li>
                    <li>{"Special note: Any electronic mail message sent with the primary purpose of advertisement or promotion of a commercial product or service must comply with the CAN-SPAM Act. Any commercial electronic message sent to residents of Canada must comply with Canada’s Anti-Spam Legislation (CASL)."}</li>
                </SCUnorderedList>
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const PreCompliedCopy = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"(This is an example only, distribution partners may have modified coverages)"}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"Page Title: "}</SCBlue>{"TruStage™ Payment Guard Insurance"}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"TruStage™ Payment Guard Insurance Headline: "}</SCBlue>{"Loan payment protection at no additional cost to you"}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"Sub headline: "}</SCBlue>{"For covered unknown job losses and unexpected disabilities"}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"Copy"}</SCBlue>
            </SCParagraph>
            <SCParagraph>
                {"At TruStage™, we understand how financial stress could affect you when your income stops for no fault of your own. Therefore, we designed Payment Guard Insurance and partnered with [distributor name] to help provide you peace of mind in such situations."}
            </SCParagraph>
            <SCParagraph>
                {"[Distributor name] launched TruStage™ Payment Guard Insurance as a unique benefit to its customers at no additional cost , on [insert launch date]. All loans funded on or after this date are automatically covered with Payment Guard for the loan term. Your coverage automatically ends when the payment obligation ends, or the maximum policy period is reached, whichever is sooner. The amount of coverage you may be eligible for it [enter coverage details as per policy]."}
            </SCParagraph>
            <SCParagraph>
                <b>{"How it works"}</b>
            </SCParagraph>
            <SCParagraph>
                {"It's pretty simple. [Distributor name] purchases TruStage™ Payment Guard Insurance to protect the customer seeking a loan, such as you, from covered unknown job losses. If you, then, experience a covered job loss or disability, while you have an outstanding balance on your loan, you may file a claim with TruStage™ Payment Guard Insurance. Our experienced claims professionals will review, and decision all submitted claims. If your claim is approved, we will pay a benefit amount in accordance with your certificate of insurance, up to the policy maximums directly to [distributor name] to apply to your account, so you can focus on getting back on your feet."}
            </SCParagraph>
            <SCParagraph>
                {"All eligible loans funded on or after <insert launch date> are covered by TruStage Payment Guard Insurance for the term of the loan or when the maximum policy period is reached, whichever is sooner."}
            </SCParagraph>
            <SCParagraph>
                {"If you, then, experience a covered job loss [or disability], and your claim is approved, TruStage Payment Guard Insurance will pay a benefit amount equal to [insert months] of your monthly loan payments, up to the policy maximums directly to [distributor name] so you can focus on getting back on your feet. The amount of coverage you my be eligible for it [enter coverage details as per policy]."}
            </SCParagraph>
            <SCParagraph>
                {"To get started, all you have to do is file a claim with TruStage™ Payment Guard Insurance if you are in a situation of an unexpected job loss [or disability]. Our experienced claims professionals will review all submitted claims. If your claim is approved, we will send [distributor name] your benefit and they will apply it to your loan payment obligation."}
            </SCParagraph>
            <SCParagraph>
                {"All eligible loans for eligible borrowers approved and funded on or after [xx/xx/xxxx] are covered with TruStage Payment Guard Insurance"}
            </SCParagraph>
            <SCParagraph>
                <b>{"Need to file a claim?"}</b>
            </SCParagraph>
            <SCParagraph>
                {"File a claim if you experience a protected job loss or disability by clicking here. If approved, your claim benefit will be sent to [distributor name]. Your covered benefit is equal to <insert months> of your monthly loan payments, but no more than the policy maximums."}
            </SCParagraph>
            <SCParagraph>
                {"[Distributor name] will apply your approved benefit towards your future loan payments."}
            </SCParagraph>
            <SCParagraph>
                <b>{"Key value props for consumers"}</b>
            </SCParagraph>
            <SCParagraph>
                <SCOrderedList>
                    <li>
                        {"No additional cost, automatic coverage"}
                        <br />
                        {"[Distributor name] is excited to offer Payment Guard protection once your loan is funded, at no additional cost to you."}
                    </li>
                    <li>
                        {"Fast and convenient claims process"}
                        <br />
                        {"Simple and easy online claim application with updates sent directly to you."}
                    </li>
                    <li>
                        {"Hassle-free benefit payment"}
                        <br />
                        {"Convenient benefit payment sent directly to [distributor name] to be applied to your account."}
                    </li>
                </SCOrderedList>
            </SCParagraph>
            <SCParagraph>
                <b>{"Borrower Contact Info"}</b>
            </SCParagraph>
            <SCParagraph>
                {"Email address: "}
                <SCUnderlined>{"paymentguard@trustage.com"}</SCUnderlined> 
            </SCParagraph>
            <SCParagraph>
                {"Phone number: 855-997-7272"}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"Disclosure"}</SCBlue>
            </SCParagraph>
            <SCParagraph>
                {"TruStage Payment Guard Insurance is underwritten by CUMIS Specialty Insurance Company, Inc. CUMIS Specialty Insurance Company, our excess and surplus lines carrier, underwrites coverages that are not available in the admitted market. Product and features may vary and not be available in all states. Certain eligibility requirements, conditions, and exclusions may apply. Please refer to the Group Policy for a full explanation or the terms. The insurance offered is not a deposit, and is not federally insured, sold or guaranteed by any financial institution."} 
                <br/>
                {"Corporate Headquarters 5910 Mineral Point Road, Madison, WI 53705"}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"Unique ID & Copyright"}</SCBlue>
            </SCParagraph>
            <SCParagraph>
                {"ADTRAX#"}
            </SCParagraph>
            <SCParagraph>
                {"© TruStage, All Rights Reserved"}
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const WhatItemsNeedToBeReviewed = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"The following is not all-inclusive, but shows some examples of what can be considered advertising if used to promote or sell:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"Content used in brochures, hand-outs, direct mail, newspapers, magazines, radio and television scripts, billboards, flyers, posters, and any mass media communication"}</li>
                    <li>{"All digital & internet content, including webpages, social media biographies and posts, banner ads, paid search, targeting advertisements and applications for mobile devices"}</li>
                    <li>{"Descriptive literature and sales aids of all kinds, authored by the insurer, its insurance producers or third parties, including but not limited to circulars, leaflets, booklets, depictions, illustrations, form letters, and lead-generating devices of all kinds"}</li>
                    <li>{"Material used for the recruitment, training and education of an insurer's agents or employees"}</li>
                    <li>{"Prepared sales talks and presentations"}</li>
                    <li>{"Business cards, professional designations, testimonials and on-hold messages"}</li>
                </SCUnorderedList>
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const WhatIsTheReviewProcessForMyCollateral = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"It takes approximately 5 business days for your content to be reviewed. Once you submit that piece to us, please pause additional edits that may cause a delay to your review. Anytime a change is made, it initiates a new review."}
            </SCParagraph>
            <SCParagraph>
                {"Please submit your request as a searchable PDF of the collateral. If your submission has links, hashtags or directs the consumer to another page, please also provide a searchable PDF of the location the consumer is directed to."}
            </SCParagraph>
            <SCParagraph>
                {"Once your submission is received, we will review the content and provide feedback on changes that need to be made when referencing the product. The collateral is not permitted to be used “in market” until the final copy is approved by TruStage."}
            </SCParagraph>
            <SCParagraph>
                {"Each submission is assigned their own dedicated AdTrax # that needs to be included in the bottom left-hand corner with that collateral. Please refer to the section below regarding AdTrax# and One-Click Rule for more details."}
            </SCParagraph>
            <SCParagraph>
                {"As an insurance company we are responsible to help our business partners represent the product appropriately avoiding any overpromising, or misleading language. We are required to retain a record of our approval of that content and have it readily available should any examiners or regulators ask."}
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const CanWeCallItUnemploymentInsurance = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"No. TruStage™ Payment Guard Insurance should not be labeled as unemployment insurance as these products are different from each other. Payment Guard Insurance can include coverage if consumers have a Job Loss, while Unemployment insurance is a policy that helps cover consumers income for a set amount of time after they lose their job. It supplements the government’s unemployment benefits, helping jobless workers retain their full salary. Consumers can receive unemployment insurance either through their employer, the state government, or a private policy."}
            </SCParagraph>
            <SCParagraph>
                <SCBlue>{"Suggested text:"}</SCBlue>
            </SCParagraph>
            <SCParagraph>
                {"TruStage™ Payment Guard includes coverage if you have a covered job loss."}
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const CanWeCoBrand = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"There are restrictions in how TruStage™ Payment Guard insurance is presented next to the distributor brand."}
            </SCParagraph>
            <SCParagraph>
                {"For example, "}<b>{"XYZ’s Job Loss Coverage powered by TruStage™ Payment Guard."}</b>{" This example is not compliant for the following reasons:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"It must be clear that this product is insurance."}</li>
                    <li>{"It must be clear that this product is underwritten and provided by CUMIS Specialty Insurance Company, Inc (and only CUMIS)."}</li>
                    <li>{"Distributors of Payment Guard insurance cannot represent themselves as “offering” or “providing” the insurance. They are not a licensed insurance agent or Company."}</li>
                </SCUnorderedList>
            </SCParagraph>
            <SCParagraph>
                {"The following are some examples of how “co-branding” could work. These will still need to be approved by TruStage™ and the language around them will need to represent and disclose that CUMIS Specialty Insurance Company, Inc. is the insurer along with the standard product details."}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"TruStage™ Payment Guard Insurance, made available to our customers by XYZ."}</li>
                    <li>{"Understanding the needs of our customers, XYZ has collaborated with TruStage™ to provide TruStage™ Payment Guard Insurance on all eligible loans."}</li>
                    <li>{"Loans through XYZ include TruStage™ Payment Guard Insurance."}</li>
                    <li>{"XYZ loans include TruStage™ Payment Guard Insurance."}</li>
                </SCUnorderedList>
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const RecommendationsOnHowToTalk = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"The following is a list of some common words or phrases that should be avoided or used in certain ways."}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Could vs. Can"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"Could & Can are not always interchangeable. They are similar and “could” is the past tense of “can” in some references. However, when used in the present tense, “could” denotes less certainty, and “can” means to be able to do something. If the goal is to soften a statement as an uncertain condition, “could” should be used."}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Avoid Superlatives"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"These are words that indicate or imply surpassing others. Examples: Best, top, industry leading, etc. A rule of thumb to follow when using the term “industry leading” is to be able to prove a top ten ranking in the category being promoted."}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Words to Avoid"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"“All”; “full”; “complete”; “comprehensive”; “unlimited”; “up to”; “as high as” are some examples. To avoid definite/promissory statements, here are some words that could soften language: Examples: -Helps, may, could, designed to be…"}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Guaranteed"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"Guaranteed disclosure must be included anytime the word is used and not fully explained. If ad mentions a guarantee, please use this disclosure: 'All guarantees are subject to the financial strength and claims paying ability of the issuing insurance company."}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Free"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"In order to use the word “free” or a similar word, the item or claim must be free in every sense of the word. If someone or an entity is paying to make it free, we must disclose that fact. We need to explain in the text or with an asterisk & disclosure on the same page that the credit union is paying for the coverage."}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Partner"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"Using partnership in a way that confuses who is financially responsible for the insurance policy (life, AD&D, annuity, auto, home) should not be permitted. A use of partnership that suggests a “backed by” connection would not work. Partnership could be used to communicate other products/services the consumer can learn about/access through TruStage™."}
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Examples:"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"Acceptable: TruStage™ has selected XYZ company to be our trusted partner to offer auto insurance."}</li>
                    <li>{"Acceptable: Our Partners – XYZ Company, ABC Company."}</li>
                    <li>{"Not Acceptable: TruStage™ and XYZ Company have formed a partnership to jointly offer life insurance (Unless contractually true)."}</li>
                    <li>{"Not Acceptable: TruStage™ partners with XYZ company, and because of this partnership you have the full backing of both companies when purchasing insurance."}</li>
                </SCUnorderedList>
            </SCParagraph>
            <SCParagraph>
                <SCUnderlined>{"Urgency"}</SCUnderlined>
            </SCParagraph>
            <SCParagraph>
                {"Do not use words that cause an undue sense of urgency. Buying insurance is an important matter but should not be an urgent request."}
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const AreThereAnyClearListAddresses = (): ReactElement => {
    return (
        <SCMarketingContentWrapper>
            <SCParagraph>
                {"Yes, please enable access to these areas:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>
                        <SCUnderlined>{"paymentguard@trustage.com"}</SCUnderlined>{" - Claims and Customer Service"}
                    </li>
                    <li>
                        <SCUnderlined>{"Lauren.Zimmer@m3ins.com"}</SCUnderlined>{" – M3 Billing Contact"}
                    </li>
                    <li>
                        <SCUnderlined>{"Jenn.Burkmier@trustage.com"}</SCUnderlined>{" – Business Development"}
                    </li>
                </SCUnorderedList>
            </SCParagraph>
        </SCMarketingContentWrapper>
    );
};

const staticContent: IMarketingJson =
{
    marketing_guidelines_collections: [
        {
            id: "groupId_preCompliedCopyContents",
            group_name: "precomplied_copy_content",
            group_label: "Pre-Complied Copy Content",
            group_description: "We are excited for you to create a user experience that integrates this product into your borrower journey. As you build out content, please be sure to submit any screen shots or materials that contain reference to the product(s) below so we can assist in maintaining compliance on the advertising of this product.",
            group_list: [
                {
                    label: "Using Pre-Complied Copy Content",
                    content: UsingPreCompliedContent(),
                    summary_order: 1
                },
                {
                    label: "Pre-Complied Copy",
                    content: PreCompliedCopy(),
                    summary_order: 2
                }
            ]
        },
        {
            id: "groupId_marketingContentGeneratedByYou",
            group_name: "marketing_content_generated_by_you",
            group_label: "Marketing Content Generated By You",
            group_description: "You may also create your content.",
            group_list: [
                {
                    label: "What items need to be reviewed?",
                    content: WhatItemsNeedToBeReviewed(),
                    summary_order: 1
                },
                {
                    label: "What type of collateral do we need to have complied by TruStage™ before providing to our consumers?",
                    content: "All collateral mentioning PGI or TruStage needs to be sent for review to see if it meets the definition of advertising. If it does, then it will need to be sent through the process.",//WhatTypeOfCollateral(),
                    summary_order: 2
                },
                {
                    label: "What is the review process for my collateral mentioning Payment Guard and what is the timeline?",
                    content: WhatIsTheReviewProcessForMyCollateral(),
                    summary_order: 3
                },
                {
                    label: "Can we call TruStage™ Payment Guard Insurance \"unemployment insurance\"?",
                    content: CanWeCallItUnemploymentInsurance(),
                },
                {
                    label: "Can we advertise TruStage™ Payment Guard Insurance on our website?",
                    content: "Yes, however, any advertising of TruStage™ Payment Guard Insurance should be sent to DLIMarketing@trustage.com for compliance review.",
                },
                {
                    label: "Can we co-brand or private label TruStage™ Payment Guard Insurance?",
                    content: CanWeCoBrand(),
                },
                {
                    label: "Recommendations on how to talk to your consumers about Payment Guard",
                    content: RecommendationsOnHowToTalk(),
                },
                {
                    label: "Approved Disclosure to be used when referring to TruStage™:",
                    content: "TruStage™ Payment Guard Insurance is underwritten by CUMIS Specialty Insurance Company, Inc. CUMIS Specialty Insurance Company, our excess and surplus lines carrier, underwrites coverages that are not available in the admitted market. Product and features may vary and not be available in all states. Certain eligibility requirements, conditions, and exclusions may apply. Please refer to the Group Policy for a full explanation of the terms. The insurance offered is not a deposit, and is not federally insured, sold or guaranteed by any financial institution. Corporate Headquarters 5910 Mineral Point Road, Madison, WI 53705.",
                },
                {
                    label: "What is AdTrax and how do I use it for advertisements and marketing materials?",
                    content: "This number is generated internally and provided to you by TruStage™. All advertisements are required to have an advertising form number (AdTrax) in the lower left corner of the first page for one-page advertisements. If the advertisement has multiple pages, the advertisement form number may appear in the lower left corner on the first or last page. Sample AdTrax: PGI-XXXXXXXX.X-XXXX-XXXX",
                },
                {
                    label: "What do the distribution partners need to include in their submissions?",
                    content: "Please include any materials that refer to TruStage™ Payment Guard Insurance, including any external links from a page that refer to or describe or promote TruStage™ Payment Guard Insurance. Example: Banner ad that takes the user to an internal landing page that refers to Payment Guard. Please send the banner ad and the landing page for review.",
                }
            ]
        },
        {
            id: "groupId_brandingGuidelines",
            group_name: "branding_guidelines",
            group_label: "Branding Guidelines",
            group_description: '',
            group_list: [
                {
                    label: "Branding content",
                    content: "Please refer to this website for all current branding guidelines.",
                    summary_order: 1
                },
                {
                    label: "Trademark",
                    content: "TruStage™ Payment Guard Insurance",
                    summary_order: 2
                },
                {
                    label: "Product reference",
                    content: "Must be identified as insurance. Terms such as “insurance”, “protection” and “coverage” may be interchangeable provided that the product is described as “insurance” in a prominent location.",
                    summary_order: 3
                },
                {
                    label: "Are there any email addresses or websites that we should clear-list to ensure accessibility?",
                    content: AreThereAnyClearListAddresses()
                }
            ]
        }
    ]
};

export { MarketingGuidelines };
