import React from 'react';
import { ILogInProps } from './types';
import { SCContentWrapper, SCPageWrapper } from 'styles/global-styles';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';

const LogIn = ({ children }: ILogInProps): JSX.Element => {
    const history = useHistory();
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const setLogIn = (): void => {
        // console.info(
        //     '\n::::::::::::::::::::::::::::::::RouterSwitch::setLogIn:::::::::::::::::::::::::::::::',
        //     '\n::authenticationData::',
        //     authenticationData,
        //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
        // );
        updateAuthenticationType({ ...authenticationData, isAuthenticated: true });
    };
    const setLogOut = (): void => {
        // console.info(
        //     '\n::::::::::::::::::::::::::::::::RouterSwitch::setLogIn:::::::::::::::::::::::::::::::',
        //     '\n::authenticationData::',
        //     authenticationData,
        //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
        // );
        updateAuthenticationType({ ...authenticationData, isAuthenticated: false });
    };

    return (
        <>
            <SCPageWrapper>
                <SCContentWrapper>
                    login jazz here
                    <button
                        onClick={(): void => {
                            setLogIn();
                            history.push(Routes.ADMIN_LANDING);
                        }}
                    >
                        log-in
                    </button>
                    <button
                        onClick={(): void => {
                            setLogOut();
                        }}
                    >
                        log-out
                    </button>
                </SCContentWrapper>
            </SCPageWrapper>
        </>
    );
};

export { LogIn };
