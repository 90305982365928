import * as React from 'react';
import { IChildrenProps } from 'types/children';
import { IClaimLookupResponseData, IClaimLookupResponseDataState } from 'types/claim-lookup';

export const ClaimLookupDataStateContext = React.createContext<IClaimLookupResponseDataState | null>(null);
const ClaimLookupDataStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [claimLookupResponseDataState, setclaimLookupResponseData] = React.useState<IClaimLookupResponseData>({
        claimNumber: '',
        borrowerIdentifierKey: '',
        borrowerIdentifierTypeId: -1,
        borrowerPhoneNumber: '',
        peril: '',
        claimStatus: '',
        claimDecision: '',
        claimDecisionDate: '',
        claimDecisionReason: '',
        borrowerFirstName: '',
        borrowerLastName: '',
        distributorName: '',
        loanNumber: '',
        amountPaid: 0,
        filedDate: '',
        incurredDate: '',
        denialReason: '',
        shouldDisplayResult: false,
        shouldDisplayNoResult: false,
        claimBenefits: []
    });

    const saveClaimLookupResponseDataState = (claimLookupResponseData: IClaimLookupResponseData): void => {
        const newclaimLookupResponseData: IClaimLookupResponseData = {
            claimNumber: claimLookupResponseData.claimNumber,
            borrowerIdentifierKey: claimLookupResponseData.borrowerIdentifierKey,
            borrowerIdentifierTypeId: claimLookupResponseData.borrowerIdentifierTypeId,
            borrowerPhoneNumber: claimLookupResponseData.borrowerPhoneNumber,
            peril: claimLookupResponseData.peril,
            claimStatus: claimLookupResponseData.claimStatus,
            claimDecision: claimLookupResponseData.claimDecision,
            claimDecisionReason: claimLookupResponseData.claimDecisionReason,
            claimDecisionDate: claimLookupResponseData.claimDecisionDate,
            borrowerFirstName: claimLookupResponseData.borrowerFirstName,
            borrowerLastName: claimLookupResponseData.borrowerLastName,
            distributorName: claimLookupResponseData.distributorName,
            loanNumber: claimLookupResponseData.loanNumber,
            amountPaid: claimLookupResponseData.amountPaid,
            incurredDate: claimLookupResponseData.incurredDate,
            filedDate: claimLookupResponseData.filedDate,
            denialReason: claimLookupResponseData.denialReason,
            shouldDisplayResult: claimLookupResponseData.shouldDisplayResult,
            shouldDisplayNoResult: claimLookupResponseData.shouldDisplayNoResult,
            claimBenefits: claimLookupResponseData.claimBenefits
        };
        setclaimLookupResponseData(newclaimLookupResponseData);
    };

    return (
        <ClaimLookupDataStateContext.Provider
            value={{ claimLookupResponseDataState, saveClaimLookupResponseDataState }}
        >
            {children}
        </ClaimLookupDataStateContext.Provider>
    );
};

export default ClaimLookupDataStateProvider;
