import React, { ReactElement, useEffect } from 'react';
import { AlertContext, IAlertTypeState } from 'context/Alert';
import { useLocation } from 'react-router-dom';
import { Alert } from 'components/molecules/Alert';
import { IAlertManagementRootProps } from './types';

const AlertManagementRoot = ({ children }: IAlertManagementRootProps): ReactElement => {
    const { contextStateAlert, updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const location = useLocation();
    useEffect(() => {
        location.pathname !== '/' &&
            contextStateAlert.active &&
            contextStateAlert.dismissible &&
            updateContextStateAlert({});
    }, [location]);

    const dismissAlert = (): void => {
        updateContextStateAlert({});
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::Alert:::::::::::::::::::::::::::::::::',
    //     '\n::contextStateAlert::',
    //     contextStateAlert,
    //     '\n::contextStateAlert.active::',
    //     contextStateAlert.active,
    //     '\n::contextStateAlert.callToActionType::',
    //     contextStateAlert.callToActionType,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return <Alert alert={contextStateAlert} handleDismiss={dismissAlert} />;
};

export { AlertManagementRoot };
