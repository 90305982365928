import { HeadCell } from 'components/pages/ClaimsManagement';

export const editFormLabels = {
    borrowerName: 'Name',
    borrowerNameFirst: 'First Name',
    borrowerNameLast: 'Last Name',
    claimStatus: 'Claim Status',
    distributorName: 'Distributor',
    loanServicerName: 'Loan Servicer',
    claimNumber: 'Claim Number',
    reasonForJobLoss: 'Reason for Job Loss',
    claimIdentifier: 'Identifier Key',
    last4SSN: 'Last 4 SSN',
    dateFiled: 'Date Filed',
    lossType: 'Loss Type',
    loanNumber: 'Loan Number',
    incurredDate: 'Incurred Date',
    claimDecisionDate: 'Claim Decision Date',
    claimDecision: 'Claim Decision',
    amountPayable: 'Amount Payable',
    benefitAmountPayableSumTotal: 'Benefit Amount Payable Sum Total',
    disablingCondition: 'Disabling Condition',
    decisionReason: 'Denial Reason',
    dateDecisionSent: 'Date decision email sent to Borrower',
    fastTracked: 'Was claim Fast Tracked?',
    additionalInformation: 'Decision - Additional Information',
    eConsentReceived: 'E-Consent received from Borrower',
    claimSpecialistName: 'Name of Claim Specialist',
    notes: 'Notes',
    claimStateUpdatedDate: 'Claim Status Updated',
    claimSubmitDate: 'Claim Submit Date',
    benefitDecisionDate: 'Date of Benefit Decision',
    amount: 'Amount',
    status: 'Status',
    emailAddress: 'Email Address'
};
export const claimBenefitFormLabels = {
    benefitId: 'Benefit Id',
    benefitDecision: 'Benefit Decision',
    externalSystemIdentifier: 'External System Identifier',
    claimPolicyRelationshipId: 'Claim Policy Relationship Id',
    amountPayable: 'Benefit Amount Payable',
    decisionReason: 'Denial Reason',
    dateDecisionSent: 'Date decision email sent to Borrower',
    decisionAdditionalInformation: 'Reason claim was not fast tracked',
    claimDecisionDate: 'Date of Benefit Decision'
};
export const SurveyCSVLabels = {
    claimNumber: 'ClaimNumber',
    email: 'Email',
    product: 'Product',
    orig_Product: 'Orig_Product',
    name: 'Name',
    claimPaymentAmount: 'ClaimPaymentAmount',
    address: 'Address',
    creditUnion: 'CreditUnion',
    adjuster: 'Adjuster',
    policyNumber: 'PolicyNumber',
    policy_Number: 'Policy_Number',
    nFCU: 'NFCU',
    fileName: 'FileName',
    lastSampleMonth: 'LastSampleMonth',
    teamLead: 'TeamLead',
    beneficiarySettlementType: 'BeneficiarySettlementType',
    benePhone: 'BenePhone',
    beneRelationship: 'BeneRelationship',
    cellPhone1: 'CellPhone1',
    cellPhoneNumber2: 'CellPhoneNumber2',
    claimDurationInMonths: 'ClaimDurationInMonths',
    claimEnteredDate: 'ClaimEnteredDate',
    claimPaymentDate: 'ClaimPaymentDate',
    claimStatus: 'ClaimStatus',
    claimType: 'ClaimType',
    closeDate: 'CloseDate',
    closeReason: 'CloseReason',
    complexityRating: 'ComplexityRating',
    contestable: 'Contestable',
    contractNumber: 'ContractNumber',
    cover_Type: 'Cover_Type',
    coverageName: 'CoverageName',
    coveragePaymentAmt: 'CoveragePaymentAmt',
    cP_Type: 'CP_Type',
    cust_ID: 'Cust.ID',
    dateofDeath: 'DateofDeath',
    dPProductRelease: 'DPProductRelease',
    fNOLClaim: 'FNOLClaim',
    informant_FirstName: 'Informant_FirstName',
    informant_LastName: 'Informant_LastName',
    informantRelationship: 'InformantRelationship',
    insuredName: 'InsuredName',
    member_FirstName: 'Member_FirstName',
    member_LastName: 'Member_LastName',
    noticeReceivedDate: 'NoticeReceivedDate',
    noticeReportDate: 'NoticeReportDate',
    num_OfClaimsSubmittedPrev_Yr: 'Num.OfClaimsSubmittedPrev.Yr',
    ownerSite: 'OwnerSite',
    payeeName: 'PayeeName',
    paymentDate: 'PaymentDate',
    paymentType: 'PaymentType',
    personOrOrganization: 'PersonOrOrganization',
    phoneNumber: 'PhoneNumber',
    site: 'Site',
    surplus_Lines: 'Surplus_Lines',
    thresholdLevel: 'ThresholdLevel',
    notificationType: 'NotificationType',
    teamType: 'TeamType',
    annuityAdjuster: 'AnnuityAdjuster',
    deskCode: 'DeskCode',
    input_Individuals_Birth_Date: 'Input_Individuals_Birth_Date',
    children_In_Household_Count: 'Children_In_Household_Count',
    presence_Of_Senior_Adult_Flag: 'Presence_Of_Senior_Adult_Flag',
    college_Graduate_Flag: 'College_Graduate_Flag',
    marital_Status_Code: 'Marital_Status_Code',
    marital_Newlywed_Flag: 'Marital_Newlywed_Flag',
    education_Of_1st_Individual_Code: 'Education_Of_1st_Individual_Code',
    race_Code: 'Race_Code',
    estimated_Income_Code: 'Estimated_Income_Code',
    net_Worth_Code: 'Net_Worth_Code',
    stocks_Bonds_Flag: 'Stocks_Bonds_Flag',
    home_Owner_Or_Renter_Indicator: 'Home_Owner_Or_Renter_Indicator',
    military_Veteran_Flag: 'Military_Veteran_Flag',
    gender: 'Gender',
    birth_Date: 'Birth_Date',
    age: 'Age',
    age_Cats: 'Age_Cats',
    memberNumber: 'MemberNumber',
    state: 'State',
    short_claimnumber: 'short_claimnumber',
    fillingMethod: 'FillingMethod',
    adjusterFirstName: 'AdjusterFirstName'
};

export const headCells: readonly HeadCell[] = [
    {
        id: 'nameFirst',
        numeric: false,
        disablePadding: true,
        label: editFormLabels.borrowerNameFirst
    },
    {
        id: 'nameLast',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.borrowerNameLast
    },
    {
        id: 'distributorName',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.distributorName
    },
    {
        id: 'claimNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimNumber
    },
    {
        id: 'borrowerIdentifierKey',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimIdentifier
    },
    {
        id: 'claimCreateDate',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.dateFiled
    },
    {
        id: 'peril',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.lossType
    }
];
