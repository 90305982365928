import React, { ReactElement, useEffect, useState } from 'react';
import { SCAdminFaqContentWrapper, SCParagraph, SCUnorderedList, SCUnderlined, SCLeftColumnWrapper, SCRightColumnWrapper, SCAnswerImage } from './styles';
import { IAdminGuideItems, IAdminGuideJson } from './types';
import { $darkGray, SCContentSectionResponsiveWrapper, SCContentWrapper, SCEyebrow, SCFooterWrapper, SCPageWrapper, SCSectionWrapper, SCSubHead } from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';
import need_help_signing_in from 'assets/images/need_help_signing_in.jpg';
import table_column_headers from 'assets/images/table_column_headers.jpg';
import { Accordion } from 'components/molecules/Accordion';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { TSQuickLinks } from 'components/atoms/TSQuickLinks';

const AdminGuide = (): ReactElement => {
    const [adminGuideItems, setAdminGuideItems] = useState<IAdminGuideItems[]>();
    const quickLinks = [
        { title: 'General Information & Definitions', destination: 'groupId_generalinformation', arrivalOption: 'scroll' },
        { title: 'Job Losses', destination: 'groupId_jobLosses', arrivalOption: 'scroll' },
        { title: 'Disability', destination: 'groupId_disability', arrivalOption: 'scroll' },
        { title: 'General Coverage', destination: 'groupId_generalCoverage', arrivalOption: 'scroll' },
        { title: 'Certificate of Insurance', destination: 'groupId_certificateOfInsurance', arrivalOption: 'scroll' },
        { title: 'Data', destination: 'groupId_data', arrivalOption: 'scroll' },
        { title: 'Billing, Premium & Pricing', destination: 'groupId_billing_premium_pricing', arrivalOption: 'scroll' },
        { title: 'Claims', destination: 'groupId_claims', arrivalOption: 'scroll' },
        { title: 'Claims Management Portal', destination: 'groupId_claimsManagementPortal', arrivalOption: 'scroll' },
        { title: 'Lending', destination: 'groupId_lending', arrivalOption: 'scroll' }
    ];
    useEffect(() => {
        const data = (staticContent);
        setAdminGuideItems(data.admin_guide_collections);
    }, []);
    return (
        <>
            <SCPageWrapper>
                <SCContentWrapper>
                    <SCContentSectionResponsiveWrapper
                        flexDirection={FlexDirectionEnum.ROW}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'40px'}
                    >
                        <SCLeftColumnWrapper>
                            <SCContentSectionResponsiveWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCEyebrow>{'General Information & Definitions'}</SCEyebrow>
                                <SCSubHead textColor={$darkGray}>{'Administrative Guide FAQs'}</SCSubHead>
                                <SCParagraph>{'General information about TruStage™ Payment Guard Insurance.'}</SCParagraph>
                                {adminGuideItems &&
                                    adminGuideItems.map((data: IAdminGuideItems, index) => {
                                        return (
                                            <SCSectionWrapper key={`faq_item_${index}`}>
                                                <SCAdminFaqContentWrapper>
                                                    <Accordion
                                                        data={data.group_list}
                                                        id={data.id}
                                                        group_description={`${data.group_description}`}
                                                        group_label={`${data.group_label}`}
                                                    ></Accordion>
                                                </SCAdminFaqContentWrapper>
                                            </SCSectionWrapper>
                                        );
                                    })}
                            </SCContentSectionResponsiveWrapper>
                        </SCLeftColumnWrapper>
                        <SCRightColumnWrapper>
                            <TSQuickLinks
                                ariaLabel={'Administrative Guide Quick Links'}
                                id={'adminGuideQuickLinks'}
                                links={quickLinks}
                                title={'Administrative Guide'}
                            />
                        </SCRightColumnWrapper>
                    </SCContentSectionResponsiveWrapper>
                </SCContentWrapper>
                <SCFooterWrapper>
                    <GlobalFooter />
                </SCFooterWrapper>
            </SCPageWrapper>
        </>
    );
};

/** 
 * -Notes on Markup formatting below-
 *  I aimed to make the JSX-markup below as humanly-readable as possible - here are a few rules I followed for myself.
 *  1. Paragraph content always gets a new line.
 *  2. Inline styles (such as "<SCBlue>" , "<SCUnderline>" , and <b/>  - for blue, underlined, and bold font) are always defined inline with their content and adjacent content that is expected to also be in the same line.
 *  3. Content will be written using a bracket-plus-doublequote structure. Ex: <div>{"There's some readable content here."}</div> 
 *       - This behaves the best with all the content (such as the various apostrophes and occasional "< >" text that appears in the content.) Using this format resulted in no special exceptions needing to be made for any of the JSX-formatting.
 *  Note: I have marked this file in .prettierignore to be ignored from evaluation. The suggestions it was giving were ultimately making this file less readable.
 */

const WhatIsADefinedBenefitPayment = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"Providers offering TruStage™ Payment Guard, predetermine a coverage benefit. That benefit is considered a defined amount. When a claim is payable, the defined benefit will be paid to the distribution partner. The distribution partner is then responsible to apply the funds to the consumer's transaction accordingly."}
            </SCParagraph>
            <SCParagraph>
                {"Example: As a distribution partner, you set your defined limit as $500. Your customer has an advance balance of $300. If your customer loses their job involuntarily, then TruStage™ Payment Guard will pay the full defined benefit of $500."}
            </SCParagraph>
            <SCParagraph>
                {"Here's how the funds should be disbursed:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"$300 should be applied to the outstanding balance of the transaction"}</li>
                    <li>{"The customer will get the remaining benefit of $200."}</li>
                </SCUnorderedList>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhatTypesOfJobLossAreNotEligible = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"The following types of job loss are not eligible:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"We will not cover the following list of occupations:"}</li>
                    <SCUnorderedList>
                        <li>{"Contract worker"}</li>
                        <li>{"Self-Employed"}</li>
                    </SCUnorderedList>
                    <li>{"Borrowers laid off due to normal and routine shut down or workforce reduction as determined by their occupation, their place of employment or the job they were hired to do, including if they lose their job due to Seasonal Employment."}</li>
                    <li>{"Borrowers quit their job, retire from their job, or otherwise voluntarily leave their job."}</li>
                    <li>{"Borrowers lose their job due to a merger, change in ownership or outsourcing but the survivor of the merger, the new owner or outsource partner (i.e., their new employer) does not end their employment."}</li>
                    <li>{"Borrowers lose their job because the job they were hired into has been completed or the time period for which they were hired has ended."}</li>
                    <li>{"Borrowers lose their job due to their failure to meet their employer's expectations regarding work performance and work results."}</li>
                    <li>{"Borrowers lose their job due to their failure to meet their employer's reasonable expectations regarding workplace behavior, including but not limited to unapproved absences or lateness, insubordination, or harassment of others."}</li>
                    <li>{"Borrowers lose their job due to Willful Misconduct or Criminal Misconduct."}</li>
                    <li>{"Borrowers lose their job due to war, act of war or a nuclear or natural disaster."}</li>
                    <li>{"Borrowers lose their job due to a Total Disability [for which We paid a benefit]."}</li>
                </SCUnorderedList>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhatDisabilitiesAreNotCovered = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"We will not pay a benefit for a Total Disability that results directly or indirectly from:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"A normal pregnancy."}</li>
                    <li>{"War or any act of war."}</li>
                    <li>{"Elective surgery or elective timing of surgery for conditions that are known prior to the date the transaction being covered."}</li>
                </SCUnorderedList>
            </SCParagraph>
            <SCParagraph>
                {"We will not pay a benefit for the following causes of Total Disability:"}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"Hip replacement"}</li>
                    <li>{"Knee replacement"}</li>
                    <li>{"Carpal tunnel surgery"}</li>
                    <li>{"Rotator cuff surgery"}</li>
                    <li>{"Bunion surgery"}</li>
                </SCUnorderedList>
            </SCParagraph>
            <SCParagraph>
                {"If we have paid a Total Disability benefit to the borrower, we will not also pay a Job Loss benefit if their job is no longer available to them when they come off Total Disability and are again able to work."}
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhoWillBeSendingInvoices = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"You will receive invoices from our Surplus lines, M3. The contact for M3 is "}<SCUnderlined>{"Lauren.Zimmer@m3ins.com"}</SCUnderlined>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhatCanWeExpectOnInvoices = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"Your invoice will disclose the taxes and surplus lines charges (all included in your premium) as per insurance requirements."}
            </SCParagraph>
            <SCParagraph>
                {"Note: In CA, surplus charges are paid annually and upfront. This will impact how the item is presented on the invoice as it changes monthly."}
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const IsThereAWayToCheckClaimStatus = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"Yes, you may "}<a href={"/claim-lookup"}>Check Claim Status</a>{" to learn more about the status for a claim."}
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhereShouldWeDirectOurCustomersWithQuestions = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"All questions and comments from customers can be emailed to us at "}<SCUnderlined>{"paymentguard@trustage.com"}</SCUnderlined>{". Alternatively, we can be reached at 855-997-7272."}
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const HowDoISetupAUser = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"You can set up a user ID by messsaging "}<SCUnderlined>{"paymentguard@trustage.com"}</SCUnderlined>{" with Partner name and email address(es) to be set up with access."}
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const IForgotMyPassword = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"On the login page, click “Need help Signing in?” or “Forgot password?” and follow the steps."}
            </SCParagraph>
            <SCParagraph>
                <SCAnswerImage src={need_help_signing_in} maxHeight={'500px'} ></SCAnswerImage>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const IsThereASelfServiceClaimsPortal = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"Yes, the link below will take you to a claims management portal to check the status of a claim."}
            </SCParagraph>
            <SCParagraph>
                <SCUnorderedList>
                    <li><a href={"/admin"}>{"https://paymentguard.trustage.com/admin"}</a></li>
                    <li>{"Also found at the bottom of paymentguard.trustage.com “Administrator Login”"}</li>
                </SCUnorderedList>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhereCanIGetTheClaimNumber = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"The claim number and all claim details can be found in the claim reporting tool."}
            </SCParagraph>
            <SCParagraph>
                <a href={"/claims-management"}>{"https://paymentguard.trustage.com/claims-management"}</a>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const HowDoIViewMultipleClaimsSubmittedForOneUser = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                {"The claims reporting list view shows all claims. To sort by any of the column headings, click the arrow to the right of the column label. Visit "}<a href={"/claims-management"}>{"https://paymentguard.trustage.com/claims-management"}</a>
            </SCParagraph>
            <SCParagraph>
                <SCAnswerImage src={table_column_headers} maxHeight={'100px'} ></SCAnswerImage>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const WhatAreTheDifferentClaimStatuses = (): ReactElement => {
    return (
        <SCAdminFaqContentWrapper>
            <SCParagraph>
                <SCUnorderedList>
                    <li>{"Default message when the claim is filed and in an Open status:"}</li>
                    <li>{"Claim filing received, claim review will begin soon"}</li>
                    <li>{"If it is in a closed status additional information is provided to indicate if it was denied or approved and more details on each of those decisions is provided."}</li>
                    <li>{"It will be in an open status until a claim decision has been determined."}</li>
                    <li>{"Messages for Open claim statuses that the claim adjuster chooses once the claim is in review and additional information is needed:"}</li>
                    <SCUnorderedList>
                        <li>{"Requesting additional information from the employee"}</li>
                        <li>{"Requesting additional information from the healthcare provider"}</li>
                        <li>{"Requesting additional information from the customer"}</li>
                        <li>{"Requesting additional information from the Distributor"}</li>
                    </SCUnorderedList>
                </SCUnorderedList>
            </SCParagraph>
        </SCAdminFaqContentWrapper>
    );
};

const staticContent: IAdminGuideJson =
{
    admin_guide_collections: [
        {
            id: "groupId_generalinformation",
            group_name: "general_information",
            group_label: "General Information & Definitions",
            group_description: "",
            group_list: [
                {
                    label: "Who is TruStage™?",
                    content: "TruStage™ is the marketing name for TruStage Financial Group, Inc. its subsidiaries and affiliates. TruStage™ Payment Guard is underwritten by CUMIS Specialty Insurance Company, Inc.",
                    summary_order: 1
                },
                {
                    label: "What is TruStage™ Payment Guard?",
                    content: "TruStage™ Payment Guard Insurance is a unique and simple benefit that is designed to provide additional financial security by providing insurance benefits towards your loan if you lose your job through no fault of your own, such as a layoff, a job elimination, business closing or if you get hurt or sick and are unable to work.",
                    summary_order: 2
                },
                {
                    label: "What is a distribution partner?",
                    content: "A distribution partner is a business partner that has collaborated with TruStage™ to add Payment Guard coverage as a part of their business model. For the remainder of the Q&A, distributors and 'we' are used interchangeably.",
                    summary_order: 3
                },
                {
                    label: "What is a customer/consumer?",
                    content: "A Customer/consumer is considered the user of the distribution partner's business. A customer/consumer could also be considered a borrower, subscriber, applicant, member, etc.",
                },
                {
                    label: "What is a considered a covered transaction?",
                    content: "A covered transaction is any transaction in the distribution partner's user experience. Distribution partners determine what transactions are covered in their user experience. Examples of this may be: opening a subscription, generating a loan lead, a loan being funded, etc.",
                },
                {
                    label: "What is a defined benefit payment and how does it work?",
                    content: WhatIsADefinedBenefitPayment(),
                }
            ]
        },
        {
            id: "groupId_jobLosses",
            group_name: "job_losses",
            group_label: "Job Losses",
            group_description: "",
            group_list: [
                {
                    label: "What types of job losses are covered?",
                    content: "A Job Loss is covered if: (a) the loss of employment meets the definition of Job Loss as defined in the Certificate of Insurance; (b) the Job Loss starts more than 30 days after the certificate effective date; and (c) the Job Loss is not specifically excluded from coverage.",
                    summary_order: 1
                },
                {
                    label: "What types of job loss are not eligible?",
                    content: WhatTypesOfJobLossAreNotEligible(),
                    summary_order: 2
                }
            ]
        },
        {
            id: "groupId_disability",
            group_name: "disability",
            group_label: "Disability",
            group_description: '',
            group_list: [
                {
                    label: "What is a covered total disability?",
                    content: "A Total Disability is covered if: (a) The borrower’s disability meets the definition of Total Disability, (b) Total Disability starts more than 30 days after the certificate effective date, (c) Their physician has stated that their Total Disability is expected to last 30 consecutive days; and (d) the Total Disability is not specifically excluded from coverage.",
                    summary_order: 1
                },
                {
                    label: "What disabilities are not covered?",
                    content: WhatDisabilitiesAreNotCovered(),
                    summary_order: 2
                }
            ]
        },
        {
            id: "groupId_generalCoverage",
            group_name: "generalCoverage",
            group_label: "General Coverage",
            group_description: '',
            group_list: [
                {
                    label: "Is there any minimum income threshold required for the users to become eligible for the job loss and disability insurance?",
                    content: "There are no income requirements to be eligible for coverage. The only requirement for eligibility is that the consumer has a covered transaction for 30 days prior to filing a claim. An outstanding balance does need to exist in order for a claim to be paid.",
                    summary_order: 1
                },
                {
                    label: "Do independent workers/self-employed consumers qualify?",
                    content: "Self-employed and independent workers can qualify for disability coverage but not Job Loss coverage. Please refer to job loss definition.",
                    summary_order: 2
                }
            ]
        },
        {
            id: "groupId_certificateOfInsurance",
            group_name: "certificateOfInsurance",
            group_label: "Certificate Of Insurance",
            group_description: '',
            group_list: [
                {
                    label: "What is a Certificate of Insurance?",
                    content: "The Certificate of Insurance is a document included with the application that explains the terms and conditions of coverage as provided in the group policy.",
                    summary_order: 1
                },
                {
                    label: "What are the requirements when providing the Certificate of Insurance?",
                    content: "We recommend that the Certificate of Insurance is shared with the consumer at the time of the transaction. This document is also available to all the borrowers on the Payment Guard website.",
                    summary_order: 2
                },
                {
                    label: "Do we need to personalize the Certificate of Insurance? And do you need a copy of what was sent to the borrower?",
                    content: "The Certificate of Insurance is a generic insurance document that applies to all borrowers, and it does not need any personalization when being distributed to the borrower. You do not need to personalize this document before issuing it to your borrowers. We do not need a copy of the certificate or communication sent to the borrower.",
                    summary_order: 3
                }
            ]
        },
        {
            id: "groupId_data",
            group_name: "data",
            group_label: "Data",
            group_description: '',
            group_list: [
                {
                    label: "What is the expected timeline for reporting? Can we use one business day post origination and only report on business days?",
                    content: "The next business day would be ideal.",
                    summary_order: 1
                },
                {
                    label: "What type of data needs to be sent and do you have a data specification file?",
                    content: "Please refer to the Lending Data Extract Job Aid and Consumer Lending Data Dictionary for details.",
                    summary_order: 2
                },
                {
                    label: "How do I get access to upload a file to the Secured File Transfer Protocol (SFTP) website?",
                    content: "Please see the SFTP Connection Guide for more details.",
                    summary_order: 3
                },
                {
                    label: "How often does a data file need to be sent?",
                    content: "We request a daily file so we can have accurate and as close to real time data as possible. This data file with coverage and loan details will help us deliver a better borrower claim experience."
                },
                {
                    label: "Is it ok to send test data or masked data in the test file we upload to the SFTP demo site?",
                    content: "Yes, for testing purposes, it is OK to send over masked data in the test file. However, it is important that the fields have generic “like” data (names, numbers, etc.)."
                },
            ]
        },
        {
            id: "groupId_billing_premium_pricing",
            group_name: "billing_premium_pricing",
            group_label: "Billing, Premium & Pricing",
            group_description: '',
            group_list: [
                {
                    label: "How are premiums calculated?",
                    content: "Transactions are covered once they are funded (or in the instance of subscriptions, opened). Premium calculations are based on the data file we receive from you at the end of each month. Premiums are calculated on the outstanding consumer transaction balance or the defined benefit of coverage each month. Please refer to the rate sheet for specific details. Any premium changes will be communicated 30 days before going into effect.",
                    summary_order: 1
                },
                {
                    label: "Are premiums paid to TruStage monthly, or upfront?",
                    content: "TruStage™ will send an invoice monthly in arrears.",
                    summary_order: 2
                },
                {
                    label: "Who will be sending us invoices?",
                    content: WhoWillBeSendingInvoices(),
                    summary_order: 3
                },
                {
                    label: "What can we expect on the invoices?",
                    content: WhatCanWeExpectOnInvoices()
                },
                {
                    label: "How frequently are bills sent?",
                    content: "Invoices are sent out monthly by the 5th of each month."
                },
                {
                    label: "When is payment due?",
                    content: "Payment is due within 20 days of invoice receipt."
                },
                {
                    label: "How often will our cost be adjusted? Will a rate change impact existing transactions or just new transactions going forward?",
                    content: "All premium pricing changes and changes to cost per loan will be communicated 30 days before the effective date, and the change will be implemented on all portfolios on the effective date stated in the communication. Full transparency and regular touch bases with TruStage™ are key to the performance of your program."
                },
                {
                    label: "If premiums are paid monthly, does TruStage require us to set aside any type of reserve to cover future monthly premiums?",
                    content: "No reserving is required by TruStage™ on this coverage."
                }
            ]
        },
        {
            id: "groupId_claims",
            group_name: "claims",
            group_label: "Claims",
            group_description: '',
            group_list: [
                {
                    label: "Approximately how long will it take for a claim to be processed?",
                    content: "Once we have what's needed to adjudicate the claim, the claim decision is made within 10 calendar days.",
                    summary_order: 1
                },
                {
                    label: "Once approved, how quickly does the payment get sent?",
                    content: "Once a claim is approved, the funds will be delivered to you within the next 3 business days via Automated Clearing House (ACH).",
                    summary_order: 2
                },
                {
                    label: "Once we receive the claim payment, how should we apply it?",
                    content: "The claim payment should be applied in a timely fashion as one lump sum to the borrower's loan. Due to earned interest rules, you should not hold the payment to apply it monthly.",
                    summary_order: 3
                },
                {
                    label: "Is there a way we can check the status of a claim?",
                    content: IsThereAWayToCheckClaimStatus(),
                },
                {
                    label: "If the claim is denied, what should the consumer expect?",
                    content: "If a claim is denied, the consumer will be notified via email."
                },
                {
                    label: "Where should we direct our customers if they have a question or need to talk to someone?",
                    content: WhereShouldWeDirectOurCustomersWithQuestions()
                },
                {
                    label: "What if the consumer has the same type of Job Loss or Total Disability within the 12 month policy year?",
                    content: "If the same type of covered event (Job Loss or Disability) occurs within the same 12 month period, no additional benefit will be provided. However, if one covered event claim is paid, (e.g. Job Loss), the consumer may be eligible for a benefit under the other covered event (e.g. Disability). In any event, we always recommend that a claim is filed regardless of previous benefits received or overlap in the timeline."
                },
                {
                    label: "Can a customer file for a Disability and a Job Loss claim in the same 12 months?",
                    content: "Yes, the policy includes coverage for one disability claim and one Job Loss claim per policy year. Each claim will be reviewed based on the provisions within the Certificate of Insurance."
                },
                {
                    label: "Is there a Monthly Payment benefit maximum?",
                    content: "There is no monthly payment benefit maximum with TruStage™ Payment Guard. We will pay the full benefit (according to your Certificate of Insurance) in a lump sum to you. You then need to apply the benefit payment to the customer s outstanding balance. Any excess will need to be provided to the customer."
                },
            ]
        },
        {
            id: "groupId_claimsManagementPortal",
            group_name: "claimsManagementPortal",
            group_label: "Claims Management Portal",
            group_description: "Setting up and managing account.",
            group_list: [
                {
                    label: "What is the purpose of the claims management portal?",
                    content: "The claims management portal is a way that your team can check the status of a claim for your user or to assist your internal team on the progress of all claims submitted. This system is intended for internal information use only.",
                    summary_order: 1
                },
                {
                    label: "How do I setup a user ID/password to access the claims management portal?",
                    content: HowDoISetupAUser(),
                    summary_order: 2
                },
                {
                    label: "I forgot my password, how do I reset my password?",
                    content: IForgotMyPassword(),
                    summary_order: 3
                },
                {
                    label: "Can I file a claim on behalf of my user?",
                    content: "Yes, you may start a claim on behalf of the user by visiting your unique link. Once submitted, the user will need to continue the claim process by providing any applicable supporting details. Please direct the user to their email that will provide directions on next steps."
                },
                {
                    label: "What information do I need to provide on behalf of the user?",
                    content: "There is an initial claim form that is required to be completed with the user's employment and/or disability information that will support their claim."
                },
                {
                    label: "What if I do not have all the information to file the claim on behalf of the user?",
                    content: "The online form has some required and some optional questions. The intake cannot be completed if the user does not have all required information. You can direct the user to the email that will link to our online claims portal and they can complete the required information from there. We will reach out to the user to get additional information as necessary while reviewing the claim."
                },
                {
                    label: "What can the user expect once I have filed a claim on their behalf?",
                    content: "Once a claim has been filed by you, the user will receive an email indicating that a claim has been started on their behalf and they will be required to complete an action of confirming electronic consent. Once they complete this step, they will continue to receive all notifications on the claim status. In addition, you or your designated team member will receive a copy of all emails sent to the user."
                },
                {
                    label: "Will admin filing the claim get a confirmation of the claim filed on behalf of the user?",
                    content: "Yes, the admin will get a copy of all emails sent to the user."
                },
                {
                    label: "Is there a self service claims portal where I can check the status of a claim?",
                    content: IsThereASelfServiceClaimsPortal()
                },
                {
                    label: "Where can I get the claim number?",
                    content: WhereCanIGetTheClaimNumber()
                },
                {
                    label: "What if I do not have the last four of the [SSN/Phone number]?",
                    content: "If you do not have the details of the user to look up claims status, please reach out to paymentguard@trustage.com or via phone at (855) 997-7272"
                },
                {
                    label: "What if a claim decision has been made but the user is unhappy with the decision?",
                    content: "All users can reach out to our claims department via email at paymentguard@trustage.com or phone at 855-997-7272 to appeal their claims decision."
                },
                {
                    label: "What if the amount payable is not accurate?",
                    content: "Please reach out to our claims department via email at paymentguard@trustage.com or phone at (855) 997-7272 to report any discrepancies in the amount paid."
                },
                {
                    label: "How do I view multiple claims submitted for one user?",
                    content: HowDoIViewMultipleClaimsSubmittedForOneUser()
                },
                {
                    label: "What are the different claim statuses?",
                    content: WhatAreTheDifferentClaimStatuses()
                }
            ]
        },
        {
            id: "groupId_lending",
            group_name: "lending",
            group_label: "Lending",
            group_description: "",
            group_list: [
                {
                    label: "What happens if we do a loan modification for a borrower?",
                    content: "Another advantage to the way this product is structured is that we adjudicate benefit amounts based on the data you send to us (up to the maximums). This allows you to modify the loan seamlessly without impacting a benefit payout.",
                    summary_order: 1
                },
                {
                    label: "Can we send the Certificate of Insurance to the borrower outside the loan closing?",
                    content: "Yes, you can send the Certificate of Insurance to the borrower within a reasonable time period. If you send the certificate after the loan event, we do ask that we help you comply the language inside of the email.",
                    summary_order: 2
                },
                {
                    label: "If the borrower pays off the loan, or the loan is charged off, how do we report that to TruStage?",
                    content: "When a loan is paid off, charged off, etc. that loan will no longer be on the loan file sent to us for charging premium. Therefore, no premium would be charged on that loan going forward.",
                    summary_order: 3
                }
            ]
        }
    ]
};

export { AdminGuide };
