export enum TrackerEnum {
    PAGE_VIEW = 'page_view',
    HOME = 'HOME',
    CLAIM = 'CLAIM',
    CONTACT = 'CONTACT',
    DISTRIBUTOR_SELECT = 'distributor_select',
    CLAIMSTEP_START = 'Begin',
    CLAIMSTEP_PERSONAL_INFO = 'PersonalInfo',
    CLAIMSTEP_DISABILITY_DETAILS = 'DisabilityDetails',
    CLAIMSTEP_JOBLOSS_DETAILS = 'JoblossDetails',
    CLAIMSTEP_EMPLOYER_INFO = 'EmployerInfo',
    CLAIMSTEP_HEALTHCARE_INFO = 'HealthcareInfo',
    CLAIMSTEP_COMMENTS = 'Comments',
    CLAIMSTEP_REVIEW = 'Review',
    CLAIMSTEP_SUBMIT_ATTEMPT_FAILED = 'SubmitAttemptFailed',
    CLAIMSTEP_SUBMITTED = 'Submitted',
    PAYMENT_GUARD_CLAIMPROCESS = 'paymentGuardClaim',
    BORROWER_NAME_CHANGED = 'borrowerNameChanged',
    LAST_CLAIM_FIELD_CHANGED = 'lastClaimFieldChanged'
}
