import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { keysBorrowDetail, labelMapIU } from './fieldTypes';
import { IClientContextState } from 'types/client-context';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { FormIUStateContext } from 'context/formIUStateContext';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { IFormIUData, IFormIUState } from 'types/form';
import {
    $deepBlue,
    $digitalBlack,
    SCBodyLarge,
    SCContentDirectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCElementsWrapper,
    SCElementWrapper,
    SCErrorLabel,
    SCForm,
    SCFormNavigationWrapper,
    SCFormRefWrapper,
    SCFormTopNavWrapper,
    SCFormWrapper,
    SCRadioWrapper,
    SCSectionBody,
    SCSectionSubHead,
    SCSectionWrapper,
    SCSubHead
} from 'styles/global-styles';
import { IFormIUProps } from './types';
import { IFormJoblossProgressState } from 'types/form-progress';
import { TimelineState } from 'enums/TimeLine';
import {
    validateDate,
    validateEmail,
    validatePhoneNumber,
    validateLast4SSN,
    validateZip,
    errorMapIU
} from 'utils/form/form';
import { FormNavigation } from 'components/molecules/FormNavigation';
import { stateOptionDict } from 'support/stateDictionary';
import { TSFormProgressStepper } from 'components/molecules/TSFormProgressStepper';
import { TSBackNavigation } from 'components/molecules/TSBackNavigation';
import { MaxFieldLengths } from 'enums/MaxFieldLengths';
import { TSTextField } from 'components/atoms/TSTextField';
import { TSMaskedTextField } from 'components/atoms/TSMaskedTextField';
import { TSAutocomplete } from 'components/atoms/TSAutocomplete';
import { TSRadioGroup } from 'components/atoms/TSRadioGroup';
import { SCChangeFormType } from './styles';
import { ClaimTypesEnum, TrackerEnum } from 'enums';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum, FormFieldWidthEnum } from 'enums/StyleTypes';
import { TSDialog } from 'components/atoms/TSDialog';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { Routes } from 'routes';
import { BeforeUnload } from 'components/molecules/BeforeUnload';
import { ClaimStateEnum } from 'enums/ClaimState';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { FormTypeContext } from 'context/formTypeContext';
import { IFormTypeState } from 'types/claim-types';
import { YesNoAutocompleteOptions, YesNoRadioOptions } from 'support/claimForm';
import { isPhoneIdentifierType } from 'utils/helpers/helpers';

const FormIU = ({ changeFormType }: IFormIUProps): ReactElement => {
    const fieldRef = useRef<HTMLInputElement>(null);
    const { saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const { saveFormIUState, formIUState } = React.useContext(FormIUStateContext) as IFormIUState;
    const { updateFormJoblossProgressState, formJoblossProgressState } = React.useContext(
        FormJoblossProgressStateContext
    ) as IFormJoblossProgressState;
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const [emailInputError, setEmailInputError] = useState<string | null>(null);
    const [zipInputError, setZipInputError] = useState<string | null>(null);
    const [phoneNumberInputError, setPhoneNumberInputError] = useState<string | null>(null);
    const [ssnInputError, setSsnInputError] = useState<string | null>(null);
    const [dateOfBirthInputError, setDateOfBirthInputError] = useState<string | null>(null);
    const [dateOfJobLossError, setDateOfJobLossError] = useState<string | null>(null);
    const [dateOfNotifiedJobLossError, setDateOfNotifiedJobLossError] = useState<string | null>(null);
    const [dateOfLastWorkedError, setDateOfLastWorkedError] = useState<string | null>(null);
    const [employerZipInputError, setEmployerZipInputError] = useState<string | null>(null);
    const [employerPhoneNumberInputError, setEmployerPhoneNumberInputError] = useState<string | null>(null);
    const [employerEmailInputError, setEmployerEmailInputError] = useState<string | null>(null);
    const [submitterPhoneNumberInputError, setSubmitterPhoneNumberInputError] = useState<string | null>(null);
    const [submitterEmailInputError, setSubmitterEmailInputError] = useState<string | null>(null);
    const [ssnInputType, setSsnInputType] = useState<string>('text');
    const [onBehalfOf, setOnBehalfOf] = useState<boolean>(false);
    const [formData, setFormData] = React.useState<IFormIUData | Record<string, unknown>>();
    const [qualifyForStateUnemployment, setQualifyForStateUnemployment] = useState<string | undefined>();
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const [formDeepCopy, setFormDeepCopy] = React.useState<IFormIUData | Record<string, unknown>>();
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [isPersonalInfoActive, setIsPersonalInfoActive] = useState(false);
    const [isJoblossDetailsActive, setIsJoblossDetailsActive] = useState(false);
    const [isEmployerInfoActive, setIsEmployerInfoActive] = useState(false);
    const [isCommentsActive, setIsCommentsActive] = useState(false);
    const [isBorrowerFirstAndLastPopulated, setIsBorrowerFirstAndLastPopulated] = useState(false);
    useEffect(() => {
        const contextStateClaimEditDeepCopy = structuredClone(formData);
        setFormDeepCopy(contextStateClaimEditDeepCopy);
    }, [formData]);
    useEffect(() => {
        formDeepCopy && JSON.stringify(formDeepCopy) !== JSON.stringify(formData) && setIsFormDirty(true);
    }, [formData, formDeepCopy]);
    const allowedRoutes = [
        `${window.location.origin}${Routes.CALLBACK}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}/default/v1/logout?id_token_hint`
    ];
    const stateOptions = Object.keys(stateOptionDict);
    const yesNoAutocompleteOptionValues = Object.keys(YesNoAutocompleteOptions);
    const handleForm = (formEvent: React.FormEvent<HTMLInputElement>): void => {
        if (
            formEvent.currentTarget.id === 'borrowerMailingState' ||
            formEvent.currentTarget.id === 'disabilityHealthcareState' ||
            formEvent.currentTarget.id === 'employerState'
        ) {
            formEvent.currentTarget.value = formEvent.currentTarget.value.toUpperCase();
        }
        setFormData({
            ...formData,
            [formEvent.currentTarget.id]: formEvent.currentTarget.value
        });
        dispatchLastFieldChangedEvent(formEvent.currentTarget.id);
    };
    const handleEmailInput = (name: string, value: string): void => {
        if (name === 'borrowerEmail') {
            value.length > 0 && !validateEmail(value)
                ? setEmailInputError(errorMapIU['emailError'])
                : setEmailInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'employerEmail') {
            value.length > 0 && !validateEmail(value)
                ? setEmployerEmailInputError(errorMapIU['emailError'])
                : setEmployerEmailInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'submitterEmail') {
            value.length > 0 && !validateEmail(value)
                ? setSubmitterEmailInputError(errorMapIU['emailError'])
                : setSubmitterEmailInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handleSSNInput = (name: string, value: string): void => {
        value.length > 0 && !validateLast4SSN(value)
            ? setSsnInputError(errorMapIU['ssnError'])
            : setSsnInputError(null);
        setFormData({
            ...formData,
            [name]: value
        });
        dispatchLastFieldChangedEvent(name);
    };
    const handleDateInput = (name: string, value: string): void => {
        if (name === 'iuDateOfJobLoss') {
            value.length > 0 && !validateDate(value)
                ? setDateOfJobLossError(errorMapIU['dateError'])
                : setDateOfJobLossError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'iuDateOfJobLossNotification') {
            value.length > 0 && !validateDate(value)
                ? setDateOfNotifiedJobLossError(errorMapIU['dateError'])
                : setDateOfNotifiedJobLossError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'iuDateLastDayWorked') {
            value.length > 0 && !validateDate(value)
                ? setDateOfLastWorkedError(errorMapIU['dateError'])
                : setDateOfLastWorkedError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'borrowerDateOfBirth') {
            value.length > 0 && !validateDate(value)
                ? setDateOfBirthInputError(errorMapIU['dateError'])
                : setDateOfBirthInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handleZIPInput = (name: string, value: string): void => {
        if (name === 'borrowerMailingZip') {
            value.length > 0 && !validateZip(value) ? setZipInputError(errorMapIU['zipError']) : setZipInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'employerZip') {
            value.length > 0 && !validateZip(value)
                ? setEmployerZipInputError(errorMapIU['zipError'])
                : setEmployerZipInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handlePhoneNumberInput = (name: string, value: string): void => {
        if (name === 'borrowerPhone') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setPhoneNumberInputError(errorMapIU['phoneError'])
                : setPhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'employerContactNumber') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setEmployerPhoneNumberInputError(errorMapIU['phoneError'])
                : setEmployerPhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        } else if (name === 'submitterPhone') {
            value.length > 0 && !validatePhoneNumber(value)
                ? setSubmitterPhoneNumberInputError(errorMapIU['phoneError'])
                : setSubmitterPhoneNumberInputError(null);
            setFormData({
                ...formData,
                [name]: value
            });
            dispatchLastFieldChangedEvent(name);
        }
    };
    const handleRadioInput = (
        name: string,
        radioValue: string | boolean,
        dependentPropertiesToReset: string[] = []
    ): void => {
        setFormData({
            ...formData,
            [name]: radioValue,
            ...Object.fromEntries(dependentPropertiesToReset.map((prop) => [prop, null]))
        });
        dispatchLastFieldChangedEvent(name);
    };
    const handleAutocompleteInput = (name: string, value: string, dependentPropertiesToReset: string[] = []): void => {
        setFormData({
            ...formData,
            [name]: value,
            ...Object.fromEntries(dependentPropertiesToReset.map((prop) => [prop, null]))
        });
        dispatchLastFieldChangedEvent(name);
    };
    const dispatchLastFieldChangedEvent = (nameOfLastFieldChanged: string): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.LAST_CLAIM_FIELD_CHANGED, {
                detail: {
                    claimType: ClaimTypesEnum.Jobloss,
                    distributorName: clientContextDataState.payload.distributorName,
                    lastClaimFieldChanged: nameOfLastFieldChanged
                }
            })
        );
    };
    const dispatchClaimProgressEvent = (nameOfClaimStep: string): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, {
                detail: {
                    claimType: ClaimTypesEnum.Jobloss,
                    distributorName: clientContextDataState.payload.distributorName,
                    id: nameOfClaimStep
                }
            })
        );
    };
    const dispatchBorrowerNameChangedEvent = (isBorrowerFirstAndLastPopulated: boolean): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.BORROWER_NAME_CHANGED, {
                detail: {
                    claimType: ClaimTypesEnum.Jobloss,
                    distributorName: clientContextDataState.payload.distributorName,
                    isBorrowerFirstLastNamePopulated: isBorrowerFirstAndLastPopulated
                }
            })
        );
    };
    useEffect(() => {
        if (formData?.iuQualifyForStateUnemployment) {
            setQualifyForStateUnemployment(formData?.iuQualifyForStateUnemployment as string);
        }
    }, [formData?.iuQualifyForStateUnemployment]);
    useEffect(() => {
        setOnBehalfOf(formData?.iuFiledOnBehalfOf === 'yes');
    }, [formData?.iuFiledOnBehalfOf]);
    const handleSaveFormState = (event: React.FormEvent, formData: IFormIUData | any): void => {
        saveFormIUState(formData);
    };
    useEffect(() => {
        if (fieldRef.current && formJoblossProgressState[0].status !== TimelineState.ACTIVE) {
            fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setIsPersonalInfoActive(formJoblossProgressState[0].status == TimelineState.ACTIVE);
        setIsJoblossDetailsActive(formJoblossProgressState[1].status == TimelineState.ACTIVE);
        setIsEmployerInfoActive(formJoblossProgressState[2].status == TimelineState.ACTIVE);
        setIsCommentsActive(formJoblossProgressState[3].status == TimelineState.ACTIVE);
    }, [formJoblossProgressState]);
    useEffect(() => {
        if (isPersonalInfoActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_PERSONAL_INFO);
        }
    }, [isPersonalInfoActive]);
    useEffect(() => {
        if (isJoblossDetailsActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_JOBLOSS_DETAILS);
        }
    }, [isJoblossDetailsActive]);
    useEffect(() => {
        if (isEmployerInfoActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_EMPLOYER_INFO);
        }
    }, [isEmployerInfoActive]);
    useEffect(() => {
        if (isCommentsActive) {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_COMMENTS);
        }
    }, [isCommentsActive]);
    useEffect(() => {
        if (formData?.borrowerNameFirst && formData?.borrowerNameLast) {
            setIsBorrowerFirstAndLastPopulated(true);
        } else {
            setIsBorrowerFirstAndLastPopulated(false);
        }
    }, [formData?.borrowerNameFirst, formData?.borrowerNameLast]);
    useEffect(() => {
        dispatchBorrowerNameChangedEvent(isBorrowerFirstAndLastPopulated);
    }, [isBorrowerFirstAndLastPopulated]);
    useEffect(() => {
        const formStatusAllComplete = formJoblossProgressState.some((obj) => obj.status === TimelineState.COMPLETE);
        if (formStatusAllComplete) {
            return;
        } else {
            updateFormJoblossProgressState('id-1', TimelineState.ACTIVE);
        }
    }, []);
    useEffect(() => {
        const comboBoxButtons = fieldRef.current?.getElementsByClassName('Combobox__menu-toggle');
        if (typeof comboBoxButtons !== 'undefined') {
            Array.from(comboBoxButtons).forEach((el) => el.setAttribute('type', 'button'));
        }
    }, [formJoblossProgressState, formData]);
    useEffect(() => {
        setFormData(formIUState);
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormIU:::::::::::::::::::::::::::::::::',
    //     "\n::fieldRef.current?.getElementsByClassName('Combobox__menu-toggle')::",
    //     fieldRef.current?.getElementsByClassName('Combobox__menu-toggle'),
    //     '\n::distributorConfigurationDataState::',
    //     distributorConfigurationDataState,
    //     '\n::isFormDirty::',
    //     isFormDirty,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCContentWrapper>
            <SCContentDirectionWrapper
                flexDirection={FlexDirectionEnum.COLUMN}
                flexAlignment={FlexAlignItemsEnum.FLEX_START}
            >
                <SCContentSectionResponsiveWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexGap={'24px'}
                    sectionWidth={'100%'}
                >
                    <SCFormRefWrapper ref={fieldRef}>
                        <SCFormTopNavWrapper>
                            <TSBackNavigation isFormDirty={isFormDirty}></TSBackNavigation>
                            <TSFormProgressStepper></TSFormProgressStepper>
                        </SCFormTopNavWrapper>
                        <SCSubHead textColor={$deepBlue}>File a Claim: Job Loss</SCSubHead>
                        {formJoblossProgressState[0].status === TimelineState.ACTIVE && (
                            <SCSectionWrapper>
                                {clientContextDataState?.payload?.client_content?.claim_options.find(
                                    (x) => x.value == ClaimTypesEnum.Disability
                                ) && (
                                    <SCChangeFormType
                                        onClick={(): void => {
                                            isFormDirty
                                                ? setConfirmModalIsVisible(true)
                                                : changeFormType && changeFormType();
                                            document.dispatchEvent(
                                                new CustomEvent(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, {
                                                    detail: {
                                                        claimType: ClaimTypesEnum.Disability,
                                                        distributorName: clientContextDataState.payload.distributorName,
                                                        id: TrackerEnum.CLAIMSTEP_START
                                                    }
                                                })
                                            );
                                        }}
                                    >
                                        Do you need to file a disability claim instead? Click here.
                                    </SCChangeFormType>
                                )}
                                <SCSectionWrapper>
                                    <SCBodyLarge>
                                        Please enter in the following information so we can better understand your
                                        situation.
                                    </SCBodyLarge>
                                    <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                    <SCFormWrapper>
                                        <form
                                            id={'borrower'}
                                            onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                                handleSaveFormState(e, formData)
                                            }
                                            autoComplete={'on'}
                                            className={SCForm}
                                        >
                                            <SCSectionSubHead>Personal Information</SCSectionSubHead>
                                            <SCElementsWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'borrowerNameFirst'}
                                                        ariaLabel={labelMapIU['borrowerNameFirst']}
                                                        label="First Name"
                                                        value={formData?.borrowerNameFirst as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['borrowerNameFirst']}`}
                                                        maxLength={MaxFieldLengths.FIRST_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'borrowerNameLast'}
                                                        ariaLabel={labelMapIU['borrowerNameLast']}
                                                        label="Last Name"
                                                        value={formData?.borrowerNameLast as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['borrowerNameLast']}`}
                                                        maxLength={MaxFieldLengths.LAST_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        ariaLabel={labelMapIU['borrowerEmail']}
                                                        id={'borrowerEmail'}
                                                        label="Email Address"
                                                        type="email"
                                                        value={formData?.borrowerEmail as string}
                                                        onChange={(
                                                            formEvent: React.FormEvent<HTMLInputElement>
                                                        ): void => {
                                                            handleEmailInput(
                                                                'borrowerEmail',
                                                                formEvent.currentTarget.value
                                                            );
                                                        }}
                                                        placeholder={`Enter ${labelMapIU['borrowerEmail']}`}
                                                        maxLength={MaxFieldLengths.EMAIL}
                                                    ></TSTextField>
                                                    <SCErrorLabel>{emailInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSMaskedTextField
                                                        id={'borrowerPhone'}
                                                        ariaLabel={labelMapIU['borrowerPhone']}
                                                        label="Phone Number"
                                                        value={formData?.borrowerPhone as string}
                                                        onChange={(event): void => {
                                                            handlePhoneNumberInput('borrowerPhone', event.target.value);
                                                        }}
                                                        placeholder={`Enter ${labelMapIU['borrowerPhone']}`}
                                                    ></TSMaskedTextField>
                                                    <SCErrorLabel>{phoneNumberInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                                {!isPhoneIdentifierType(
                                                    clientContextDataState?.payload.borrowerIdentifierType as number
                                                ) && (
                                                    <>
                                                        <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                            <TSTextField
                                                                ariaLabel={labelMapIU['borrowerSSN']}
                                                                id="borrowerSSN"
                                                                label={labelMapIU['borrowerSSN']}
                                                                type={ssnInputType}
                                                                value={formData?.borrowerSSN as string}
                                                                onChange={(
                                                                    formEvent: React.FormEvent<HTMLInputElement>
                                                                ): void => {
                                                                    handleSSNInput(
                                                                        'borrowerSSN',
                                                                        formEvent.currentTarget.value
                                                                    );
                                                                }}
                                                                onFocus={(event): void => {
                                                                    setSsnInputType('text');
                                                                }}
                                                                onBlur={(event): void => {
                                                                    setSsnInputType('password');
                                                                }}
                                                                placeholder={`Enter ${labelMapIU['borrowerSSN']}`}
                                                                maxLength={MaxFieldLengths.SSN}
                                                            ></TSTextField>
                                                            <SCErrorLabel>{ssnInputError}</SCErrorLabel>
                                                        </SCElementWrapper>

                                                        <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                            We need this to find your policy
                                                        </SCElementWrapper>
                                                    </>
                                                )}
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSMaskedTextField
                                                        name={'dateOfBirth'}
                                                        fieldType="date"
                                                        id={'borrowerDateOfBirth'}
                                                        value={formData?.borrowerDateOfBirth as string}
                                                        ariaLabel={labelMapIU['borrowerDateOfBirth']}
                                                        label={labelMapIU['borrowerDateOfBirth']}
                                                        onChange={(event): void => {
                                                            handleDateInput('borrowerDateOfBirth', event.target.value);
                                                        }}
                                                        placeholder={`Enter ${labelMapIU['borrowerDateOfBirth']}`}
                                                    ></TSMaskedTextField>
                                                    <SCErrorLabel>{dateOfBirthInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    We need this to find your policy
                                                </SCElementWrapper>
                                            </SCElementsWrapper>
                                            <SCSectionSubHead>Mailing Address</SCSectionSubHead>
                                            <SCElementsWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                    <TSTextField
                                                        ariaLabel={labelMapIU['borrowerMailingAddress']}
                                                        id={'borrowerMailingAddress'}
                                                        label="Mailing Address"
                                                        value={formData?.borrowerMailingAddress as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['borrowerMailingAddress']}`}
                                                        maxLength={MaxFieldLengths.ADDRESS}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'borrowerMailingCity'}
                                                        ariaLabel={labelMapIU['borrowerMailingCity']}
                                                        label="City"
                                                        value={formData?.borrowerMailingCity as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['borrowerMailingCity']}`}
                                                        maxLength={MaxFieldLengths.CITY}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSAutocomplete
                                                        id="borrowerMailingState"
                                                        options={stateOptions}
                                                        getOptionLabel={(abbreviation): string =>
                                                            stateOptionDict[abbreviation]
                                                        }
                                                        value={formData?.borrowerMailingState}
                                                        label="State"
                                                        onChange={(event, value, reason): void => {
                                                            handleAutocompleteInput('borrowerMailingState', value);
                                                        }}
                                                    ></TSAutocomplete>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSMaskedTextField
                                                        ariaLabel={labelMapIU['borrowerMailingZip']}
                                                        id={'borrowerMailingZip'}
                                                        fieldType="zipCode"
                                                        label="Zip"
                                                        value={formData?.borrowerMailingZip as string}
                                                        onChange={(event): void => {
                                                            handleZIPInput('borrowerMailingZip', event.target.value);
                                                        }}
                                                        placeholder={`Enter ${labelMapIU['borrowerMailingZip']}`}
                                                    ></TSMaskedTextField>
                                                    <SCErrorLabel>{zipInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                            </SCElementsWrapper>
                                            <SCFormNavigationWrapper>
                                                {formJoblossProgressState.some(
                                                    (e) => e.status === TimelineState.ACTIVE
                                                ) && (
                                                    <FormNavigation
                                                        formData={formData}
                                                        isFormDirty={isFormDirty}
                                                        requiredFields={
                                                            !isPhoneIdentifierType(
                                                                clientContextDataState?.payload
                                                                    .borrowerIdentifierType as number
                                                            )
                                                                ? keysBorrowDetail
                                                                : [
                                                                      'borrowerNameFirst',
                                                                      'borrowerNameLast',
                                                                      'borrowerEmail',
                                                                      'borrowerPhone',
                                                                      'borrowerMailingAddress',
                                                                      'borrowerMailingCity',
                                                                      'borrowerMailingState',
                                                                      'borrowerMailingZip',
                                                                      'borrowerDateOfBirth'
                                                                  ]
                                                        }
                                                        validationFields={[
                                                            !emailInputError,
                                                            !ssnInputError,
                                                            !phoneNumberInputError,
                                                            !zipInputError,
                                                            !dateOfBirthInputError
                                                        ]}
                                                    />
                                                )}
                                            </SCFormNavigationWrapper>
                                        </form>
                                    </SCFormWrapper>
                                </SCSectionWrapper>
                            </SCSectionWrapper>
                        )}
                        {isJoblossDetailsActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    We’ve got your personal details saved. Next, please enter in the following claim
                                    information regarding your job loss so we can better understand your situation.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCElementsWrapper>
                                            <SCSectionSubHead>Claim Details</SCSectionSubHead>
                                        </SCElementsWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name="date"
                                                    fieldType="date"
                                                    id={'iuDateOfJobLoss'}
                                                    value={formData?.iuDateOfJobLoss as string}
                                                    ariaLabel={labelMapIU['iuDateOfJobLoss']}
                                                    label={labelMapIU['iuDateOfJobLoss']}
                                                    onChange={(event): void => {
                                                        handleDateInput('iuDateOfJobLoss', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['iuDateOfJobLoss']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfJobLossError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name="date"
                                                    fieldType="date"
                                                    id={'iuDateOfJobLossNotification'}
                                                    value={formData?.iuDateOfJobLossNotification as string}
                                                    ariaLabel={labelMapIU['iuDateOfJobLossNotification']}
                                                    label={labelMapIU['iuDateOfJobLossNotification']}
                                                    onChange={(event): void => {
                                                        handleDateInput(
                                                            'iuDateOfJobLossNotification',
                                                            event.target.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['iuDateOfJobLossNotification']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfNotifiedJobLossError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSMaskedTextField
                                                    name="date"
                                                    fieldType="date"
                                                    id={'iuDateLastDayWorked'}
                                                    value={formData?.iuDateLastDayWorked as string}
                                                    ariaLabel={labelMapIU['iuDateLastDayWorked']}
                                                    label={labelMapIU['iuDateLastDayWorked']}
                                                    onChange={(event): void => {
                                                        handleDateInput('iuDateLastDayWorked', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['iuDateLastDayWorked']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{dateOfLastWorkedError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    id={'iuJobLossReason'}
                                                    value={formData?.iuJobLossReason as string}
                                                    ariaLabel={labelMapIU['iuJobLossReason']}
                                                    label={labelMapIU['iuJobLossReason']}
                                                    onChange={(event): void => {
                                                        handleForm(event as any);
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['iuJobLossReason']}`}
                                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                                    multiline={true}
                                                    minRows={5}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSTextField
                                                    id={'iuOccupationAtTimeOfSeparation'}
                                                    label={labelMapIU['iuOccupationAtTimeOfSeparation']}
                                                    value={formData?.iuOccupationAtTimeOfSeparation as string}
                                                    ariaLabel={labelMapIU['iuOccupationAtTimeOfSeparation']}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapIU['iuOccupationAtTimeOfSeparation']}`}
                                                    maxLength={MaxFieldLengths.JOBLOSS_OCCUPATION}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementsWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <SCRadioWrapper>
                                                        <TSRadioGroup
                                                            radioGroupValues={YesNoRadioOptions}
                                                            radioGroupLabel={labelMapIU['iuIsLayoffTypicalAtThisTime']}
                                                            value={formData?.iuIsLayoffTypicalAtThisTime}
                                                            onChange={(event): void => {
                                                                handleRadioInput(
                                                                    'iuIsLayoffTypicalAtThisTime',
                                                                    event.target.value
                                                                );
                                                            }}
                                                            id="iuIsLayoffTypicalAtThisTime"
                                                        ></TSRadioGroup>
                                                    </SCRadioWrapper>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <SCRadioWrapper>
                                                        <TSRadioGroup
                                                            radioGroupValues={YesNoRadioOptions}
                                                            radioGroupLabel={labelMapIU['iuKnowledgeOfLayoffWhenHired']}
                                                            value={formData?.iuKnowledgeOfLayoffWhenHired}
                                                            onChange={(event): void => {
                                                                handleRadioInput(
                                                                    'iuKnowledgeOfLayoffWhenHired',
                                                                    event.target.value
                                                                );
                                                            }}
                                                            id="iuKnowledgeOfLayoffWhenHired"
                                                        ></TSRadioGroup>
                                                    </SCRadioWrapper>
                                                </SCElementWrapper>
                                            </SCElementsWrapper>
                                            <SCElementsWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <SCRadioWrapper>
                                                        <TSRadioGroup
                                                            radioGroupValues={YesNoRadioOptions}
                                                            radioGroupLabel={labelMapIU['iuSelfEmployed']}
                                                            value={formData?.iuSelfEmployed}
                                                            onChange={(event): void => {
                                                                handleRadioInput('iuSelfEmployed', event.target.value);
                                                            }}
                                                            id="iuSelfEmployed"
                                                        ></TSRadioGroup>
                                                    </SCRadioWrapper>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <SCRadioWrapper>
                                                        <TSRadioGroup
                                                            radioGroupValues={YesNoRadioOptions}
                                                            radioGroupLabel={
                                                                labelMapIU['iuQualifyForStateUnemployment']
                                                            }
                                                            value={formData?.iuQualifyForStateUnemployment}
                                                            onChange={(event): void => {
                                                                handleRadioInput(
                                                                    'iuQualifyForStateUnemployment',
                                                                    event.target.value,
                                                                    ['iuRejectionStateBenefitsReason']
                                                                );
                                                            }}
                                                            id="iuQualifyForStateUnemployment"
                                                        ></TSRadioGroup>
                                                    </SCRadioWrapper>
                                                </SCElementWrapper>
                                            </SCElementsWrapper>
                                            {qualifyForStateUnemployment === 'no' && (
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                    <TSTextField
                                                        id={'iuRejectionStateBenefitsReason'}
                                                        value={formData?.iuRejectionStateBenefitsReason as string}
                                                        ariaLabel={labelMapIU['iuRejectionStateBenefitsReason']}
                                                        label={labelMapIU['iuRejectionStateBenefitsReason']}
                                                        onChange={(event: unknown): void => {
                                                            handleForm(event as React.FormEvent<HTMLInputElement>);
                                                        }}
                                                        placeholder={`${labelMapIU['iuRejectionStateBenefitsReason']}`}
                                                        maxLength={MaxFieldLengths.GENERAL_MAX}
                                                        multiline={true}
                                                        minRows={5}
                                                    />
                                                </SCElementWrapper>
                                            )}
                                        </SCElementsWrapper>
                                        <SCFormNavigationWrapper>
                                            {formJoblossProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={[
                                                        'iuDateOfJobLoss',
                                                        'iuDateOfJobLossNotification',
                                                        'iuJobLossReason',
                                                        'iuDateLastDayWorked',
                                                        'iuOccupationAtTimeOfSeparation'
                                                    ]}
                                                    validationFields={[
                                                        !dateOfJobLossError,
                                                        !dateOfNotifiedJobLossError,
                                                        !dateOfLastWorkedError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                        {isEmployerInfoActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    Great, we’ve got your claim details recorded. Next, please provide us with your
                                    employer’s contact information (for example, the corporate office, a manager, or the
                                    HR department.) If we need to contact them during the claim review, we will notify
                                    you to obtain your consent to do so.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCElementWrapper widthAmount={FormFieldWidthEnum.QUADRUPLE}>
                                            <SCSectionSubHead> Employer Details</SCSectionSubHead>
                                            <SCBodyLarge>
                                                We may need to obtain information from your employer to confirm your
                                                employment information.
                                            </SCBodyLarge>
                                        </SCElementWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'employerName'}
                                                    label={labelMapIU['employerName']}
                                                    value={formData?.employerName as string}
                                                    ariaLabel={labelMapIU['employerName']}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapIU['employerName']}`}
                                                    maxLength={MaxFieldLengths.COMPANY_NAME}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'employerEmail'}
                                                    value={formData?.employerEmail as string}
                                                    ariaLabel={labelMapIU['employerEmail']}
                                                    label={labelMapIU['employerEmail']}
                                                    onChange={(formEvent: React.FormEvent<HTMLInputElement>): void => {
                                                        handleEmailInput(
                                                            'employerEmail',
                                                            formEvent.currentTarget.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['employerEmail']}`}
                                                    maxLength={MaxFieldLengths.EMAIL}
                                                ></TSTextField>
                                                <SCErrorLabel>{employerEmailInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSMaskedTextField
                                                    fieldType="phone"
                                                    id={'employerContactNumber'}
                                                    value={formData?.employerContactNumber as string}
                                                    ariaLabel={labelMapIU['employerContactNumber']}
                                                    label={labelMapIU['employerContactNumber']}
                                                    onChange={(event): void => {
                                                        handlePhoneNumberInput(
                                                            'employerContactNumber',
                                                            event.target.value
                                                        );
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['employerContactNumber']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{employerPhoneNumberInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    id={'employerAddress'}
                                                    type="email"
                                                    label={labelMapIU['employerAddress']}
                                                    value={formData?.employerAddress as string}
                                                    ariaLabel={labelMapIU['employerAddress']}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapIU['employerAddress']}`}
                                                    maxLength={MaxFieldLengths.ADDRESS}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSTextField
                                                    id={'employerCity'}
                                                    label={labelMapIU['employerCity']}
                                                    value={formData?.employerCity as string}
                                                    ariaLabel={labelMapIU['employerCity']}
                                                    onChange={handleForm}
                                                    placeholder={`Enter ${labelMapIU['employerCity']}`}
                                                    maxLength={MaxFieldLengths.CITY}
                                                ></TSTextField>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSAutocomplete
                                                    id="employerState"
                                                    label={labelMapIU['employerState']}
                                                    options={stateOptions}
                                                    getOptionLabel={(abbreviation): string =>
                                                        stateOptionDict[abbreviation]
                                                    }
                                                    value={formData?.employerState}
                                                    onChange={(event, value, reason): void => {
                                                        handleAutocompleteInput('employerState', value);
                                                    }}
                                                ></TSAutocomplete>
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                                <TSMaskedTextField
                                                    ariaLabel={labelMapIU['employerZip']}
                                                    id={'employerZip'}
                                                    fieldType="zipCode"
                                                    label="Zip"
                                                    value={formData?.employerZip as string}
                                                    onChange={(event): void => {
                                                        handleZIPInput('employerZip', event.target.value);
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['employerZip']}`}
                                                ></TSMaskedTextField>
                                                <SCErrorLabel>{employerZipInputError}</SCErrorLabel>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        <SCFormNavigationWrapper>
                                            {formJoblossProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={[
                                                        'employerName',
                                                        'employerContactNumber',
                                                        'employerAddress',
                                                        'employerCity',
                                                        'employerState',
                                                        'employerZip'
                                                    ]}
                                                    validationFields={[
                                                        !employerPhoneNumberInputError,
                                                        !employerZipInputError,
                                                        !employerEmailInputError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                        {isCommentsActive && (
                            <SCSectionWrapper>
                                <SCBodyLarge>
                                    Please enter any additional questions, comments, or pertinent information about your
                                    situation here.
                                </SCBodyLarge>
                                <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                                <SCFormWrapper>
                                    <form
                                        id={'borrower'}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                                            handleSaveFormState(e, formData)
                                        }
                                        autoComplete={'on'}
                                    >
                                        <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                            <SCSectionSubHead>Comments</SCSectionSubHead>
                                        </SCElementWrapper>
                                        <SCElementsWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                <TSTextField
                                                    ariaLabel={labelMapIU['borrowerComments']}
                                                    label={labelMapIU['borrowerComments']}
                                                    id={'borrowerComments'}
                                                    value={formData?.borrowerComments as string}
                                                    onChange={(event: unknown): void => {
                                                        handleForm(event as React.FormEvent<HTMLInputElement>);
                                                    }}
                                                    placeholder={`Enter ${labelMapIU['borrowerComments']}`}
                                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                                    multiline={true}
                                                    minRows={5}
                                                />
                                            </SCElementWrapper>
                                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                <TSAutocomplete
                                                    id="iuFiledOnBehalfOf"
                                                    options={yesNoAutocompleteOptionValues}
                                                    getOptionLabel={(yesNoValue): string =>
                                                        YesNoAutocompleteOptions[yesNoValue]
                                                    }
                                                    value={formData?.iuFiledOnBehalfOf}
                                                    label={labelMapIU['iuFiledOnBehalfOf']}
                                                    onChange={(event, value, reason): void => {
                                                        handleAutocompleteInput('iuFiledOnBehalfOf', value, [
                                                            'submitterCompanyName',
                                                            'submitterNameFirst',
                                                            'submitterNameLast',
                                                            'submitterEmail',
                                                            'submitterPhone'
                                                        ]);
                                                    }}
                                                ></TSAutocomplete>
                                            </SCElementWrapper>
                                        </SCElementsWrapper>
                                        {formData && onBehalfOf && (
                                            <SCElementsWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                                                    <TSTextField
                                                        id={'submitterCompanyName'}
                                                        ariaLabel={labelMapIU['submitterCompanyName']}
                                                        label={`${labelMapIU['submitterCompanyName']}`}
                                                        value={formData?.submitterCompanyName as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['submitterCompanyName']}`}
                                                        maxLength={MaxFieldLengths.COMPANY_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'submitterNameFirst'}
                                                        ariaLabel={labelMapIU['submitterNameFirst']}
                                                        label={`${labelMapIU['submitterNameFirst']}`}
                                                        value={formData?.submitterNameFirst as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['submitterNameFirst']}`}
                                                        maxLength={MaxFieldLengths.FIRST_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        id={'submitterNameLast'}
                                                        ariaLabel={labelMapIU['submitterNameLast']}
                                                        label={`${labelMapIU['submitterNameLast']}`}
                                                        value={formData?.submitterNameLast as string}
                                                        onChange={handleForm}
                                                        placeholder={`Enter ${labelMapIU['submitterNameLast']}`}
                                                        maxLength={MaxFieldLengths.LAST_NAME}
                                                    ></TSTextField>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSTextField
                                                        ariaLabel={labelMapIU['submitterEmail']}
                                                        id={'submitterEmail'}
                                                        label={labelMapIU['submitterEmail']}
                                                        type="email"
                                                        value={formData?.submitterEmail as string}
                                                        onChange={(
                                                            formEvent: React.FormEvent<HTMLInputElement>
                                                        ): void => {
                                                            handleEmailInput(
                                                                'submitterEmail',
                                                                formEvent.currentTarget.value
                                                            );
                                                        }}
                                                        placeholder={`Enter ${labelMapIU['submitterEmail']}`}
                                                        maxLength={MaxFieldLengths.EMAIL}
                                                    ></TSTextField>
                                                    <SCErrorLabel>{submitterEmailInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                                <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                                    <TSMaskedTextField
                                                        id={'submitterPhone'}
                                                        ariaLabel={labelMapIU['submitterPhone']}
                                                        label={labelMapIU['submitterPhone']}
                                                        value={formData?.submitterPhone as string}
                                                        onChange={(event): void => {
                                                            handlePhoneNumberInput(
                                                                'submitterPhone',
                                                                event.target.value
                                                            );
                                                        }}
                                                        placeholder={`Enter ${labelMapIU['submitterPhone']}`}
                                                    ></TSMaskedTextField>
                                                    <SCErrorLabel>{submitterPhoneNumberInputError}</SCErrorLabel>
                                                </SCElementWrapper>
                                            </SCElementsWrapper>
                                        )}
                                        <SCFormNavigationWrapper>
                                            {formJoblossProgressState.some(
                                                (e) => e.status === TimelineState.ACTIVE
                                            ) && (
                                                <FormNavigation
                                                    formData={formData}
                                                    requiredFields={
                                                        formData?.iuFiledOnBehalfOf === 'yes'
                                                            ? [
                                                                  'submitterCompanyName',
                                                                  'submitterNameFirst',
                                                                  'submitterNameLast',
                                                                  'submitterEmail',
                                                                  'submitterPhone'
                                                              ]
                                                            : []
                                                    }
                                                    validationFields={[
                                                        !submitterEmailInputError,
                                                        !submitterPhoneNumberInputError
                                                    ]}
                                                />
                                            )}
                                        </SCFormNavigationWrapper>
                                    </form>
                                </SCFormWrapper>
                            </SCSectionWrapper>
                        )}
                    </SCFormRefWrapper>
                    <BeforeUnload
                        isLoggedIn={authenticationData.isAuthenticated ? authenticationData.isAuthenticated : false}
                        cancelHeaderButtonText={'Close'}
                        modalBody={
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                                <SCSectionBody>
                                    You have unsaved claim information, continuing may cause your information to be lost
                                </SCSectionBody>
                            </SCContentSectionWrapper>
                        }
                        nativeDialogue={true}
                        customDialogue={true}
                        modalEnabled={isFormDirty}
                        continueButtonText={'Proceed without saving'}
                        cancelButtonText={'Return to page'}
                        onContinue={(): void => {
                            setIsFormDirty(false);
                            saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
                        }}
                        allowedRoutes={allowedRoutes}
                        onCancel={(): void => {}}
                    />
                    <TSDialog
                        ariaLabel={'Unsaved Changes dialog'}
                        hideSecondaryCloseButton
                        handleClose={(): void => {
                            setConfirmModalIsVisible(false);
                        }}
                        id={'unsavedChangesDialog'}
                        open={confirmModalIsVisible}
                        dialogSize={'sm'}
                        bannerHeaderCloseButtonText={'Close'}
                        content={
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                                <SCSectionBody>
                                    You have unsaved claim information, continuing may cause your information to be lost
                                </SCSectionBody>
                            </SCContentSectionWrapper>
                        }
                        actionChildren={
                            <SCContentSectionResponsiveWrapper
                                flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                                flexAlignment={FlexAlignItemsEnum.CENTER}
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexGap={'16px'}
                            >
                                <TSButton
                                    ariaLabel="dialog return to page"
                                    tsVariant={TSButtonVariantsEnum.TEXT}
                                    size={TSButtonSizeEnum.MEDIUM}
                                    onClick={(): void => {
                                        setConfirmModalIsVisible(false);
                                    }}
                                >
                                    Return to page
                                </TSButton>
                                <TSButton
                                    ariaLabel="dialog proceed without saving"
                                    tsVariant={TSButtonVariantsEnum.ARROW}
                                    size={TSButtonSizeEnum.MEDIUM}
                                    onClick={(): void => {
                                        changeFormType && changeFormType();
                                        setConfirmModalIsVisible(false);
                                    }}
                                >
                                    Proceed without saving
                                </TSButton>
                            </SCContentSectionResponsiveWrapper>
                        }
                    ></TSDialog>
                </SCContentSectionResponsiveWrapper>
            </SCContentDirectionWrapper>
        </SCContentWrapper>
    );
};

export { FormIU };
