import * as React from 'react';
import { IClaimFormAdvancementTypeState, IClaimFormAdvancementType } from './ClaimFormAdvancementTypes';
import { IChildrenProps } from 'types/children';
import { ClaimStateEnum } from 'enums/ClaimState';

export const ClaimFormAdvancementContext = React.createContext<IClaimFormAdvancementTypeState | null>(null);
const ClaimFormAdvancementProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [stateClaimFormAdvancement, setStateClaimFormAdvancement] = React.useState<IClaimFormAdvancementType>({
        reviewState: ClaimStateEnum.INACTIVE
    });
    const stateObject: IClaimFormAdvancementTypeState = {
        contextStateClaimFormAdvancement: stateClaimFormAdvancement,
        updateContextStateClaimFormAdvancement: setStateClaimFormAdvancement
    };
    return <ClaimFormAdvancementContext.Provider value={stateObject}>{children}</ClaimFormAdvancementContext.Provider>;
};

export default ClaimFormAdvancementProvider;
