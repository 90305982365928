import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { FormIUStateContext } from 'context/formIUStateContext';
import { IFormDisabilityState, IFormIUData, IFormIUState } from 'types/form';
import { IFormNavigationProps } from './types';
import { IFormDisabilityProgressState, IFormJoblossProgressState } from 'types/form-progress';
import { SCButtonWrapper, SCElementsWrapper, SCRowWrapper } from './styles';
import { TimelineState } from 'enums/TimeLine';
import { FormTypeContext } from 'context/formTypeContext';
import { IFormTypeState } from 'types/claim-types';
import { ClaimTypesEnum } from 'enums/ClaimTypes';
import { allFieldsComplete, allFieldsValid } from 'utils/form/form';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { FormDisabilityStateContext } from 'context/formDisabilityStateContext';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { TSDialog } from 'components/atoms/TSDialog';
import {
    $digitalBlack,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCSectionBody,
    SCSectionSubHead
} from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';

const FormNavigation = ({
    formData,
    requiredFields,
    validationFields,
    isFormDirty
}: IFormNavigationProps): ReactElement => {
    const {
        updateFormJoblossProgressState,
        formJoblossProgressState,
        updateFormJoblossProgressCompletionStatus,
        updateAllFormJoblossProgressStatusComplete,
        formJoblossProgressCompletionStatus
    } = React.useContext(FormJoblossProgressStateContext) as IFormJoblossProgressState;
    const {
        updateFormDisabilityProgressState,
        formDisabilityProgressState,
        updateFormDisabilityProgressCompletionStatus,
        updateAllFormDisabilityProgressStatusComplete,
        formDisabilityProgressCompletionStatus
    } = React.useContext(FormDisabilityProgressStateContext) as IFormDisabilityProgressState;
    const { formType, saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const { saveFormIUState } = React.useContext(FormIUStateContext) as IFormIUState;
    const { saveFormDisabilityState } = React.useContext(FormDisabilityStateContext) as IFormDisabilityState;
    const [formValid, setFormValid] = useState<boolean>(false);
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    useEffect(() => {
        if (formData && requiredFields) {
            setFormValid(allFieldsComplete(requiredFields, formData) && allFieldsValid(validationFields));
        }
    }, [formData, requiredFields, validationFields]);
    const handleSaveFormState = (event: React.FormEvent, formData: IFormIUData | any): any => {
        formType.type === ClaimTypesEnum.Jobloss ? saveFormIUState(formData) : saveFormDisabilityState(formData);
    };
    const currentId =
        formType.type === ClaimTypesEnum.Jobloss
            ? formJoblossProgressState.filter((item) => item.status === TimelineState.ACTIVE).map((item) => item.id)
            : formDisabilityProgressState.filter((item) => item.status === TimelineState.ACTIVE).map((item) => item.id);
    const incrementId = currentId
        ? currentId[0].replace(/\d+$/, (n): string => {
              return (parseInt(n) + 1) as unknown as string;
          })
        : '';
    const decrementId = currentId
        ? currentId[0].replace(/\d+$/, (n): string => {
              return (parseInt(n) - 1) as unknown as string;
          })
        : '';
    const handleBackFormStates = (): void => {
        if (currentId[0] === 'id-1') {
            saveFormType({
                type: undefined,
                reviewState: ClaimStateEnum.ACTIVE
            });
            updateFormJoblossProgressState(currentId[0], TimelineState.COMPLETE);
            updateFormDisabilityProgressState(currentId[0], TimelineState.COMPLETE);
            setConfirmModalIsVisible(true);
        }
        if (formType.type === ClaimTypesEnum.Jobloss) {
            updateFormJoblossProgressState(decrementId, TimelineState.ACTIVE);
            updateFormJoblossProgressState(currentId[0], TimelineState.COMPLETE);
        } else if (formType.type === ClaimTypesEnum.Disability) {
            updateFormDisabilityProgressState(decrementId, TimelineState.ACTIVE);
            updateFormDisabilityProgressState(currentId[0], TimelineState.COMPLETE);
        }
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormNavigation:::::::::::::::::::::::::::::::::',
    //     '\n::currentId::',
    //     currentId,
    //     '\n::incrementId::',
    //     incrementId,
    //     '\n::decrementId::',
    //     decrementId,
    //     '\n::formValid::',
    //     formValid,
    //     '\n::formDisabilityProgressCompletionStatus::',
    //     formDisabilityProgressCompletionStatus,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCElementsWrapper>
            <SCRowWrapper>
                <SCButtonWrapper>
                    <TSButton
                        tsVariant={TSButtonVariantsEnum.BACK}
                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                            isFormDirty && currentId[0] === 'id-1'
                                ? setConfirmModalIsVisible(true)
                                : handleBackFormStates();
                        }}
                        ariaLabel={'return to previous view'}
                    >
                        Back
                    </TSButton>
                </SCButtonWrapper>
                <SCButtonWrapper>
                    <TSButton
                        tsVariant={TSButtonVariantsEnum.ARROW}
                        ariaLabel={''}
                        disabled={!formValid}
                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                            handleSaveFormState(event, formData);
                            if (allFieldsComplete(requiredFields, formData)) {
                                if (formType.type === ClaimTypesEnum.Jobloss) {
                                    updateFormJoblossProgressState(currentId[0], TimelineState.COMPLETE);
                                    updateFormJoblossProgressState(incrementId, TimelineState.ACTIVE);
                                }
                                if (formType.type === ClaimTypesEnum.Disability) {
                                    updateFormDisabilityProgressState(currentId[0], TimelineState.COMPLETE);
                                    updateFormDisabilityProgressState(incrementId, TimelineState.ACTIVE);
                                }
                                if (
                                    formJoblossProgressState.every((obj) => {
                                        return obj.status === TimelineState.COMPLETE;
                                    })
                                ) {
                                    saveFormType({ type: ClaimTypesEnum.Jobloss, reviewState: ClaimStateEnum.REVIEW });
                                    updateContextStateClaimFormAdvancement({
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                }
                                if (
                                    formDisabilityProgressState.every((obj) => {
                                        return obj.status === TimelineState.COMPLETE;
                                    })
                                ) {
                                    saveFormType({
                                        type: ClaimTypesEnum.Disability,
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                    updateContextStateClaimFormAdvancement({
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                }
                            }
                        }}
                    >
                        Continue
                    </TSButton>
                </SCButtonWrapper>
            </SCRowWrapper>
            {((formType.type === ClaimTypesEnum.Disability && formDisabilityProgressCompletionStatus) ||
                (formType.type === ClaimTypesEnum.Jobloss && formJoblossProgressCompletionStatus)) && (
                <SCRowWrapper>
                    <SCButtonWrapper>
                        <TSButton
                            tsVariant={TSButtonVariantsEnum.BACK}
                            disabled={!formValid}
                            onClick={(event: SyntheticEvent<Element, Event>): void => {
                                handleSaveFormState(event, formData);
                                if (allFieldsComplete(requiredFields, formData)) {
                                    if (formType.type === ClaimTypesEnum.Jobloss) {
                                        updateFormJoblossProgressCompletionStatus(true);
                                        saveFormType({
                                            type: ClaimTypesEnum.Jobloss,
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateAllFormJoblossProgressStatusComplete();
                                    }
                                    if (formType.type === ClaimTypesEnum.Disability) {
                                        updateFormDisabilityProgressCompletionStatus(true);
                                        saveFormType({
                                            type: ClaimTypesEnum.Disability,
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateAllFormDisabilityProgressStatusComplete();
                                    }
                                }
                            }}
                            ariaLabel={'return to previous view'}
                        >
                            Return to Review
                        </TSButton>
                    </SCButtonWrapper>
                </SCRowWrapper>
            )}
            <TSDialog
                ariaLabel={'Unsaved Changes dialog'}
                hideSecondaryCloseButton
                handleClose={(): void => {
                    setConfirmModalIsVisible(false);
                }}
                id={'unsavedChangesDialog'}
                open={confirmModalIsVisible}
                dialogSize={'sm'}
                bannerHeaderCloseButtonText={'Close'}
                content={
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                    >
                        <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                        <SCSectionBody>
                            You have unsaved claim information, continuing may cause your information to be lost
                        </SCSectionBody>
                    </SCContentSectionWrapper>
                }
                actionChildren={
                    <SCContentSectionResponsiveWrapper
                        flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexGap={'16px'}
                    >
                        <TSButton
                            ariaLabel="dialog return to page"
                            tsVariant={TSButtonVariantsEnum.TEXT}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                setConfirmModalIsVisible(false);
                            }}
                        >
                            Return to page
                        </TSButton>
                        <TSButton
                            ariaLabel="dialog proceed without saving"
                            tsVariant={TSButtonVariantsEnum.ARROW}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                handleBackFormStates();
                                setConfirmModalIsVisible(false);
                            }}
                        >
                            Proceed without saving
                        </TSButton>
                    </SCContentSectionResponsiveWrapper>
                }
            ></TSDialog>
        </SCElementsWrapper>
    );
};

export { FormNavigation };
