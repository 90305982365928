import React, { ReactElement } from 'react';
import { SCRootSVG, SCPath, SCSvgWrapper } from './styles';
import { IXIconProps } from './types';

const XIcon = ({ size }: IXIconProps): ReactElement => {
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::XIcon:::::::::::::::::::::::::::::::::',
    //     '\n::size::',
    //     size,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCSvgWrapper size={size}>
            <SCRootSVG
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlSpace="preserve"
            >
                <g transform="matrix(1,0,0,1,2.46393,3.18828)">
                    <SCPath d="M9.505,1.642L8.091,0.227L0.953,7.358L2.367,8.773L9.505,1.642Z" />
                </g>
                <g transform="matrix(1,0,0,1,2.46393,3.18828)">
                    <SCPath d="M9.504,7.358L2.366,0.227L0.952,1.642L8.09,8.773L9.504,7.358Z" />
                </g>
            </SCRootSVG>
        </SCSvgWrapper>
    );
};

export { XIcon };
