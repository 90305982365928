import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { IClaimEditProps, IClaimEditViewDetailsProps, IFieldData } from './types';
import { ClaimEditContext, IClaimEditType, IClaimEditTypeState } from 'context/ClaimEdit';
import { BeforeUnload } from 'components/molecules/BeforeUnload';
import { FlexAlignItemsEnum, FlexDirectionEnum, FormFieldWidthEnum } from 'enums/StyleTypes';
import {
    SCContentSectionWrapper,
    SCElementsWrapper,
    SCElementWrapper,
    SCErrorLabel,
    SCForm,
    SCSectionSubHead,
    SCFormWrapper,
    SCHRElement,
    SCTempDateFieldWrapper
} from 'styles/global-styles';
import { TSTextField } from 'components/atoms/TSTextField';
import { labelMapDisability } from 'components/organisms/FormIU/fieldTypes';
import { MaxFieldLengths } from 'enums/MaxFieldLengths';
import { editFormLabels } from 'support/formLabels';
import { TSAutocomplete } from 'components/atoms/TSAutocomplete';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ClaimTypesEnum } from 'enums';
import { DatePicker } from '@mui/x-date-pickers';
import { IDistributorOptions } from 'types/distributor-information';
import { Routes } from 'routes';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import {
    errorMapIU,
    formatCurrency,
    getNumberWithoutCommas,
    validateLast4SSN,
    validatePriceUS,
    prependedInteger
} from 'utils/form/form';
import { IClaimsManagementClaimBenefitsData, IClaimsManagementData } from 'types/claims-management';
import { ClaimBenefit } from 'components/molecules/ClaimBenefit';
import { TSButton } from 'components/atoms/TSButton';
import { Accordion, IAccordionData } from 'components/molecules/Accordion';
import { SCViewDetailsWrapper } from './styles';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimAdjudicationConfigsContext, IClaimAdjudicationConfigsTypeState } from 'context/ClaimAdjudicationConfigs';
import { IDistributorAdjudicationConfig, ILoanServicerAdjudicationConfig } from 'types/claim-adjudication-config';
import { isLast4SSNIdentifierType } from 'utils/helpers/helpers';

dayjs.extend(utc);
dayjs.extend(timezone);
const timeZone = 'America/Chicago';
const missingRequiredFieldError = 'Required';
const ClaimEdit = ({ isDataValidCallback, getAllClaimDetailsCallback }: IClaimEditProps): ReactElement => {
    const { contextStateClaimEdit, updateContextStateClaimEdit, updateContextStateClaimEditBenefits } =
        React.useContext(ClaimEditContext) as IClaimEditTypeState;
    const { authenticationData } = React.useContext(AuthenticationSessionContext) as IAuthenticationDataState;
    const { contextStateClaimAdjudicationConfigs } = React.useContext(
        ClaimAdjudicationConfigsContext
    ) as IClaimAdjudicationConfigsTypeState;
    const [claimEditFormData, setClaimEditFormData] = React.useState<IClaimEditType | Record<string, unknown>>();
    const [claimEditFormDataState, setClaimEditFormDataState] = React.useState<
        IClaimEditType | Record<string, unknown>
    >();
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const [last4SSNRequired, setLast4SSNRequired] = React.useState(false);
    const [nameFirstData, setNameFirstData] = useState<IFieldData>();
    const [nameLastData, setNameLastData] = useState<IFieldData>();
    const [jobLossReasonData, setJobLossReasonData] = useState<IFieldData>();
    const [last4SSN, setLast4SSN] = useState<IFieldData>();
    const [loanNumber, setLoanNumber] = useState<IFieldData>();
    const [amountPayable, setAmountPayable] = useState<IFieldData>();
    const [amountPayableDisplay, setAmountPayableDisplay] = useState<string>('0');
    const [disablingCondition, setDisablingCondition] = useState<IFieldData>();
    const [additionalInformation, setAdditionalInformation] = useState<IFieldData>();
    const [claimSpecialistName, setClaimSpecialistName] = useState<IFieldData>();
    const [claimNotes, setClaimNotes] = useState<IFieldData>();
    const [loanServicersAvailable, setLoanServicersAvailable] = useState<string[]>([]);
    /*FORM VALIDATION Begin*/
    const [distributorNameError, setDistributorNameError] = useState<string | null>(null);
    const [ssnInputError, setSsnInputError] = useState<string | null>(null);
    const [claimDecisionDateError, setClaimDecisionDateError] = useState<string | null>(null);
    const [incurredDateError, setIncurredDateError] = useState<string | null>(null);
    const [dateDecisionSentError, setDateDecisionSentError] = useState<string | null>(null);
    const [amountPayableError, setAmountPayableError] = useState<string | null>(null);
    const [nameFirstError, setNameFirstError] = useState<string | null>(null);
    const [nameLastError, setNameLastError] = useState<string | null>(null);
    const [newBenefitsArrayLength, setNewBenefitsArrayLength] = useState(-1);
    const [distributionTableOptions, setDistributionTableOptions] = useState<IDistributorOptions[]>([]);
    /**
     * Overall form validation
     * Return true if no errors are present
     * */
    const isDataValid = (): boolean => {
        return (
            !distributorNameError &&
            !ssnInputError &&
            !claimDecisionDateError &&
            !incurredDateError &&
            !dateDecisionSentError &&
            !amountPayableError &&
            !nameFirstError &&
            !nameLastError
        );
    };
    useEffect(() => {
        isDataValidCallback(isDataValid());
    }, [
        amountPayableError,
        ssnInputError,
        claimDecisionDateError,
        incurredDateError,
        dateDecisionSentError,
        nameFirstError,
        nameLastError
    ]);
    /** Distributor name + Last 4 SSN validation
     * Set an error if required distributor name is empty
     * */
    const evaluateDistributorNameValidity = (): void => {
        contextStateClaimEdit?.distributorName == null || contextStateClaimEdit.distributorName?.length == 0
            ? setDistributorNameError(missingRequiredFieldError)
            : setDistributorNameError(null);
    };
    /**
     * Set an error if Last 4 SSN isn't entered as 4 digits, or if it's empty for a distributor that requires it
     * */
    const evaluateSSNFieldValidity = (): void => {
        const last4SSNString = last4SSN?.value as string;
        let last4SSNError: string | null = null;
        if (last4SSNRequired) {
            if (last4SSNString == null || last4SSNString.length == 0) {
                last4SSNError = missingRequiredFieldError;
            } else if (!validateLast4SSN(last4SSNString)) {
                last4SSNError = errorMapIU['ssnError'];
            }
        } else if (last4SSNString?.length > 0 && !validateLast4SSN(last4SSNString)) {
            /**
             * Last 4 SSN is optional for distributors that don't require it... but still,
             * let's validate it's in the correct format if they have typed out something
             */
            last4SSNError = errorMapIU['ssnError'];
        }
        setSsnInputError(last4SSNError);
    };
    useEffect(() => {
        evaluateDistributorNameValidity();
        evaluateSSNFieldValidity();
    }, [contextStateClaimEdit?.distributorName, last4SSN, last4SSNRequired]);
    /**
     * Price validation
     * Set an error if AmountPayable is not empty and also in an invalid format
     */
    const evaluateAmountPayableValidity = (): void => {
        const amountPayableValue = amountPayable?.value as string;
        amountPayableValue?.length > 0 && !validatePriceUS(amountPayableValue)
            ? setAmountPayableError(errorMapIU['priceUSError'])
            : setAmountPayableError(null);
    };
    useEffect(() => {
        evaluateAmountPayableValidity();
    }, [amountPayable?.value]);
    /**
     * First name validation
     * nameFirstString: Required
     * */
    useEffect(() => {
        const nameFirstString = nameFirstData?.value as string;
        nameFirstString?.length == 0 ? setNameFirstError(missingRequiredFieldError) : setNameFirstError(null);
    }, [nameFirstData?.value]);
    /**
     * Last name validation
     * */
    useEffect(() => {
        const nameLastString = nameLastData?.value as string;
        nameLastString?.length == 0 ? setNameLastError(missingRequiredFieldError) : setNameLastError(null);
    }, [nameLastData?.value]);
    /**
     * FORM VALIDATION end
     */
    const updateDistributionTableOptions = (): void => {
        setDistributionTableOptions(
            contextStateClaimAdjudicationConfigs.distributors.map(
                (item: IDistributorAdjudicationConfig) =>
                    ({
                        label: item.name,
                        value: item.name
                    } as IDistributorOptions)
            )
        );
    };
    useEffect(() => {
        /** Distributor choice has changed. Recalculate available loan servicers */
        reevaluateDistributorContext();
    }, [contextStateClaimEdit?.distributorName]);
    const reevaluateDistributorContext = (): void => {
        const listOfLoanServicers: string[] = [];
        const distributorName = contextStateClaimEdit?.distributorName as string;
        const currentLoanServicerName = contextStateClaimEdit?.loanServicerName as string;
        let currentLoanServicerAvailable = false;
        let isLast4SSNDistributor = false;
        /** Parse Claim Adjudication Configs to determine list of eligible Loan Servicers for this Distributor */
        if (distributorName != null && contextStateClaimAdjudicationConfigs.distributors != null) {
            contextStateClaimAdjudicationConfigs.distributors.forEach(function (
                distributorOption: IDistributorAdjudicationConfig
            ) {
                if (distributorOption.name == distributorName && distributorOption.loanServicers != null) {
                    isLast4SSNDistributor = isLast4SSNIdentifierType(
                        distributorOption?.borrowerIdentifierTypeId as number
                    );
                    if (distributorOption.loanServicers != null) {
                        distributorOption.loanServicers.forEach(function (
                            loanServicerOption: ILoanServicerAdjudicationConfig
                        ) {
                            if (loanServicerOption != null && listOfLoanServicers) {
                                listOfLoanServicers.push(loanServicerOption.name as string);
                                if (currentLoanServicerName == (loanServicerOption.name as string)) {
                                    currentLoanServicerAvailable = true;
                                }
                            }
                        });
                    }
                }
            });
        }
        setLoanServicersAvailable(listOfLoanServicers);
        setLast4SSNRequired(isLast4SSNDistributor);
        /** If the previously set loan servicer is no longer available, clear it out */
        if (!currentLoanServicerAvailable) {
            updateContextStateClaimEdit({
                ...contextStateClaimEdit,
                loanServicerName: ''
            });
        }
    };
    const handleFormBlurUpdate = (fieldValue: IFieldData): void => {
        fieldValue.id &&
            updateContextStateClaimEdit({
                ...contextStateClaimEdit,
                [fieldValue.id]: fieldValue.value,
                isDirty: true
            });
    };
    const claimBenefitIndexId: unknown = claimEditFormDataState?.ClaimBenefits
        ? `${claimEditFormDataState?.claimNumber}-${prependedInteger(
              (Object.values(claimEditFormDataState?.ClaimBenefits).length as number) + 1
          )}`
        : `${contextStateClaimEdit?.claimNumber}-01`;
    const newClaimBenefitRecord = {
        ClaimBenefitId: claimBenefitIndexId as string,
        ClaimNumber: '',
        ClaimPolicyRelationshipId: '00000000-0000-0000-0000-000000000000',
        ExternalSystemIdentifier: '',
        AmountPayable: '',
        DecisionStatus: '',
        DecisionDateTimeUTC: '',
        DecisionReason: '',
        DecisionEmailSentDateTimeUTC: '',
        FastTracked: null,
        DecisionAdditionalInfo: '',
        IsDeleted: false
    };
    const addClaimBenefitRecord = (): void => {
        contextStateClaimEdit.ClaimBenefits == null &&
            updateContextStateClaimEdit({
                ...contextStateClaimEdit,
                ClaimBenefits: [newClaimBenefitRecord]
            });
        contextStateClaimEdit.ClaimBenefits &&
            updateContextStateClaimEditBenefits([...contextStateClaimEdit.ClaimBenefits, newClaimBenefitRecord]);
    };
    const addNewClaimBenefitRecord = (): void => {
        updateContextStateClaimEdit({
            ...contextStateClaimEdit,
            ClaimBenefits: [...contextStateClaimEdit.ClaimBenefits, newClaimBenefitRecord],
            isDirty: true
        });
        contextStateClaimEdit.ClaimBenefits &&
            updateContextStateClaimEditBenefits([...contextStateClaimEdit.ClaimBenefits, newClaimBenefitRecord]);
    };
    const newBenefitsArray: IClaimsManagementClaimBenefitsData[] =
        contextStateClaimEdit.ClaimBenefits &&
        contextStateClaimEdit.ClaimBenefits.map((object, i) => ({
            ...object
        }));
    const allowedRoutes = [
        `${window.location.origin}${Routes.CALLBACK}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}/default/v1/logout?id_token_hint`
    ];
    const amountPayableSum = (benefitsArray: IClaimsManagementClaimBenefitsData[]): number => {
        return benefitsArray.reduce((sum: number, current: any): number => {
            return typeof current.AmountPayable !== 'undefined' &&
                current.IsDeleted !== true &&
                parseFloat(current.AmountPayable)
                ? parseFloat(current.AmountPayable) + sum
                : sum;
        }, 0);
    };
    const allClaimDetails: IClaimsManagementData = getAllClaimDetailsCallback(
        contextStateClaimEdit.allClaimDetailsLookupIndex
    );
    useEffect(() => {
        newBenefitsArray && setAmountPayableDisplay(amountPayableSum(newBenefitsArray).toLocaleString());
    }, [contextStateClaimEdit.ClaimBenefits, newBenefitsArray, newBenefitsArrayLength]);
    useEffect(() => {
        updateDistributionTableOptions();
        const contextStateClaimEditDeepCopy = structuredClone(contextStateClaimEdit);
        setClaimEditFormDataState(contextStateClaimEditDeepCopy);
        claimEditFormDataState &&
            JSON.stringify(claimEditFormDataState) !== JSON.stringify(contextStateClaimEdit) &&
            setIsFormDirty(true);
    }, [contextStateClaimEdit, contextStateClaimEdit.ClaimBenefits, isFormDirty]);
    useEffect(() => {
        newBenefitsArray != null
            ? updateContextStateClaimEditBenefits(newBenefitsArray)
            : updateContextStateClaimEditBenefits([newClaimBenefitRecord]);
        contextStateClaimEdit.ClaimBenefits == null && addClaimBenefitRecord();
        setNameFirstData({ value: contextStateClaimEdit.nameFirst });
        setNameLastData({ value: contextStateClaimEdit.nameLast });
        setJobLossReasonData({ value: contextStateClaimEdit.jobLossReason });
        setLast4SSN({ value: contextStateClaimEdit.last4SSN });
        setLoanNumber({ value: contextStateClaimEdit.loanNumber });
        setAmountPayable({ value: getNumberWithoutCommas(contextStateClaimEdit.amountPayable as string) });
        setDisablingCondition({ value: contextStateClaimEdit.disablingCondition });
        setAdditionalInformation({ value: contextStateClaimEdit.additionalInfo });
        setClaimSpecialistName({ value: contextStateClaimEdit.nameOfClaimSpecialist });
        setClaimNotes({ value: contextStateClaimEdit.notes });
        setClaimEditFormData(contextStateClaimEdit);
        setNewBenefitsArrayLength(newBenefitsArray && newBenefitsArray.length);
        newBenefitsArray?.sort((a, b) => a.ClaimBenefitId.localeCompare(b.ClaimBenefitId));
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ClaimEdit:::::::::::::::::::::::::::::::::',
    //     '\n::authenticationData::',
    //     authenticationData,
    //     '\n::claimEditFormData::',
    //     claimEditFormData,
    //     '\n::claimEditFormDataState::',
    //     claimEditFormDataState,
    //     '\n::newBenefitsArray::',
    //     newBenefitsArray && newBenefitsArray,
    //     '\n::contextStateClaimEdit::',
    //     contextStateClaimEdit,
    //     '\n::paymentGuardDistributorsInformation::',
    //     paymentGuardDistributorsInformation,
    //     '\n::distributionTableOptions::',
    //     distributionTableOptions,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );
    return (
        <SCContentSectionWrapper
            flexDirection={FlexDirectionEnum.COLUMN}
            flexAlignment={FlexAlignItemsEnum.FLEX_START}
            flexGap={'24px'}
        >
            <SCFormWrapper>
                <form id={'borrower'} autoComplete={'on'} className={SCForm}>
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                    >
                        <SCHRElement />
                        <SCSectionSubHead>Claim Information</SCSectionSubHead>
                        <SCElementsWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id={'claimNumber'}
                                    ariaLabel={editFormLabels.claimNumber}
                                    label={editFormLabels.claimNumber}
                                    value={contextStateClaimEdit?.claimNumber}
                                    readOnly={true}
                                    placeholder={`${editFormLabels.claimNumber}`}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id={'claimStatus'}
                                    ariaLabel={editFormLabels.claimStatus}
                                    label={editFormLabels.claimStatus}
                                    value={contextStateClaimEdit?.claimStatus}
                                    readOnly={true}
                                    placeholder={`${editFormLabels.claimStatus}`}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id={editFormLabels.lossType}
                                    value={
                                        contextStateClaimEdit.peril === ClaimTypesEnum.Jobloss
                                            ? 'Job Loss'
                                            : 'Disability'
                                    }
                                    ariaLabel={editFormLabels.lossType}
                                    label={editFormLabels.lossType}
                                    readOnly={true}
                                    placeholder={`${editFormLabels.lossType}`}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id={editFormLabels.dateFiled}
                                    value={dayjs
                                        .utc(contextStateClaimEdit.claimCreateDate as string)
                                        .tz(timeZone)
                                        .format('MMMM D YYYY h:mm A')}
                                    ariaLabel={editFormLabels.dateFiled}
                                    label={editFormLabels.dateFiled}
                                    readOnly={true}
                                    placeholder={`${editFormLabels.dateFiled}`}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSAutocomplete
                                    id={'distributorName'}
                                    ariaLabel={editFormLabels.distributorName}
                                    options={distributionTableOptions}
                                    getOptionLabel={(option): string =>
                                        option.label
                                            ? (option.label as string)
                                            : (contextStateClaimEdit?.distributorName as string)
                                    }
                                    defaultValue={contextStateClaimEdit.distributorName}
                                    value={contextStateClaimEdit?.distributorName as string}
                                    inputValue={contextStateClaimEdit?.distributorName as string}
                                    label={editFormLabels.distributorName}
                                    onChange={(event, value): void => {
                                        updateContextStateClaimEdit({
                                            ...contextStateClaimEdit,
                                            distributorName: value.value,
                                            isDirty: true
                                        });
                                    }}
                                ></TSAutocomplete>
                                <SCErrorLabel>{distributorNameError}</SCErrorLabel>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSAutocomplete
                                    id={'loanServicerName'}
                                    ariaLabel={editFormLabels.loanServicerName}
                                    options={loanServicersAvailable}
                                    getOptionLabel={(option): string => option}
                                    defaultValue={contextStateClaimEdit.loanServicerName}
                                    value={contextStateClaimEdit?.loanServicerName as string}
                                    inputValue={contextStateClaimEdit?.loanServicerName as string}
                                    label={editFormLabels.loanServicerName}
                                    onChange={(event, value): void => {
                                        updateContextStateClaimEdit({
                                            ...contextStateClaimEdit,
                                            loanServicerName: value,
                                            isDirty: true
                                        });
                                    }}
                                    disabled={loanServicersAvailable == null || loanServicersAvailable.length == 0}
                                ></TSAutocomplete>
                                <SCErrorLabel>{distributorNameError}</SCErrorLabel>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id={'amountPayable'}
                                    label={editFormLabels.amountPayable}
                                    ariaLabel={editFormLabels.amountPayable}
                                    value={formatCurrency(amountPayableDisplay)}
                                    readOnly={true}
                                    placeholder={editFormLabels.amountPayable}
                                    startAdornment={<span>$</span>}
                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                ></TSTextField>
                                <SCErrorLabel>{amountPayableError}</SCErrorLabel>
                            </SCElementWrapper>
                        </SCElementsWrapper>
                        <SCElementsWrapper>
                            <SCHRElement />
                            <SCSectionSubHead>Borrower Information</SCSectionSubHead>
                        </SCElementsWrapper>
                        <SCElementsWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                <TSTextField
                                    id={'nameFirst'}
                                    ariaLabel={editFormLabels.borrowerNameFirst}
                                    label={editFormLabels.borrowerNameFirst}
                                    value={nameFirstData?.value}
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setNameFirstData({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        nameFirstData && handleFormBlurUpdate(nameFirstData);
                                    }}
                                    placeholder={`Enter ${labelMapDisability['borrowerNameFirst']}`}
                                    maxLength={MaxFieldLengths.FIRST_NAME}
                                ></TSTextField>
                                <SCErrorLabel>{nameFirstError}</SCErrorLabel>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                <TSTextField
                                    id={'nameLast'}
                                    ariaLabel={editFormLabels.borrowerNameLast}
                                    label={editFormLabels.borrowerNameLast}
                                    value={nameLastData?.value}
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setNameLastData({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        nameLastData && handleFormBlurUpdate(nameLastData);
                                    }}
                                    placeholder={`Enter ${labelMapDisability['borrowerNameLast']}`}
                                    maxLength={MaxFieldLengths.LAST_NAME}
                                ></TSTextField>
                                <SCErrorLabel>{nameLastError}</SCErrorLabel>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id="last4SSN"
                                    ariaLabel={editFormLabels.last4SSN}
                                    label={editFormLabels.last4SSN}
                                    value={last4SSN?.value}
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setLast4SSN({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        last4SSN && handleFormBlurUpdate(last4SSN);
                                    }}
                                    placeholder={`${editFormLabels.last4SSN}`}
                                    maxLength={MaxFieldLengths.SSN}
                                ></TSTextField>
                                <SCErrorLabel>{ssnInputError}</SCErrorLabel>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                <TSTextField
                                    id={'emailAddress'}
                                    ariaLabel={editFormLabels.emailAddress}
                                    label={editFormLabels.emailAddress}
                                    value={contextStateClaimEdit?.emailAddress}
                                    readOnly={true}
                                    placeholder={`${editFormLabels.emailAddress}`}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementsWrapper>
                                <SCHRElement />
                                <SCSectionSubHead>Claim Benefits</SCSectionSubHead>
                            </SCElementsWrapper>
                            <SCElementsWrapper>
                                {newBenefitsArray &&
                                    newBenefitsArray.map(
                                        (claimBenefit: IClaimsManagementClaimBenefitsData, index: number) => {
                                            return (
                                                <ClaimBenefit
                                                    key={`claimBenefitKey${index}`}
                                                    claimNumber={contextStateClaimEdit.claimNumber}
                                                    claimBenefitIndex={index}
                                                    claimBenefit={claimBenefit}
                                                ></ClaimBenefit>
                                            );
                                        }
                                    )}
                            </SCElementsWrapper>
                            <SCElementsWrapper>
                                <SCElementWrapper
                                    widthAmount={FormFieldWidthEnum.QUADRUPLE}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                >
                                    <TSButton
                                        ariaLabel="dialog Save"
                                        tsVariant={TSButtonVariantsEnum.PLUS}
                                        size={TSButtonSizeEnum.MEDIUM}
                                        onClick={(): void => {
                                            addNewClaimBenefitRecord();
                                        }}
                                        disabled={false}
                                    >
                                        Add New Benefit
                                    </TSButton>
                                </SCElementWrapper>
                            </SCElementsWrapper>
                            <SCElementsWrapper>
                                <SCHRElement />
                                <SCSectionSubHead>Additional Claim Information</SCSectionSubHead>
                            </SCElementsWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.QUADRUPLE}>
                                <TSTextField
                                    id={'jobLossReason'}
                                    ariaLabel={editFormLabels.reasonForJobLoss}
                                    label={editFormLabels.reasonForJobLoss}
                                    value={jobLossReasonData?.value}
                                    multiline
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setJobLossReasonData({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        jobLossReasonData && handleFormBlurUpdate(jobLossReasonData);
                                    }}
                                    placeholder={`${editFormLabels.reasonForJobLoss}`}
                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.QUADRUPLE}>
                                <TSTextField
                                    id={'disablingCondition'}
                                    label={editFormLabels.disablingCondition}
                                    ariaLabel={editFormLabels.disablingCondition}
                                    value={disablingCondition?.value}
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setDisablingCondition({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        disablingCondition && handleFormBlurUpdate(disablingCondition);
                                    }}
                                    placeholder={editFormLabels.disablingCondition}
                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <SCTempDateFieldWrapper>
                                    <DatePicker
                                        label={editFormLabels.incurredDate}
                                        className="react-datepicker-filter"
                                        views={['month', 'day', 'year']}
                                        defaultValue={null}
                                        value={
                                            contextStateClaimEdit.dateIncurred
                                                ? dayjs(contextStateClaimEdit.dateIncurred)
                                                : null
                                        }
                                        onChange={(value): void => {
                                            updateContextStateClaimEdit({
                                                ...contextStateClaimEdit,
                                                dateIncurred: dayjs(value).toISOString(),
                                                isDirty: true
                                            });
                                        }}
                                        onError={(value): void => {
                                            value != null
                                                ? setIncurredDateError(errorMapIU['dateError'])
                                                : setIncurredDateError(null);
                                        }}
                                    />
                                </SCTempDateFieldWrapper>
                                <SCErrorLabel>{incurredDateError}</SCErrorLabel>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                <TSTextField
                                    id={'eConsentReceived'}
                                    label={editFormLabels.eConsentReceived}
                                    ariaLabel={editFormLabels.eConsentReceived}
                                    value={
                                        contextStateClaimEdit?.eConsent === 'true'
                                            ? ('Yes' as string)
                                            : ('No' as string)
                                    }
                                    readOnly={true}
                                    placeholder={`${editFormLabels.eConsentReceived}`}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.DOUBLE}>
                                <TSTextField
                                    id={'nameOfClaimSpecialist'}
                                    label={editFormLabels.claimSpecialistName}
                                    placeholder={editFormLabels.claimSpecialistName}
                                    ariaLabel={editFormLabels.claimSpecialistName}
                                    value={claimSpecialistName?.value}
                                    defaultValue={claimSpecialistName?.value}
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setClaimSpecialistName({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        claimSpecialistName && handleFormBlurUpdate(claimSpecialistName);
                                    }}
                                    maxLength={MaxFieldLengths.GENERAL_MAX}
                                ></TSTextField>
                            </SCElementWrapper>
                            <SCElementWrapper widthAmount={FormFieldWidthEnum.QUADRUPLE}>
                                <TSTextField
                                    id={'notes'}
                                    label={editFormLabels.notes}
                                    placeholder={editFormLabels.notes}
                                    ariaLabel={editFormLabels.notes}
                                    value={claimNotes?.value}
                                    defaultValue={claimNotes?.value}
                                    onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                                        setClaimNotes({
                                            id: fieldEvent.currentTarget.id,
                                            value: fieldEvent.currentTarget.value
                                        });
                                        fieldEvent.currentTarget.value && setIsFormDirty(true);
                                    }}
                                    onBlur={(): void => {
                                        claimNotes && handleFormBlurUpdate(claimNotes);
                                    }}
                                    multiline={true}
                                    minRows={5}
                                    maxLength={MaxFieldLengths.NOTES_CLAIM_ATTRIBUTE}
                                />
                            </SCElementWrapper>
                        </SCElementsWrapper>
                        {allClaimDetails != null && <ClaimEditViewDetails claimData={allClaimDetails} />}
                    </SCContentSectionWrapper>
                </form>
            </SCFormWrapper>
            <BeforeUnload
                isLoggedIn={authenticationData.isAuthenticated ? authenticationData.isAuthenticated : false}
                nativeDialogue={true}
                customDialogue={false}
                modalEnabled={isFormDirty}
                onContinue={(): void => {
                    setIsFormDirty(false);
                }}
                allowedRoutes={allowedRoutes}
                onCancel={(): void => {}}
            />
        </SCContentSectionWrapper>
    );
};
/** Component representing the View Details section within ClaimEdit.
 *  High-level overview: Given an object representing the claim, present the various keys & values & arrays nicely, almost as if it were a beautified-JSON, but without all the brackets and quotation marks, to make it more readable.
 */
const ClaimEditViewDetails = ({ claimData }: IClaimEditViewDetailsProps): ReactElement => {
    const [claimDetailsDataForAccordion, setClaimDetailsDataForAccordion] = useState<IAccordionData[]>([]);
    useEffect(() => {
        /** Return true if value is an object */
        const isObject = (value): boolean => {
            return value.toString() === '[object Object]';
        };
        /** Container node for an object with keys and values */
        const ObjectContainer = ({ obj }): ReactElement => {
            return (
                <ul>
                    {Object.entries(obj).map(([key, value], i) => {
                        return <FieldNode key={i} label={key} value={value} />;
                    })}
                </ul>
            );
        };
        /** Container node for arrays */
        const ArrayContainer = ({ value }): ReactElement => {
            return (
                <ol>
                    {value.map((value, i) => {
                        return (
                            <React.Fragment key={i}>
                                <li>
                                    <br></br>
                                    <ObjectContainer obj={value} />
                                </li>
                                <hr />
                            </React.Fragment>
                        );
                    })}
                </ol>
            );
        };
        /** Node representing a field. A field has a label and value.
         *  The value is expected to be either another object (array or object) or just a primitive type. The possibilities will be handled appropriately.
         **/
        const FieldNode = ({ label, value }): ReactElement => {
            if (value == null) {
                value = 'null';
            }
            return (
                <li>
                    {Array.isArray(value) ? (
                        <>
                            <FieldLabel labelContent={label} />
                            <ArrayContainer value={value} />
                        </>
                    ) : isObject(value) ? (
                        <>
                            <FieldLabel labelContent={label} />
                            <ObjectContainer obj={value} />
                        </>
                    ) : (
                        <>
                            <FieldLabel labelContent={label} />
                            {value.toString()}
                        </>
                    )}
                </li>
            );
        };
        const FieldLabel = ({ labelContent }): ReactElement => {
            return <b>{labelContent}: </b>;
        };
        setClaimDetailsDataForAccordion([
            {
                label: 'View Details',
                content: <ObjectContainer obj={claimData} />
            }
        ]);
    }, [claimData]);

    return (
        <SCViewDetailsWrapper>
            <Accordion data={claimDetailsDataForAccordion} />
        </SCViewDetailsWrapper>
    );
};
export { ClaimEdit };
