import { FormHelperText } from '@mui/material';
import styled from 'styled-components';
import { $darkGray, $deepBlue, $digitalBlack, $white, $yellow } from 'styles/global-styles';

export const SCTextFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    word-break: break-word;
    box-sizing: border-box;
    background: ${$white};
    input {
        width: 100%;
        border: none !important;
        background-color: transparent !important;
    }
    textarea {
        width: 100%;
        padding: 32px;
        resize: both;
        overflow: auto;
        border-radius: 4px;
        background: ${$white};
        box-shadow: 0 0 0 2px rgba(35, 31, 32, 0.75);
        &:hover {
            box-shadow: 0 0 0 2px ${$deepBlue};
        }
        &:focus {
            box-shadow: 0 0 0 2px ${$deepBlue};
        }
        .sx {
            &::placeholder {
                color: red;
            }
        }
        transition: box-shadow 0.25s ease-out;
    }
    .MuiInput-root.MuiInput-underline.MuiInputBase-multiline:before {
        border: none;
        &::placeholder {
            color: red;
        }
    }
    .MuiInput-root.MuiInput-underline.MuiInputBase-multiline:after {
        border: none;
    }
    div {
        width: 100%;
    }
`;
export const SCLabelWrapper = styled.div`
    min-height: 18px;
`;
export const SCHelperText = styled(FormHelperText)`
    padding-top: 10px;
    padding-left: 5px;
    border: none !important;
`;
