import * as React from 'react';
import { IFormType, IFormTypeState } from 'types/claim-types';
import { IChildrenProps } from 'types/children';
import { ClaimStateEnum } from 'enums/ClaimState';

export const FormTypeContext = React.createContext<IFormTypeState | null>(null);
const FormTypeProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [formType, setFormType] = React.useState<IFormType>({
        type: undefined,
        reviewState: ClaimStateEnum.ACTIVE
    });
    const saveFormType = (formData: IFormType): void => {
        const newFormData: IFormType = {
            type: formData.type,
            reviewState: formData.reviewState
        };
        setFormType(newFormData);
    };
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FormTypeContext:::::::::::::::::::::::::::::::::',
    //     '\n::formType::',
    //     formType,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );
    return <FormTypeContext.Provider value={{ formType, saveFormType }}>{children}</FormTypeContext.Provider>;
};

export default FormTypeProvider;
