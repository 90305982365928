import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'styles/css-reset.css';
import { AnalyticsService } from 'Services/googleAnalyticsDataLayer';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

(async (): Promise<void> => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY ? process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY : '',
        options: {
            bootstrap: 'localStorage'
        }
    });
    // console.info(
    //     '\n:::::::::::::::::::::::::::::index.tsx::::::::::::::::::::::::::::::::::::',
    //     '\n::process.env.NODE_ENV::',
    //     process.env.NODE_ENV,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );
    ReactDOM.render(
        <React.StrictMode>
            <LDProvider>
                <App />
            </LDProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    AnalyticsService.init();
    reportWebVitals();
})();
