import React, { useEffect, useState, ReactElement } from 'react';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import {
    SCPageWrapper,
    SCContentWrapper,
    SCEyebrow,
    SCSectionWrapper,
    SCFooterWrapper,
    SCContentDirectionWrapper,
    SCContentSectionResponsiveWrapper
} from 'styles/global-styles';
import { Accordion } from 'components/molecules/Accordion';
import { IClientContextState, IFAQItems } from 'types/client-context';
import { IFAQProps } from './types';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';

const FAQ = ({ children }: IFAQProps): ReactElement => {
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const [faqGeneral, setFAQGeneral] = useState<IFAQItems[]>();
    useEffect(() => {
        if (clientContextDataState?.payload?.client_content) {
            setFAQGeneral(clientContextDataState.payload.client_content.faq_collections);
        }
    }, [clientContextDataState]);
    return (
        <>
            <SCPageWrapper>
                <SCContentWrapper>
                    <SCContentDirectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    >
                        <SCContentSectionResponsiveWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.FLEX_START}
                            flexGap={'24px'}
                        >
                            <SCEyebrow>Frequently Asked Questions</SCEyebrow>
                            {faqGeneral &&
                                faqGeneral.map((data: IFAQItems, index) => {
                                    return (
                                        <SCSectionWrapper key={`faq_item_${index}`}>
                                            <Accordion
                                                data={data.group_list}
                                                group_label={`${data.group_label}`}
                                            ></Accordion>
                                        </SCSectionWrapper>
                                    );
                                })}
                        </SCContentSectionResponsiveWrapper>
                    </SCContentDirectionWrapper>
                </SCContentWrapper>
                <SCFooterWrapper>
                    <GlobalFooter />
                </SCFooterWrapper>
            </SCPageWrapper>
        </>
    );
};

export { FAQ };
