import styled from 'styled-components';

export const SCTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 24px 0 24px;
`;
export const SCButtonIconWrapper = styled.div`
    width: 100%;
    max-width: 29px;
    max-height: 29px;
    margin: 0 0 0 2px;
`;
export const SCResets = styled.div`
    border: transparent;
    label {
        font-size: 0.8rem;
    }
    input {
        font-size: 0.875rem;
        min-width: 100px;
        border-radius: 0 !important;
        background-color: transparent !important;
        border: 0 none transparent !important;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        width: 100%;
        fieldset {
            border-radius: 0;
            border: 0 none transparent;
        }
    }
    button {
        color: rgba(25, 48, 98, 1);
        text-transform: none;
        border-radius: 0 !important;
    }
    button:hover {
        outline: inherit !important;
        box-shadow: inherit !important;
    }
    button:focus {
        outline: inherit !important;
        box-shadow: inherit !important;
    }
`;
