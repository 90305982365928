export enum AuthenticationGroupRolesEnum {
    ADMIN_INTERNAL = 'r-paymentguard_internal',
    ADMIN_ACCESS_OWNER = 'r-paymentguard_access_Owner',
    ADMIN_ACCESS_WRITE = 'r-paymentguard_access_Write',
    ADMIN_EXTERNAL = 'r-paymentguard_external',
    ADMIN_ACCESS_READ = 'r-paymentguard_access_ReadOnly',
    ADMIN_ACCESS_TestDistributor = 'r-paymentguard_distributor_TestDistributor',
    ADMIN_ACCESS_HAPPY_MONEY = 'r-paymentguard_distributor_HappyMoney',
    ADMIN_ACCESS_SPLASH = 'r-paymentguard_distributor_Splash',
    ADMIN_ACCESS_ZIRTUE = 'r-paymentguard_distributor_Zirtue',
    ADMIN_ACCESS_BEEM = 'r-paymentguard_distributor_Beem'
}
