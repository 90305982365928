export const labelMapIU = {
    borrowerName: 'Name',
    borrowerNameFirst: 'First Name',
    borrowerNameLast: 'Last Name',
    borrowerEmail: 'Email',
    borrowerPhone: 'Phone number',
    borrowerSSN: 'Last 4 Digits of SSN',
    borrowerDateOfBirth: 'Date of Birth',
    borrowerMailingAddress: 'Mailing Address',
    borrowerMailingCity: 'City',
    borrowerMailingState: 'State',
    borrowerMailingZip: 'ZIP',
    iuDateOfJobLoss: 'Date of job loss',
    iuDateOfJobLossNotification: 'Date you were notified of the job loss',
    iuDateLastDayWorked: 'Date of last day worked',
    iuJobLossReason: 'Reason for job loss',
    iuOccupationAtTimeOfSeparation: 'Occupation at time of job loss',
    iuIsLayoffTypicalAtThisTime: 'Do you get laid off each year around this time?',
    iuKnowledgeOfLayoffWhenHired: 'Did you know about the layoff at the time of hire?',
    iuSelfEmployed: 'Are you self employed?',
    iuQualifyForStateUnemployment: 'Are you receiving State Unemployment benefits?',
    iuRejectionStateBenefitsReason:
        'If you did not qualify for State Unemployment benefits, please indicate the reason why.',
    employerName: 'Employer name',
    employerEmail: 'Employer email',
    employerContactNumber: 'Employer Phone Number',
    employerAddress: 'Employer Address',
    employerCity: 'Employer City',
    employerState: 'Employer State',
    employerZip: 'Employer ZIP',
    borrowerComments: 'Comments (optional)',
    iuFiledOnBehalfOf: 'Are you filing this on behalf of someone else?',
    submitterCompanyName: 'Submitter Company Name',
    submitterName: 'Submitter Name',
    submitterNameFirst: 'Submitter First Name',
    submitterNameLast: 'Submitter Last Name',
    submitterEmail: 'Submitter Email',
    submitterPhone: 'Submitter Phone Number',
    disabilityFiledOnBehalfOf: 'Are you filing this on behalf of someone else?',
    disabilityTypeOfSurgery: 'If surgery is occurring, please indicate the type of surgery being completed',
    disabilityExplanationOf: 'Explanation of disability',
    disabilityKnowWhenExpectedWorking: 'Do you know when you expect to be back at work?',
    disabilityDateExpectedWorking: "Expected date you'll be back at work",
    disabilityDateLastWorked: 'Last working date',
    disabilityDateOfIncident: 'Date of incident (disability)',
    disabilityMedicalCategories: 'Medical categories',
    disabilityJobClassification: 'Job classification (drop-down self employed)',
    disabilityJobDuties: 'Job duties (if not employed at time of disability, what were your previous job duties)',
    disabilityFirstPhysicianDate: 'Date you first saw a physician for this condition',
    disabilityHealthcareName: 'Healthcare Provider Name',
    disabilityHealthcareHospitalName: 'Name of clinic/hospital',
    // disabilityHealthcareEmail: 'Healthcare Provider Email',
    disabilityHealthcareContactNumber: 'Healthcare Provider Contact Number',
    disabilityHealthcareAddress: 'Healthcare Provider Address',
    disabilityHealthcareCity: 'Healthcare provider City',
    disabilityHealthcareState: 'Healthcare Provider State',
    disabilityHealthcareZip: 'Healthcare provider Zip'
};
export const keysBorrowDetail = [
    'borrowerNameFirst',
    'borrowerNameLast',
    'borrowerEmail',
    'borrowerPhone',
    'borrowerSSN',
    'borrowerDateOfBirth',
    'borrowerMailingAddress',
    'borrowerMailingCity',
    'borrowerMailingState',
    'borrowerMailingZip'
];
export const keysBorrowDetailColumn1 = [
    'borrowerName',
    'borrowerNameFirst',
    'borrowerNameLast',
    'borrowerSSN',
    'borrowerEmail',
    'borrowerPhone',
    'borrowerDateOfBirth'
];
export const keysBorrowDetailColumn2 = [
    'borrowerMailingAddress',
    'borrowerMailingCity',
    'borrowerMailingState',
    'borrowerMailingZip'
];
export const keysEmployerDetails = [
    'employerName',
    'employerEmail',
    'employerContactNumber',
    'employerAddress',
    'employerCity',
    'employerState',
    'employerZip'
];
export const keysEmployerDetailColumn1 = ['employerName', 'employerEmail', 'employerContactNumber'];
export const keysEmployerDetailColumn2 = ['employerAddress', 'employerCity', 'employerState', 'employerZip'];
export const keysIUJobLossDetails = [
    'iuDateOfJobLoss',
    'iuDateOfJobLossNotification',
    'iuJobLossReason',
    'iuDateLastDayWorked',
    'iuOccupationAtTimeOfSeparation',
    'iuIsLayoffTypicalAtThisTime',
    'iuKnowledgeOfLayoffWhenHired',
    'iuSelfEmployed',
    'iuQualifyForStateUnemployment',
    'iuRejectionStateBenefitsReason'
];
export const keysIUJobLossDetailsColumn1 = [
    'iuJobLossReason',
    'iuOccupationAtTimeOfSeparation',
    'iuIsLayoffTypicalAtThisTime',
    'iuKnowledgeOfLayoffWhenHired',
    'iuSelfEmployed',
    'iuQualifyForStateUnemployment',
    'iuRejectionStateBenefitsReason'
];
export const keysIUJobLossDetailsColumn2 = ['iuDateOfJobLoss', 'iuDateOfJobLossNotification', 'iuDateLastDayWorked'];
export const keysIUJobLossDetailsRow1Column1 = ['iuJobLossReason', 'iuOccupationAtTimeOfSeparation'];
export const keysIUJobLossDetailsRow1Column2 = [
    'iuDateOfJobLoss',
    'iuDateOfJobLossNotification',
    'iuDateLastDayWorked'
];
export const keysIUJobLossDetailsRow2Column1 = [
    'iuIsLayoffTypicalAtThisTime',
    'iuKnowledgeOfLayoffWhenHired',
    'iuSelfEmployed'
];
export const keysIUJobLossDetailsRow2Column2 = ['iuQualifyForStateUnemployment', 'iuRejectionStateBenefitsReason'];
export const keysDisabilityComments = [
    'borrowerComments',
    'disabilityFiledOnBehalfOf',
    'submitterCompanyName',
    'submitterNameFirst',
    'submitterNameLast',
    'submitterEmail',
    'submitterPhone'
];
export const keysDisabilityCommentsColumn1 = ['borrowerComments', 'disabilityFiledOnBehalfOf', 'submitterCompanyName'];
export const keysDisabilityCommentsColumn2 = ['submitterName', 'submitterEmail', 'submitterPhone'];
export const keysIUComments = [
    'borrowerComments',
    'iuFiledOnBehalfOf',
    'submitterCompanyName',
    'submitterNameFirst',
    'submitterNameLast',
    'submitterEmail',
    'submitterPhone'
];
export const keysIUCommentsColumn1 = ['borrowerComments', 'iuFiledOnBehalfOf', 'submitterCompanyName'];
export const keysIUCommentsColumn2 = ['submitterName', 'submitterEmail', 'submitterPhone'];
export const keysDisabilityDetails = [
    'disabilityTypeOfSurgery',
    'disabilityExplanationOf',
    'disabilityKnowWhenExpectedWorking',
    'disabilityDateExpectedWorking',
    'disabilityDateLastWorked',
    'disabilityDateOfIncident'
];
export const keysDisabilityDetailsRow1Column1 = [
    'disabilityDateOfIncident',
    'disabilityExplanationOf',
    'disabilityJobDuties'
];
export const keysDisabilityDetailsRow1Column2 = [
    'disabilityDateLastWorked',
    'disabilityKnowWhenExpectedWorking',
    'disabilityDateExpectedWorking',
    'disabilityFirstPhysicianDate'
];
export const keysDisabilityDetailsRow2Column1 = ['disabilityTypeOfSurgery'];
export const keysDisabilityDetailsAdditional = [
    // 'disabilityMedicalCategories',
    // 'disabilityJobClassification',
    'disabilityJobDuties',
    'disabilityFirstPhysicianDate'
];
export const keysDisabilityDetailsHealthcare = [
    'disabilityHealthcareName',
    // 'disabilityHealthcareEmail',
    'disabilityHealthcareContactNumber',
    'disabilityHealthcareAddress',
    'disabilityHealthcareCity',
    'disabilityHealthcareState',
    'disabilityHealthcareZip'
];
export const keysDisabilityDetailsHealthcareRow1Column1 = [
    'disabilityHealthcareName',
    'disabilityHealthcareHospitalName',
    // 'disabilityHealthcareEmail',
    'disabilityHealthcareContactNumber'
];
export const keysDisabilityDetailsHealthcareRow1Column2 = [
    'disabilityHealthcareAddress',
    'disabilityHealthcareCity',
    'disabilityHealthcareState',
    'disabilityHealthcareZip'
];

export const labelMapDisability = {
    borrowerName: 'Name',
    borrowerNameFirst: 'First Name',
    borrowerNameLast: 'Last Name',
    borrowerEmail: 'Email',
    borrowerPhone: 'Phone number',
    borrowerSSN: 'Last four digits of security number',
    borrowerDateOfBirth: 'Date of Birth',
    borrowerMailingAddress: 'Mailing Address',
    borrowerMailingCity: 'City',
    borrowerMailingState: 'State',
    borrowerMailingZip: 'ZIP',
    employerName: 'Employer name',
    employerEmail: 'Employer email',
    employerContactNumber: 'Employer Phone Number',
    employerAddress: 'Employer Address',
    employerCity: 'Employer City',
    employerState: 'Employer State',
    employerZip: 'Employer ZIP',
    borrowerComments: 'Comments (optional)',
    disabilityFiledOnBehalfOf: 'Are you filing this on behalf of someone else?',
    disabilityTypeOfSurgery: 'If surgery is occurring, please indicate the type of surgery being completed',
    disabilityExplanationOf: 'Explanation of disability',
    disabilityKnowWhenExpectedWorking: 'Do you know when you expect to be back at work?',
    disabilityDateExpectedWorking: "Expected date you'll be back at work",
    disabilityDateLastWorked: 'Last working date',
    disabilityDateOfIncident: 'Date of incident (disability)',
    disabilityMedicalCategories: 'Medical categories',
    disabilityJobClassification: 'Job classification (drop-down self employed)',
    disabilityJobDuties: 'Job duties (if not employed at time of disability, what were your previous job duties)',
    disabilityFirstPhysicianDate: 'Date you first saw a physician for this condition',
    disabilityHealthcareName: 'Healthcare Provider Name',
    disabilityHealthcareHospitalName: 'Name of clinic/hospital',
    // disabilityHealthcareEmail: 'Healthcare Provider Email',
    disabilityHealthcareContactNumber: 'Healthcare Provider Contact Number',
    disabilityHealthcareAddress: 'Healthcare Provider Address',
    disabilityHealthcareCity: 'Healthcare provider City',
    disabilityHealthcareState: 'Healthcare Provider State',
    disabilityHealthcareZip: 'Healthcare provider Zip',
    submitterCompanyName: 'Submitter Company Name',
    submitterNameFirst: 'Submitter First Name',
    submitterNameLast: 'Submitter Last Name',
    submitterEmail: 'Submitter Email',
    submitterPhone: 'Submitter Phone Number'
};
