import styled from 'styled-components';

export const SCDiv = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;
export const SCButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    min-height: 52px;
`;
export const SCReviewLinkWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    min-height: 52px;
    cursor: pointer;
`;
export const SCEditIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
`;

export const SCHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 52px;
    cursor: pointer;
    @media (max-width: 1440px) {
        flex-direction: column;
        align-items: start;
        padding: 0px 32px;
    }
`;
export const SCKeyValueSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 92%;
    @media (max-width: 1440px) {
        flex-wrap: wrap;
        padding: 0px 32px;
        width: 100%;
    }
`;
export const SCKeyValueListWrapper = styled.div`
    width: 50%;
    font-size: 14px;
    letter-spacing: 0.4px;
    padding: 36px 0px 0px 0px;
    @media (max-width: 1440px) {
        width: 100%;
        flex-wrap: wrap;
    }
`;
