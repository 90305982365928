import React, { ReactElement } from 'react';
import { IPlusIconProps } from './types';
import { SCSvgWrapper, SCSvgRectangle, SCSvgRoot, SCSVGBar } from 'components/atoms/PlusIcon/styles';

const PlusIcon = ({ disabled, iconState }: IPlusIconProps): ReactElement => {
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::PlusIcon:::::::::::::::::::::::::::::::::',
    //     '\n::iconState::',
    //     iconState,
    //     '\n::disabled::',
    //     disabled,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCSvgWrapper
            data-testid={'plus_icon'}
            aria-disabled={disabled ? 'true' : 'false'}
            role="img"
            aria-label="Plus icon"
        >
            <SCSvgRoot
                aria-labelledby="Plus icon"
                role="group"
                width="100%"
                height="100%"
                viewBox="0 0 55 55"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
            >
                <g transform="matrix(0.982143,0,0,0.982143,0,0)">
                    <SCSvgRectangle
                        animate={iconState}
                        data-testid={iconState ? 'RectangleIconActive' : 'RectangleIconInactive'}
                        x="0"
                        y="0"
                        width="56"
                        height="56"
                    />
                </g>
                <g transform="matrix(0.784715,0,0,0.982143,5.13545,0.0006875)">
                    <SCSVGBar
                        animate={iconState}
                        data-testid={iconState ? 'PlusIconActive' : 'PlusIconInactive'}
                        disabled={disabled}
                        x="12.84"
                        y="26.919"
                        width="31.32"
                        height="2.16"
                        fill={'rgb(25,48,98)'}
                    />
                </g>
                <g transform="matrix(4.805e-17,-0.784715,0.982143,6.01389e-17,0.0006875,49.8645)">
                    <SCSVGBar
                        animate={iconState}
                        data-testid={iconState ? 'PlusIconActive' : 'PlusIconInactive'}
                        disabled={disabled}
                        x="12.84"
                        y="26.919"
                        width="31.32"
                        height="2.16"
                    />
                </g>
            </SCSvgRoot>
        </SCSvgWrapper>
    );
};

export { PlusIcon };
