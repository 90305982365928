import React, { ReactElement } from 'react';
import { SCAutoCompleteWrapper } from './styles';
import { ITSAutocompleteProps } from './types';
import { Autocomplete, TextField } from '@mui/material';

/***
 * Our wrapper around the MaterialUI Autocomplete component (aka, a dropdown selection component with type-and-search functionality)
 */
const TSAutocomplete = ({
    options,
    label,
    id,
    defaultValue,
    value,
    getOptionLabel,
    onChange,
    disabled,
    autoHighlight,
    filterOptions,
    dataTestId,
    renderOption,
    children,
    placeholder,
    onInputChange,
    onFreeSolo,
    autoSelect,
    ariaLabel
}: ITSAutocompleteProps): ReactElement => {
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSAutocomplete:::::::::::::::::::::::::::::::::',
    //     '\n::id::',
    //     id,
    //     '\n::options::',
    //     options,
    //     '\n::value::',
    //     value,
    //     '\n::defaultValue::',
    //     defaultValue,
    //     // '\n::inputValue::',
    //     // inputValue,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCAutoCompleteWrapper>
            <Autocomplete
                disablePortal
                defaultValue={defaultValue != null ? defaultValue : value ?? null}
                value={value ?? null}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, value): boolean => {
                    return value === undefined || value === '' || option.id === value.id;
                }}
                options={options}
                renderInput={(params): ReactElement => (
                    <TextField
                        name="autocompleteInput"
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        inputProps={{ ...params.inputProps }}
                        variant="standard"
                    />
                )}
                renderOption={renderOption}
                id={id}
                aria-label={ariaLabel}
                onChange={onChange}
                disabled={disabled}
                autoHighlight={autoHighlight == null ? true : autoHighlight}
                filterOptions={filterOptions}
                data-testid={dataTestId}
                onInputChange={onInputChange}
                freeSolo={onFreeSolo}
                autoSelect={autoSelect == null ? true : autoSelect}
            />
        </SCAutoCompleteWrapper>
    );
};

export { TSAutocomplete };
